import { useActor } from "@xstate/react";
import { ActorRef } from "xstate";
import { FormattedMedia } from "../../generated/router";
import {
  ContainerContent,
  ContainerContext,
} from "../../stateMachines/PlaybackMachine";
import { Settings } from "../../stateMachines/PlayerMachine";
import { ProtocolHeaders } from "../app/dynamic";
import { Screen } from "../playback";
import styles from "./index.module.css";

export function Container(props: Props) {
  const [state] = useActor(props.actor);
  const context = state.context as ContainerContext;

  const isInfinite =
    props.isInfinite && state.matches("playing.playingA.single");

  const shouldPlayA =
    props.shouldBePlaying && state.matches("playing.playingA");

  const a =
    context.isFirstRender ||
    shouldPlayA ||
    state.matches("playing.transitionA");

  const shouldPlayB =
    props.shouldBePlaying && state.matches("playing.playingB");

  const b = shouldPlayB || state.matches("playing.transitionB");

  return (
    <div className={styles.root}>
      <div className={styles.fullScreen} style={{ opacity: a ? 1 : 0 }}>
        {context.screenA ? (
          <Screen
            key={context.screenA?.id}
            value={context.screenA}
            send={props.send}
            settings={props.settings}
            shouldBePlaying={shouldPlayA}
            medias={props.medias}
            isInfinite={isInfinite}
            isFullScreen={false}
            headers={props.headers}
          />
        ) : null}
      </div>
      <div className={styles.fullScreen} style={{ opacity: b ? 1 : 0 }}>
        {context.screenB ? (
          <Screen
            key={context.screenB?.id}
            value={context.screenB}
            send={props.send}
            settings={props.settings}
            shouldBePlaying={shouldPlayB}
            medias={props.medias}
            isInfinite={isInfinite}
            isFullScreen={false}
            headers={props.headers}
          />
        ) : null}
      </div>
    </div>
  );
}

interface Props {
  shouldBePlaying: boolean;
  isInfinite: boolean;
  value: ContainerContent;
  actor: ActorRef<any>;
  send: (type: any) => void;
  settings: Settings;
  medias: FormattedMedia[];
  headers: ProtocolHeaders;
}
