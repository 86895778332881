import { useDynamicRandom } from "../../misc/useDynamicRandom";

export const TestScreenImage = () => {
  const left = useDynamicRandom(10, 60, 3000);
  const top = useDynamicRandom(10, 60, 3000);
  return (
    <img
      alt="Test screen"
      src="./TestScreen.png"
      style={{
        zIndex: 10000,
        position: "absolute",
        opacity: 0.5,
        width: "35vmin",
        top: `${top}vh`,
        left: `${left}vw`,
      }}
    />
  );
};
