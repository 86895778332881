import { TIZEN_DIRECTORY } from ".";

export function getFullTizenPath(path: string): Promise<string | null> {
  return new Promise((resolve, reject) => {
    try {
      window.tizen.filesystem.resolve(
        `${TIZEN_DIRECTORY}/`,
        (dir) => {
          try {
            dir.listFiles(
              (files) => {
                files.forEach((file) => {
                  if (file.name.startsWith(path)) {
                    try {
                      resolve(file.toURI());
                    } catch (err) {
                      reject(err);
                    }
                  }
                });
              },
              (err: any) => reject(err),
              null
            );
          } catch (err) {
            reject(err);
          }
        },
        (err) => reject(err),
        "r"
      );
    } catch (err) {
      reject(err);
    }
  });
}
