export enum CurrentPlayback {
  A,
  B,
}

export interface Playback {
  playlistIndex: number;
  playlistsOffsets: number[];
}

export interface PlaybackData {
  currentPlayback: CurrentPlayback;
  playbackA?: Playback;
  playbackB?: Playback;
}

export function playbackEquals(
  playbackA?: Playback,
  playbackB?: Playback
): boolean {
  if (playbackA && playbackB) {
    return (
      playbackA.playlistIndex === playbackB.playlistIndex &&
      playbackA.playlistsOffsets.length === playbackB.playlistsOffsets.length &&
      playbackA.playlistsOffsets.every(function (value, index) {
        return value === playbackB.playlistsOffsets[index];
      })
    );
  }

  return false;
}
