import styles from "./index.module.css";

export const PasswordOverlay = ({
  passwordIsIncorrect,
  onSubmit,
  onClear,
}: {
  passwordIsIncorrect: boolean;
  onSubmit: (password: string) => void;
  onClear: () => void;
}) => (
  <div className={styles.background}>
    <form
      className={styles.form}
      onSubmit={(event) => {
        event.preventDefault();
        if (event.target instanceof HTMLFormElement) {
          const data = new FormData(event.target);
          const password = data.get("password")?.toString() ?? "";
          onSubmit(password);
        }
      }}
    >
      <div className={styles.content}>
        <div className={styles.title}>This is a restricted channel</div>
        <div className={styles.description}>
          Enter the password to open the link
        </div>
        <label className={styles.inputGroup}>
          <div className={styles.label}>Password</div>
          <input
            className={styles.input}
            autoFocus
            name="password"
            type="password"
            onChange={() => onClear()}
            placeholder="Enter password"
          />
          {passwordIsIncorrect ? (
            <div className={styles.error}>Password is incorrect</div>
          ) : null}
        </label>
      </div>
      <div className={styles.footer}>
        <button className={styles.submit} type="submit">
          Open Channel
        </button>
      </div>
    </form>
  </div>
);
