import QR from "qrcode.react";
import styles from "./index.module.css";
import {
  CastingInstructionsOptions,
  WifiInstructions,
} from "../../stateMachines/PlayerMachine";
import { useRef } from "react";
import { useDynamicElement } from "../../misc/useDynamicElement";

export const CastingInstructions = ({
  instructions,
  options,
  castPin,
}: {
  instructions: WifiInstructions | null;
  options: CastingInstructionsOptions | null;
  castPin: string;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { left, top } = useDynamicElement(ref, 3000);

  const castingUrl = `${process.env.REACT_APP_CAST_BASE}/${castPin}`;

  if (options?.style === "BAR") {
    return (
      <div
        className={`${styles.bar} ${
          options?.size === "STANDARD" ? "" : styles.big
        }`}
      >
        <img className={styles.logo} src="./logo-casting.svg" alt="logo" />
        <div className={styles.castContent}>
          <p>
            To cast to this screen, go to{" "}
            <a className={styles.castingUrl} href={castingUrl}>
              {castingUrl.split("//")?.[1]}
            </a>{" "}
            in your browser
          </p>
        </div>
        <div className={styles.wifiContent}>
          {instructions?.ssid && (
            <div className={styles.wifiColumns}>
              <div className={styles.wifiInstructions}>
                <p>Join the WIFI</p>
                <p>Network name: {instructions.ssid}</p>
                {instructions.password && (
                  <p>Network password: {instructions.password}</p>
                )}
              </div>
              <QR
                className={styles.qrCode}
                value={`WIFI:S:${instructions.ssid};T:WPA;P:${instructions.password};;`}
                bgColor="#f0e9f6"
                fgColor="#100918"
                renderAs="svg"
              />
            </div>
          )}
        </div>
      </div>
    );
  }
  return (
    <div
      className={`${styles.popup} ${
        options?.size === "STANDARD" ? "" : styles.big
      }`}
      style={
        options?.position === "DYNAMIC"
          ? {
              top,
              left,
              right: "auto",
              bottom: "auto",
              transform: "none",
            }
          : {}
      }
      ref={ref}
    >
      <div className={styles.castContent}>
        <div className={styles.header}>
          <img className={styles.logo} src="./logo-casting.svg" alt="logo" />
        </div>
        <h1 className={styles.title}>Cast to this screen from your device</h1>
        <a className={styles.castingUrl} href={castingUrl}>
          {castingUrl.split("//")?.[1]}
        </a>
        <p>
          To share your screen, go the above URL from the browser on your
          device.
        </p>
        <p>
          To cast from more than one source, open the URL in multiple browsers.
        </p>
      </div>
      {instructions?.ssid && (
        <div className={styles.wifiContent}>
          <h1 className={styles.title}>WiFi</h1>
          <div className={styles.wifiColumns}>
            <div className={styles.wifiInstructions}>
              <p>Connect your device to the local WiFi network.</p>
              <p>Scan the code or enter the credentials:</p>
              <p className={styles.highlighted}>
                Network name: {instructions.ssid}
              </p>
              {instructions.password && (
                <p className={styles.highlighted}>
                  Network password: {instructions.password}
                </p>
              )}
            </div>
            <div>
              <QR
                className={styles.qrCode}
                value={`WIFI:S:${instructions.ssid};T:WPA;P:${instructions.password};;`}
                bgColor="#f0e9f6"
                fgColor="#100918"
                renderAs="svg"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
