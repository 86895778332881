import { get, set } from "idb-keyval";

export async function silentReload() {
  try {
    await set("silentReload", Date.now());
  } catch (e) {
    console.error(e);
  } finally {
    window.location.reload();
  }
}

export async function isSilentReload() {
  if (process.env.NODE_ENV === "development") return true;
  const lastTime = (await get("silentReload")) || 0;
  const isSilent = Date.now() - lastTime < 15000;
  console.debug(`Is silent reload: ${isSilent}`);
  return isSilent;
}
