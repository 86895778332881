import { isOutdatedBrowser } from "../components/video-playback";
import { VideoPreference } from "../stateMachines/PlayerMachine";
import { getPlayerType } from "./get-player-type";

export default function getDefaultVideoPlaybackType(): VideoPreference {
  if (isTizen()) return "html";
  if (!window.FugoBridge) return null;

  if (getPlayerType() === "amazon") {
    return "native";
  }

  if (getPlayerType() === "bravia") {
    return "html";
  }

  if (isOutdatedBrowser()) {
    return "native";
  }

  if (isOutdatedAndroid()) {
    return "native";
  }

  return "html";
}

function isOutdatedAndroid() {
  const version = getAndroidVersion();
  if (version == null) return true;
  return version < 9;
}

function getAndroidVersion(): number | null {
  try {
    if (window?.FugoBridge?.getAndroidVersion) {
      const fullVersion = window.FugoBridge.getAndroidVersion();
      if (fullVersion) {
        const major = ~~fullVersion.split(".")[0];
        if (major) {
          return major;
        }
      }
    }

    const parsed = window.navigator.userAgent.match(/Android ([^.]{0,2})/);
    if (parsed && parsed[1]) {
      return ~~parsed[1];
    }

    return null;
  } catch (e) {}
  return null;
}

export function isTizen(): boolean {
  return !!navigator.userAgent.includes("Tizen");
}
