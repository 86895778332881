import EventTarget from "@ungap/event-target";

class AmazonIapV2 extends EventTarget implements AmazonIapV2 {
  fire(eventJson: string) {
    console.log(eventJson);
    const event = JSON.parse(eventJson);
    if (!event.eventId) {
      throw new Error("Event object missing 'eventId' property.");
    }
    const e = new CustomEvent(event.eventId, { detail: event.payload });
    this.dispatchEvent(e);
  }

  purchase(sku: string): Promise<Purchase> {
    return new Promise((resolve) => {
      const handlePurchaseResponse = (e: CustomEvent<Purchase>) => {
        resolve(e.detail);
        // @ts-expect-error
        this.removeEventListener("purchaseResponse", handlePurchaseResponse);
      };
      // @ts-expect-error
      this.addEventListener("purchaseResponse", handlePurchaseResponse);
      window.FugoBridge.amazonPurchase(sku);
    });
  }

  notifyFulfillment(
    receiptId: string,
    fulfillmentResult: "FULFILLED" | "UNAVAILABLE"
  ) {
    return new Promise((resolve) => {
      window.FugoBridge.notifyFulfillment(receiptId, fulfillmentResult);
      resolve(0);
    });
  }

  getPurchaseUpdates(reset: boolean): Promise<PurchaseUpdates> {
    return new Promise((resolve) => {
      const handleGetPurchaseUpdatesResponse = (
        e: CustomEvent<PurchaseUpdates>
      ) => {
        resolve(e.detail);
        this.removeEventListener(
          "getPurchaseUpdatesResponse",
          // @ts-expect-error
          handleGetPurchaseUpdatesResponse
        );
      };
      this.addEventListener(
        "getPurchaseUpdatesResponse",
        // @ts-expect-error
        handleGetPurchaseUpdatesResponse
      );
      window.FugoBridge.getPurchaseUpdates(reset);
    });
  }

  getUserData(): Promise<UserData> {
    return new Promise((resolve) => {
      const handleGetUserData = (e: CustomEvent<UserData>) => {
        resolve(e.detail);
        // @ts-expect-error
        this.removeEventListener("getUserDataResponse", handleGetUserData);
      };
      // @ts-expect-error
      this.addEventListener("getUserDataResponse", handleGetUserData);
      window.FugoBridge.getUserData();
    });
  }

  getProductData(skus: string[]): Promise<ProductData> {
    return new Promise((resolve) => {
      const handleGetProductData = (e: CustomEvent<ProductData>) => {
        resolve(e.detail);
        this.removeEventListener(
          "getProductDataResponse",
          // @ts-expect-error
          handleGetProductData
        );
      };
      // @ts-expect-error
      this.addEventListener("getProductDataResponse", handleGetProductData);
      window.FugoBridge.getProductData(skus);
    });
  }
}

const amazonIapV2: AmazonIapV2 = new AmazonIapV2();
window.AmazonIapV2 = amazonIapV2;
export default amazonIapV2;

const events = [
  "getUserDataResponse",
  "getProductDataResponse",
  "purchaseResponse",
  "getPurchaseUpdatesResponse",
] as const;

type Events = typeof events;

interface AmazonIapV2 {
  addListener: (eventId: Events, cb: () => void) => void;
  removeListener: (eventId: Events, cb: () => void) => void;

  purchase: (sku: string) => Promise<Purchase>;
  getPurchaseUpdates: (reset: boolean) => Promise<PurchaseUpdates>;
  notifyFulfillment: (
    receiptId: string,
    fulfillmentResult: "FULFILLED" | "UNAVAILABLE"
  ) => Promise<unknown>;
  getUserData: () => Promise<UserData>;
  getProductData: (skus: string[]) => Promise<ProductData>;

  fire: (eventJson: string) => void;
}

export interface Receipt {
  itemType: ProductType;
  purchaseDate: string;
  receiptId: string;
  sku: string;
  endDate?: null | string;
}

interface Purchase {
  requestId: string;
  requestStatus: "SUCCESSFUL" | "ALREADY_PURCHASED" | "FAILED" | "INVALID_SKU";
  userData: { marketplace: string; userId: string };
  receipt: Receipt;
}

interface PurchaseUpdates {
  REQUEST_ID: string;
  REQUEST_STATUS: "SUCCESSFUL" | "FAILED";
  USER_DATA: {
    userId: string;
    marketplace: string;
  };
  RECEIPTS: Receipt[];
  HAS_MORE: boolean;
}

interface UserData {
  REQUEST_ID: string;
  REQUEST_STATUS: "SUCCESSFUL" | "FAILED";
  USER_DATA: {
    userId: string;
    marketplace: string;
  };
}

interface ProductData {
  requestId: string;
  UNAVAILABLE_SKUS: string[];
  requestStatus: "SUCCESSFUL";
  productData: {
    [keys: string]: Product;
  };
}

export interface Product {
  sku: string;
  productType: ProductType;
  description: string;
  price: string;
  smallIconUrl: string;
  title: string;
  coinsRewardAmount: 0;
}
type ProductType = "ENTITLED" | "SUBSCRIPTION" | "CONSUMABLE";

declare global {
  interface Window {
    AmazonIapV2: AmazonIapV2;
  }
}
