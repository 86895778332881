export const codecs = [
  "h264",
  "h264-fragmented",
  "webm",
  "iadea",
  "brightsign",
] as const;
type Codecs = typeof codecs[number];
type VideoSizes =
  | "640x360" // 360p
  | "854x480" // 480p
  | "1280x720" // HD
  | "1920x1080" // FullHD
  | "3840x2160"; // 4K
export type VideoCodec = Codecs | `${Codecs}_${VideoSizes}`;

export default function getSupportedVideoCodecs(): VideoCodec[] {
  const videoCodecs: VideoCodec[] = [];
  const testEl = document.createElement("video");

  if (testEl.canPlayType) {
    if (
      "" !==
      (testEl.canPlayType('video/mp4; codecs="avc1.42E01E"') ||
        testEl.canPlayType('video/mp4; codecs="avc1.42E01E, mp4a.40.2"') ||
        testEl.canPlayType('video/mp4; codecs="mp4v.20.8"'))
    ) {
      videoCodecs.push("h264");
    }

    if ("" !== testEl.canPlayType('video/webm; codecs="vp9, vorbis"')) {
      videoCodecs.push("webm");
    }
  }

  return videoCodecs;
}
