import { Textfit } from "@outofaxis/react-textfit";
import { useEffect, useMemo, useRef } from "react";
import replaceAll from "string.prototype.replaceall";
import {
  Colors,
  RgbaColor,
  ShapeContent,
  textEffectStyles,
} from "../MediaUtils";
import styles from "./index.module.css";

export function Shape({ shape }: Props) {
  if (shape.kind === "text") {
    return (
      <pre
        className={`${styles.inset0} ${styles["whitespace-pre-wrap"]} ${styles["break-words"]}`}
        style={{
          fontSize: shape.fontSizeCache,
          fontFamily:
            shape.fontFamily || "Nunito,ui-sans-serif,system-ui,-apple-system",
          willChange: "transform",
          textAlign: shape.align,
          color: rgbaToString(shape.fill),
          ...(shape.effect?.kind
            ? textEffectStyles[shape.effect.kind](shape as any)
            : undefined),
        }}
      >
        <TextTile>{shape.content}</TextTile>
      </pre>
    );
  } else if (shape.kind === "staticText") {
    const isAndroidWebView = window.FugoBridge;
    const apkVersion =
      window.FugoBridge?.getAPKVersion && window.FugoBridge?.getAPKVersion();
    const fontSize =
      isAndroidWebView && apkVersion === "1.8" && shape.fontSizeCache
        ? shape.fontSizeCache / window.devicePixelRatio
        : shape.fontSizeCache;
    return (
      <pre
        className={`${styles["whitespace-pre-wrap"]} ${styles["break-words"]}`}
        style={{
          fontSize,
          fontFamily:
            shape.fontFamily || "Nunito,ui-sans-serif,system-ui,-apple-system",
          willChange: "transform",
          lineHeight: shape.lineHeight,
          letterSpacing: shape.letterSpacing,
          color: rgbaToString(shape.fill),
          ...(shape.effect?.kind
            ? textEffectStyles[shape.effect.kind](shape)
            : undefined),
        }}
      >
        <StaticTextTile>{shape.content}</StaticTextTile>
      </pre>
    );
  } else if (shape.kind === "ellipse") {
    return (
      <div
        className={styles.inset0}
        style={{
          willChange: "transform",
          borderRadius: "50%",
          border: `${shape.border?.width}px solid ${rgbaToString(
            shape.border?.color
          )}`,
          backgroundColor: rgbaToString(shape.fill),
        }}
      />
    );
  } else if (shape.kind === "triangle") {
    return (
      <div
        className={styles.inset0}
        style={{
          willChange: "transform",
          backgroundColor: rgbaToString(shape.fill),
          border: `${shape.border?.width}px solid ${rgbaToString(
            shape.border?.color
          )}`,
          clipPath: "polygon(0% 0%, 100% 0%, 50% 100%)",
        }}
      />
    );
  } else if (shape.kind === "rectangle") {
    return (
      <div
        className={styles.inset0}
        style={{
          willChange: "transform",
          border: `${shape.border?.width}px solid ${rgbaToString(
            shape.border?.color
          )}`,
          backgroundColor: rgbaToString(shape.fill),
          borderRadius: "inherit",
        }}
      />
    );
  } else if (shape.kind === "line") {
    return (
      <div
        className={styles.inset0}
        style={{
          willChange: "transform",
          border: `${shape.border?.width}px solid ${rgbaToString(
            shape.border?.color
          )}`,
          backgroundColor: rgbaToString(shape.fill),
        }}
      />
    );
  }

  return null;
}

function TextTile({ children }: { children?: string }) {
  const textfitRef = useRef<{ handleWindowResize: () => void } | null>(null);

  // this hook fixes text not being fit properly sometimes
  useEffect(() => {
    const timeoutId = setTimeout(
      () => textfitRef.current?.handleWindowResize(),
      500
    );
    return () => clearTimeout(timeoutId);
  }, []);

  const nodes = useMemo(() => deserialize(children || ""), [children]);

  return (
    <Textfit className={styles.inset0} max={1000} min={0} ref={textfitRef}>
      {nodes.map((node) =>
        node.children.map((node) => (
          <span
            style={{
              fontWeight: node.bold ? "bolder" : "400",
              fontStyle: node.italic ? "italic" : undefined,
              textDecoration: node.underline ? "underline" : undefined,
            }}
          >
            {node.text}
          </span>
        ))
      )}
    </Textfit>
  );
}

function StaticTextTile({ children }: { children?: string }) {
  const nodes = useMemo(() => deserialize(children || ""), [children]);

  return (
    <>
      {nodes.map((node, i) => (
        <div key={i}>
          {Array.isArray(node.children)
            ? node.children.map((node, i) =>
                typeof node.text === "string" ? (
                  <span
                    key={i}
                    style={{
                      fontWeight: node.bold ? "bolder" : undefined,
                      fontStyle: node.italic ? "italic" : undefined,
                      textDecoration: node.underline ? "underline" : undefined,
                    }}
                  >
                    {node.text || <br />}
                  </span>
                ) : null
              )
            : null}
        </div>
      ))}
    </>
  );
}

export const rgbaToString = (
  { r, g, b, a = 1 }: RgbaColor = { r: 0, g: 0, b: 0, a: 0 }
) => `rgba(${r}, ${g}, ${b}, ${a})`;

export const colorsToStyle = (colors: Colors): Record<string, string> => {
  return Object.fromEntries(
    Object.entries(colors).map(([key, value]) => [key, rgbaToString(value)])
  );
};

const textStyles: Array<{ splitBy: string; params: TextAttributes }> = [
  { splitBy: "_", params: { underline: true } },
  { splitBy: "***", params: { italic: true, bold: true } },
  { splitBy: "**", params: { bold: true } },
  { splitBy: "*", params: { italic: true } },
];

const markdownEscapedToHtmlEncoded = (value: string) =>
  replaceAll(
    replaceAll(value, `\\*`, `&#${"*".charCodeAt(0)}`),
    `\\_`,
    `&#${"_".charCodeAt(0)}`
  );

const htmlEncodedToText = (value: string) =>
  replaceAll(
    replaceAll(value, `&#${"*".charCodeAt(0)}`, "*"),
    `&#${"_".charCodeAt(0)}`,
    "_"
  );

const deserializeTextStyle = (
  items: Array<Text>,
  splitBy: string,
  attributes: TextAttributes
): Array<Text> =>
  items.flatMap((item) =>
    item.text
      .split(
        new RegExp(
          `${escapeRegexp(splitBy)}([^${escapeRegexp(
            splitBy[0]
          )}]*)${escapeRegexp(splitBy)}`
        )
      )
      .map((text, i) =>
        i % 2
          ? {
              ...item,
              text,
              ...attributes,
            }
          : {
              ...item,
              text,
            }
      )
  );

function escapeRegexp(str: string) {
  return String(str).replace(/([.*+?=^!:${}()|[\]/\\])/g, "\\$1");
}

const deserializeLine = (line: string): Array<Text> =>
  textStyles
    .reduce(
      (result, { splitBy, params }) =>
        deserializeTextStyle(result, splitBy, params),
      [{ text: markdownEscapedToHtmlEncoded(line) }]
    )
    .map(({ text, ...params }) => ({
      text: htmlEncodedToText(text),
      ...params,
    }))
    .filter(({ text }, i, arr) => arr.length <= 1 || Boolean(text));

const deserialize = (string: string) =>
  string.split("\n").map((line) => ({
    children: deserializeLine(line),
  }));

// eslint-disable-next-line @typescript-eslint/no-redeclare
interface Text {
  text: string;

  [key: string]: unknown;
}

type TextAttributes = { bold?: boolean; italic?: boolean; underline?: boolean };

interface Props {
  shape: ShapeContent;
}
