import { useCallback, useEffect, useRef } from "react";
import { getVideoObjectCover } from "../video-playback";
import { ImageFit } from "../../stateMachines/PlaybackMachine";
import { play } from "../video-playback/";

export default function VideoExternal({
  onDone,
  duration,
  isInfinite,
  fit,
  src,
  poster,
  shouldBePlaying,
  onLoad,
}: Props) {
  const videoRef = useRef<HTMLVideoElement>(null);

  const playExternal = useCallback(() => {
    console.log(`playing external video ${src}`);
    play(videoRef, onDone, duration, isInfinite);
    setTimeout(() => {
      if (videoRef.current && videoRef.current.currentTime === 0) {
        onDone("External video failed to play.");
      }
    }, duration);
  }, [onDone, duration, isInfinite, src]);

  useEffect(() => {
    if (shouldBePlaying) {
      playExternal();
    }
  }, [playExternal, shouldBePlaying]);

  const handleCanPlay = () => {
    onLoad();
    if (shouldBePlaying) {
      playExternal();
    }
  };

  return (
    <video
      ref={videoRef}
      onCanPlay={handleCanPlay}
      onEnded={() => onDone()}
      poster={poster}
      style={{
        width: "100%",
        height: "100%",
        objectFit: getVideoObjectCover(fit),
      }}
      autoPlay={false}
    >
      <source src={src} />
    </video>
  );
}

interface Props {
  onLoad: () => void;
  onDone: (err?: string) => void;
  src: string;
  poster: string;
  isInfinite: boolean;
  shouldBePlaying: boolean;
  isMuted: boolean;
  fit?: ImageFit;
  duration: number;
}
