import { Machine, sendParent, assign } from "xstate";
import { NotSupported } from "./PlayerMachine";

export const brightnessMachine = Machine<
  BrightnessMachineContext,
  BrightnessMachineEvents
>(
  {
    id: "brightnessMachine",
    initial: "idle",
    context: {
      brightnessSchedule: null,
    },
    on: {
      UPDATE_SETTINGS: {
        actions: "updateSettings",
        target: "idle",
      },
    },
    states: {
      idle: {
        always: [
          {
            cond: "isScheduleNotEmpty",
            target: "updateCurrentBrightness",
          },
        ],
      },
      updateCurrentBrightness: {
        always: {
          actions: "sendParentCurrentBrightness",
          target: "scheduleNextUpdate",
        },
      },
      scheduleNextUpdate: {
        invoke: {
          id: "scheduleNextUpdate",
          src: scheduleNextUpdate,
          onDone: {
            target: "updateCurrentBrightness",
          },
        },
      },
    },
  },
  {
    actions: {
      sendParentCurrentBrightness: sendParent((context) => {
        const currentRange = getCurrentRange(context);
        const brightness =
          currentRange?.brightnessLevel || context.brightnessSchedule?.default;
        if (brightness) {
          return {
            type: "UPDATE_BRIGHTNESS",
            payload: {
              brightness,
            },
          } as UpdateBrightnessEvent;
        } else {
          return { type: "NOOP" };
        }
      }),
      updateSettings: assign({
        brightnessSchedule: (_context: BrightnessMachineContext, event: any) =>
          event.settings.brightnessSchedule,
      }),
    },
    guards: {
      isScheduleNotEmpty: (context) => {
        if (!context) return false;
        if (!context.brightnessSchedule) return false;
        if (context.brightnessSchedule.default) return true;
        return context.brightnessSchedule.schedule.length > 0;
      },
    },
  }
);

async function scheduleNextUpdate(context: BrightnessMachineContext) {
  const msToNextUpdate = calcMsToNextUpdate(context);
  await sleep(msToNextUpdate);
}

function calcMsToNextUpdate(context: BrightnessMachineContext): Millis {
  const currentMillis = getCurrentDayMillis();
  const currentRange = getCurrentRange(context);
  if (currentRange) {
    return currentRange.end - currentMillis;
  }

  const nextRange = context.brightnessSchedule?.schedule.sort(
    (a, b) => a.start - b.start
  )[0];
  if (nextRange) {
    return nextRange.start - currentMillis;
  }

  return 1000 * 60 * 60 * 24;
}

function getCurrentRange(
  context: BrightnessMachineContext
): BrightnessScheduleUnit | null {
  const currentMillis = getCurrentDayMillis();
  const currentRange = context.brightnessSchedule?.schedule.find(
    (unit) => unit.start >= currentMillis && unit.end < currentMillis
  );
  if (currentRange) {
    return currentRange;
  }
  return null;
}

function getCurrentDayMillis(): Millis {
  var dt = new Date();
  var millis =
    dt.getMilliseconds() +
    dt.getSeconds() * 1000 +
    1000 * 60 * dt.getMinutes() +
    1000 * 60 * 60 * dt.getHours();
  return millis;
}

async function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), ms);
  });
}

type Millis = number;
type DayMillis = number; // 0 – 86,400,000
type Brightness = number; // 0 – 1

export type BrightnessSchedule =
  | {
      schedule: BrightnessScheduleUnit[];
      default?: Brightness;
    }
  | NotSupported;

type BrightnessScheduleUnit = {
  start: DayMillis;
  end: DayMillis;
  brightnessLevel: Brightness;
};

export interface UpdateBrightnessEvent {
  type: "UPDATE_BRIGHTNESS";
  payload: {
    brightness: number;
  };
}

interface BrightnessMachineContext {
  brightnessSchedule: BrightnessSchedule;
}

type BrightnessMachineEvents = {
  type: "UPDATE_SETTINGS";
  settings: {
    brightnessSchedule: BrightnessSchedule;
  };
};
