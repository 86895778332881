import { DocumentFormat } from "../misc/get-supported-document-format";
import { ImageFormat } from "../misc/get-supported-image-format";
import { codecs, VideoCodec } from "../misc/get-supported-video-codecs";
import { shouldUseTizenAPI } from "../stateMachines/PlaybackMachine";

export function makePullQueryVariables(
  tenantId: string,
  playerId: string,
  pullHash: string | undefined,
  currentTime: moment.Moment,
  screenOrientation: number,
  videoCodec: VideoCodec,
  imageFormat: ImageFormat,
  documentFormat: DocumentFormat
) {
  const shouldServerRotateVideos = !shouldUseTizenAPI();
  const videoRotation = shouldServerRotateVideos ? screenOrientation : 0;
  const requestedFormats = [documentFormat];
  const variables: any = {
    tenantId,
    playerId,
    videoCodec,
    localDate: currentTime.format("YYYY-MM-DD"),
    videoFormatNames: getVideoFormatNames(videoRotation, videoCodec),
    imageFormatNames: [
      imageFormat === "original" ? "fullHDImage" : imageFormat,
    ],
    audioFormatNames: ["mp3"],
    documentFormatNamesMap: [
      { formatName: "pdf", requestedFormats },
      { formatName: "pptx", requestedFormats },
    ],
  };

  if (pullHash) {
    variables.hash = pullHash;
  }

  return variables;
}

function getVideoFormatNames(rotation: number, codec?: VideoCodec): String[] {
  const toExtendedFormatName = (
    codec: string,
    rotation: number,
    width: number = 1280,
    height: number = 720
  ) => `${codec}_${width}x${height}_${rotation}deg`;

  // legacy
  if (codec?.toLowerCase() === "vp9") {
    return [toExtendedFormatName("webm", rotation)];
  }

  const codecRegex = new RegExp(`(${codecs.join("|")})_(\\d+)x(\\d+)`);
  if (codec && codecRegex.test(codec)) {
    const matches = codecRegex.exec(codec);
    if (matches) {
      const [, codecName, widthString, heightString] = matches;
      const width = ~~widthString;
      const height = ~~heightString;
      if (codecName && width && height) {
        return [toExtendedFormatName(codecName, rotation, ~~width, ~~height)];
      }
    }
  }
  return [toExtendedFormatName(codec ?? "h264andmp4withaudio", rotation)];
}

export const pullQuery = `
  query Queries($tenantId: String!, $playerId: String!, $localDate: String!, $videoFormatNames: [String!]!, $imageFormatNames: [String!]!, $audioFormatNames: [String!]!, $documentFormatNamesMap: [DocumentFormatNamesInput!], $hash: String) {
    pull(tenantId: $tenantId, playerId: $playerId, localDate: $localDate, videoFormatNames: $videoFormatNames, imageFormatNames: $imageFormatNames, audioFormatNames: $audioFormatNames, documentFormatNamesMap: $documentFormatNamesMap, hash: $hash) {
      __typename
    
      ... on Pulled {
        playlists {
          playlistId
          teamId
          name
          duration
          contents {
            __typename
            
            ... on App {
              appId
              duration
              config
              mediaIds  
            }
            
            ... on Content {
              contentId
              duration
              body
              mediaIds
              settings
            }
            
            ... on Media {
              mediaId
              duration
              config
            }

            ... on Dashboard {
              dashboardId
              duration
              config
            }
          }
          startDate
          endDate
          weekSchedule {
            start {
              dayOfWeek
              time
            }
            end {
              dayOfWeek
              time
            }
          }
          timerEvents {
            name
            start
            end
          }
          manualEvent {
            ... on Standby {
              __typename
            }
            ... on PlayLoop {
              __typename
            }
            ... on PlayOnce {
              __typename
              timestamp
            }
          }
        }
        medias {
          __typename
          mediaId
          formatUrl
          originalUrl
          
          ... on FormattedVideo {
            duration
            codec
            frames {
              first {
                url
              }
              last {
                url
              }
            }
          }

          ... on FormattedDocument {
            format
            formatName
            formatSizeBytes
          }
        }
        hash
      }
    }
  }
`;
