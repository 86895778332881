import { ReactNode, useRef, useState } from "react";
import { useRect } from "@reach/rect";
import styles from "./Contain.module.css";

export const Contain = ({
  children,
  aspectRatio,
}: {
  children: ({ width, height }: { width: number; height: number }) => ReactNode;
  aspectRatio: number;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState({ width: 0, height: 0 });
  useRect(ref, {
    onChange: () => {
      if (ref.current) {
        const { offsetWidth, offsetHeight } = ref.current;
        const containerAspectRatio = offsetWidth / offsetHeight;
        const isLandscape = containerAspectRatio > aspectRatio;
        if (isLandscape) {
          setSize({
            width: offsetHeight * aspectRatio,
            height: offsetHeight,
          });
        } else {
          setSize({
            width: offsetWidth,
            height: offsetWidth / aspectRatio,
          });
        }
      }
    },
  });
  return (
    <div key={aspectRatio} className={styles.center} ref={ref}>
      {children(size)}
    </div>
  );
};
