import { assign, createMachine, sendParent } from "xstate";
import { fetchMachine } from "./FetchMachine";
import { makePullQueryVariables, pullQuery } from "../graphql/PullQuery";
import {
  DateTimeSource,
  getCurrentTime,
  getDefaultDateTimeSource,
  initialTimeData,
  TimeData,
  updateTime,
  UpdateTimeEvent,
} from "../TimeUtils";
import {
  FormattedMedia,
  FormattedVideo,
  Playlist,
  Queries,
} from "../generated/router";
import { VideoCodec } from "../misc/get-supported-video-codecs";
import getDefaultVideoCodec from "../misc/get-default-video-codec";
import { UpdateSettingsEvent } from "./CommandsMachine";
import getDefaultImageFormat from "../misc/get-default-image-format";
import { ImageFormat } from "../misc/get-supported-image-format";
import getDefaultMediaCacheUrl from "../misc/get-default-media-cache-url";
import { DocumentFormat } from "../misc/get-supported-document-format";
import { getDefaultDocumentFormat } from "../misc/get-default-document-format";

interface PullMachineContext {
  playerId: string;
  tenantId: string;
  pullHash: string;
  mediaCacheUrl: string;
  timeData: TimeData;
  screenOrientation: number;
  videoCodec: VideoCodec;
  imageFormat: ImageFormat;
  documentFormat: DocumentFormat;
  dateTimeSource: DateTimeSource;
  playlists: Playlist[];
}

export type UpdatedPulledDataEvent = {
  type: "UPDATE_PULLED_DATA";
  hash: string;
  playlists: Playlist[];
  medias: FormattedMedia[];
};

type PullMachineEvent = UpdateTimeEvent | UpdateSettingsEvent;

export const pullMachine = createMachine(
  {
    schema: {
      context: {} as PullMachineContext,
      events: {} as PullMachineEvent,
      services: { pull: { data: {} as FetchMachineResult } },
    },
    tsTypes: {} as import("./PullMachine.typegen").Typegen0,
    context: {
      playerId: "",
      tenantId: "",
      pullHash: "",
      mediaCacheUrl: getDefaultMediaCacheUrl(),
      playlists: [],
      timeData: initialTimeData,
      screenOrientation: 0,
      videoCodec: getDefaultVideoCodec(),
      imageFormat: getDefaultImageFormat(),
      documentFormat: getDefaultDocumentFormat(),
      dateTimeSource: getDefaultDateTimeSource(),
    },
    initial: "waitingForTimeSync",
    on: {
      UPDATE_TIME: {
        target: "pull",
        actions: "updateTime",
      },
      UPDATE_SETTINGS: {
        target: "pull",
        actions: "updateSettings",
      },
    },
    states: {
      waitingForTimeSync: {},
      idle: {
        after: {
          PLAYER_PULL_DELAY: "pull",
        },
      },
      pull: {
        invoke: {
          src: "pull",
          data: (context) => ({
            url: process.env.REACT_APP_ROUTER_HOST,
            requestBody: {
              query: pullQuery,
              variables: makePullQueryVariables(
                context.tenantId,
                context.playerId,
                context.pullHash,
                getCurrentTime(context.timeData, context.dateTimeSource),
                context.screenOrientation,
                context.videoCodec,
                context.imageFormat,
                context.documentFormat
              ),
            },
          }),
          onDone: [
            {
              target: "idle",
              cond: "isPlayerPullPulled",
              actions: ["savePull", "notifyParentPulledDataUpdated"],
            },
            {
              cond: "isPlayerPullUnregistered",
              actions: "notifyParentPlayerUnregistered",
            },
            {
              cond: "isPlayerUnpaid",
              actions: "notifyParentPlayerUnpaid",
              target: "idle",
            },
            {
              target: "idle",
            },
          ],
          onError: "idle",
        },
      },
    },
  },
  {
    actions: {
      updateTime,

      notifyParentPulledDataUpdated: sendParent((context, event) => {
        if (event.data.body?.data?.pull?.__typename !== "Pulled") {
          throw new Error(
            "This will never happen because this is guarded with `isPlayerPullPulled` but this is needed for TypeScript."
          );
        }
        const data = event.data.body.data.pull;
        if (shouldUseMediaCache(context.tenantId)) {
          data.medias = replaceMediaUrls(data.medias, context.mediaCacheUrl);
        }
        return {
          type: "UPDATE_PULLED_DATA",
          ...data,
        };
      }),

      notifyParentPlayerUnregistered: sendParent("PLAYER_UNREGISTERED"),

      notifyParentPlayerUnpaid: sendParent("PLAYER_UNPAID"),

      savePull: assign((_context, event: any) => ({
        pullHash: event.data.body.data.pull.hash,
        playlists: event.data.body.data.pull.playlists,
      })),

      // @ts-expect-error
      updateSettings: assign((context, event) => ({
        pullHash: "",
        videoCodec:
          "videoCodec" in event.settings
            ? event.settings.videoCodec
            : context.videoCodec,
        imageFormat:
          "imageFormat" in event.settings
            ? event.settings.imageFormat
            : context.imageFormat,
        documentFormat:
          "documentFormat" in event.settings
            ? event.settings.documentFormat
            : context.documentFormat,
        screenOrientation:
          "screenOrientation" in event.settings
            ? event.settings.screenOrientation
            : context.screenOrientation,
        dateTimeSource:
          "dateTimeSource" in event.settings
            ? event.settings.dateTimeSource
            : context.dateTimeSource,
        mediaCacheUrl:
          "mediaCacheUrl" in event.settings
            ? event.settings.mediaCacheUrl
            : context.mediaCacheUrl,
      })),
    },

    delays: {
      PLAYER_PULL_DELAY: (context) =>
        context.playlists.length ? 10_000 : 4_000,
    },

    guards: {
      isPlayerPullPulled: (context, event) =>
        event.data.body?.data?.pull?.__typename === "Pulled" &&
        event.data.body?.data?.pull?.hash !== context.pullHash,

      isPlayerPullUnregistered: (_context, event) =>
        event.data.body?.data?.pull?.__typename === "Unregistered",

      isPlayerUnpaid: (_context, event) =>
        event.data.body?.data?.pull?.__typename === "Unpaid",
    },

    services: {
      pull: fetchMachine,
    },
  }
);

export interface FetchMachineResult {
  body?: {
    data?: Queries;
  };
}

function replaceMediaUrls(
  medias: Array<FormattedMedia>,
  mediaCacheBaseUrl: string
): Array<FormattedMedia> {
  const newMedias: Array<FormattedMedia> = JSON.parse(JSON.stringify(medias)); // deep clone
  return newMedias.map((media) => {
    media.formatUrl = genMediaCacheUrl(media.formatUrl, mediaCacheBaseUrl);
    media.originalUrl = genMediaCacheUrl(media.originalUrl, mediaCacheBaseUrl);
    if (isFormattedVideo(media)) {
      if (media.frames?.first?.url) {
        media.frames.first.url = genMediaCacheUrl(
          media.frames?.first?.url,
          mediaCacheBaseUrl
        );
      }
      if (media.frames?.last?.url) {
        media.frames.last.url = genMediaCacheUrl(
          media.frames?.last?.url,
          mediaCacheBaseUrl
        );
      }
    }
    return media;
  });
}

function genMediaCacheUrl(url: string, baseUrl: string): string {
  if (!url) return url;
  return url.replace("https://media.fugo.ai/", baseUrl);
}

function shouldUseMediaCache(tenantId: string): boolean {
  return tenantId !== "14af4ac5-8de2-4870-9679-b1bbfd039a79";
}

function isFormattedVideo(media: any): media is FormattedVideo {
  return media.__typename === "FormattedVideo";
}
