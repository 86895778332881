import { assign, DoneInvokeEvent, Machine, sendParent } from "xstate";
import { fetchMachine } from "./FetchMachine";

const MAX_TIME_DIFF = 5 * 1000;
const TIME_CHECK_INTERVAL = 15 * 1000;

interface TimeSyncMachineContext {
  lastMachineTime: number;
}

const timezonedbUrl = `https://vip.timezonedb.com/v2.1/get-time-zone?by=ip&fields=timestamp,gmtOffset,zoneName&format=json&key=${process.env.REACT_APP_TIMEZONEDB_API_KEY}`;

type TimeSyncMachineEvent = DoneInvokeEvent<any>;

export const timeSyncMachine = Machine<
  TimeSyncMachineContext,
  TimeSyncMachineEvent
>(
  {
    context: {
      lastMachineTime: 0,
    },
    initial: "sync",
    states: {
      idle: {
        after: {
          TIME_CHECK_INTERVAL: "timeCheck",
        },
      },
      timeCheck: {
        always: [
          {
            target: "idle",
            actions: "updateLastMachineTime",
            cond: "hasMachineTimeChanged",
          },
          {
            target: "sync",
            cond: "hasMachineTimeUnchanged",
          },
        ],
      },
      sync: {
        invoke: {
          src: fetchMachine,
          data: {
            url: timezonedbUrl,
          },
          onDone: [
            {
              target: "idle",
              cond: "isTimeFetchOk",
              actions: ["updateTime", "updateLastMachineTime"],
            },
            {
              target: "idle",
              actions: ["skip", "updateLastMachineTime"],
            },
          ],
        },
      },
    },
  },
  {
    actions: {
      updateTime: sendParent((_context, event) => {
        const body = event.data.body;
        const timestamp = body.timestamp;
        const gmtOffset = body.gmtOffset;
        const zoneName = body.zoneName;

        const netTime = (timestamp - gmtOffset) * 1000;
        const lastMachineTime = Date.now();

        const timeDiffInSeconds = ~~((netTime - lastMachineTime) / 1000);

        return {
          type: "UPDATE_TIME",
          timeData: {
            timeDiffInSeconds: timeDiffInSeconds,
            zoneName: zoneName,
          },
        };
      }),

      updateLastMachineTime: assign({
        lastMachineTime: (_) => Date.now(),
      }),

      skip: sendParent((_context) => {
        return {
          type: "UPDATE_TIME",
          timeData: {
            timeDiffInSeconds: 0,
            zoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        };
      }),
    },

    delays: {
      TIME_FETCH_INTERVAL: 30 * 60 * 1000,
      TIME_CHECK_INTERVAL: TIME_CHECK_INTERVAL,
    },

    guards: {
      isTimeFetchOk: (_context, event) => event.data?.body?.status === "OK",

      hasMachineTimeChanged: (_context) =>
        hasMachineTimeChanged(_context.lastMachineTime),

      hasMachineTimeUnchanged: (_context) =>
        !hasMachineTimeChanged(_context.lastMachineTime),
    },
  }
);

function hasMachineTimeChanged(lastMachineTime: number): boolean {
  return (
    Math.abs(Date.now() - lastMachineTime - TIME_CHECK_INTERVAL) < MAX_TIME_DIFF
  );
}
