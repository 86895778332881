import {
  useMemo,
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import styles from "./index.module.css";

export default forwardRef(function HtmlVideo(
  props: {
    src: string;
    poster?: string;
    style: React.CSSProperties;
    muted: boolean;
    onEnded: (this: HTMLVideoElement, ev: Event) => any;
    onTimeUpdate: any;
    onLoad: any;
    onLoadedData: any;
    onCanPlay: any;
    hwz?: string;
  },
  ref
) {
  const rootRef = useRef<HTMLDivElement | null>(null);
  const video = useMemo(() => {
    const vid = getVideoElement();
    return vid;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(
    ref,
    () => {
      return video.video;
    },
    [video.video]
  );

  useEffect(() => {
    if (!props.src) return;
    const videoEl = video.video;
    // @ts-expect-error
    videoEl.hwz = props.hwz ?? "";
    videoEl.src = props.src;
    videoEl.style.width = `${props.style.width}`;
    videoEl.style.height = `${props.style.height}`;
    videoEl.addEventListener("ended", props.onEnded);

    videoEl.addEventListener("onLoad", props.onLoad);
    videoEl.addEventListener("onLoadedData", props.onLoadedData);
    videoEl.addEventListener("onCanPlay", props.onCanPlay);
    videoEl.load();
    videoEl.play().catch(() => {
      videoEl.muted = true;
      videoEl.play();
    });

    rootRef.current?.appendChild(videoEl);

    return () => {
      freeVideoElement(video.id);
      videoEl.removeEventListener("ended", props.onEnded);
      videoEl.removeEventListener("onLoad", props.onLoad);
      videoEl.removeEventListener("onLoadedData", props.onLoadedData);
      videoEl.removeEventListener("onCanPlay", props.onCanPlay);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.src]);

  useEffect(() => {
    const videoEl = video.video;
    videoEl.addEventListener("onTimeUpdate", props.onTimeUpdate);
    return () => {
      videoEl.removeEventListener("onTimeUpdate", props.onTimeUpdate);
    };
  }, [props.onTimeUpdate, video.video]);

  useEffect(() => {
    const videoEl = video.video;
    videoEl.muted = props.muted;
    videoEl.style.width = `${props.style.width}`;
    videoEl.style.height = `${props.style.height}`;
  }, [props.muted, video.video, props.style.width, props.style.height]);

  return <div ref={rootRef} className={styles.video} style={props.style} />;
});

const videoElements: Array<{
  id: number;
  isFree: boolean;
  video: HTMLVideoElement;
}> = [];

function getVideoElement() {
  const freeVideoElement = getFreeVideoElement();
  if (freeVideoElement) return freeVideoElement;

  const newVideoElement = createVideoElement();
  videoElements.push(newVideoElement);
  return newVideoElement;
}

function getFreeVideoElement() {
  const freeEl = videoElements.find((el) => el.isFree);
  if (!freeEl) return null;

  freeEl.isFree = false;
  log("Using free HTMLVideoElement with ID of %s", freeEl.id);

  return freeEl;
}

function createVideoElement() {
  const id = Date.now() + Math.random();
  const video = document.createElement("video");
  video.preload = "auto";
  video.playsInline = true;
  video.className = styles.video;
  video.muted = true;

  log(
    "Created HTMLVideoElement with ID of %s, there are %s videos in the pool",
    id,
    videoElements.length + 1
  );

  return {
    id,
    isFree: false,
    video,
  };
}

function freeVideoElement(id: number) {
  const el = videoElements.find((el) => el.id === id);

  if (!el) {
    log("Did not find HTMLVideoElement to free with ID of %s", id);
    return;
  }

  log("Free HTMLVideoElement with ID of %s", id);
  el.isFree = true;
  el.video.src = "";
}

function log(msg: any, ...args: Array<any>) {
  console.log(`[${new Date().toISOString()}][Video Pool] ${msg}`, ...args);
}
