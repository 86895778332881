import { Machine, assign, DoneInvokeEvent } from "xstate";

interface FetchMachineContext {
  url: string;
  requestBody?: any;
  body?: any;
  error?: string;
  headers: Record<string, string>;
}

type FetchMachineEvent = DoneInvokeEvent<any>;

export const fetchMachine = Machine<FetchMachineContext, FetchMachineEvent>(
  {
    initial: "loading",
    id: "fetch",
    context: {
      url: "",
      requestBody: undefined,
      headers: {},
    },
    states: {
      loading: {
        invoke: {
          src: (context, _) => {
            const headers = { ...context.headers };

            // timezonedb sets incorrect CORS in case of application/json
            if (!context.url?.includes("timezonedb.com/")) {
              headers["content-type"] = "application/json";
            }

            const options: RequestInit = {
              method: context.requestBody ? "POST" : "GET",
              headers,
              body: context.requestBody
                ? JSON.stringify(context.requestBody)
                : null,
              cache: "no-cache",
            };

            return fetch(context.url, options)
              .then((response) =>
                Promise.all([Promise.resolve(response), response.json()])
              )
              .then(([response, body]) => ({ response, body }))
              .catch((e) => e);
          },
          onDone: [
            {
              target: "successFinal",
              cond: "isResponseStatusCodeOk",
              actions: "assignResponseBody",
            },
            {
              target: "failureFinal",
              actions: assign({
                error: (_) => "Status code was not between 200-299",
              }),
            },
          ],
          onError: {
            target: "failureFinal",
            actions: assign({ error: (_) => "Network error has occurred" }),
          },
        },
        after: {
          TIMEOUT_DELAY: {
            target: "failureFinal",
            actions: assign({
              error: (_) =>
                `Timeout has occur after waiting for 7000 milliseconds`,
            }),
          },
        },
      },
      successFinal: {
        type: "final",
        data: (context, _) => ({ body: context.body }),
      },
      failureFinal: {
        type: "final",
        data: (context, _) => ({ error: context.error }),
      },
    },
  },
  {
    delays: {
      TIMEOUT_DELAY: 7000,
    },

    actions: {
      assignResponseBody: assign((_, event) => {
        return { body: event.data.body };
      }),
    },

    guards: {
      isResponseStatusCodeOk: (_, event) => event.data?.response?.ok,
    },
  }
);
