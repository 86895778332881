import { Info } from "../stateMachines/PlayerMachine";

export function makeGeneratePinVariables(playerType: String, info: Info) {
  return {
    playerType: playerType,
    info,
  };
}

export const generatePinMutation = `
  mutation PlayerMutation($playerType: PlayerType!, $info: Json!) {
    generatePin(playerType: $playerType, info: $info) {
      pin
      playerId
      timeToLive
    }
  }
`;
