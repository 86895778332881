export function makeCompleteRegistrationVariables(playerId?: string) {
  return {
    playerId,
  };
}

export const completeRegistrationMutation = `
  mutation PlayerMutation($playerId: String!) {
    completeRegistration(playerId: $playerId) {
      playerId
      tenantId
      settings
    }
  }
`;
