import { useEffect, useRef } from "react";
import Hls from "hls.js";
import HTMLVideo from "../../../video-playback/html-video";

export default function VideoStream(props: Props) {
  const { url, isMuted, shouldBePlaying, onLoad } = {
    ...defaults,
    ...props,
  };
  const videoRef = useRef<HTMLVideoElement>(null);
  const video = videoRef.current;

  useEffect(() => {
    if (video) {
      console.log("Using hls.js to play HLS stream");
      var hls = new Hls({
        debug: false,
      });
      hls.loadSource(url);
      hls.attachMedia(video);
      return () => {
        hls.destroy();
      };
    }
  }, [url, video]);

  useEffect(() => {
    if (shouldBePlaying && videoRef.current) {
      play(videoRef.current);
    }
  }, [shouldBePlaying]);

  return (
    <HTMLVideo
      ref={videoRef}
      onEnded={noop}
      onTimeUpdate={noop}
      onLoad={onLoad}
      onLoadedData={onLoad}
      style={{}}
      muted={isMuted}
      poster=""
      onCanPlay={noop}
      src={url}
    />
  );
}

function noop() {}

async function play(videoEl: HTMLVideoElement, started = Date.now()) {
  const timeout = 30000;

  // already playing
  if (videoEl.currentTime > 1 && !videoEl.ended) {
    console.debug(`video is already playing ${videoEl.currentTime}`);
    return;
  }

  // timeout
  if (started + timeout < Date.now()) {
    console.log(
      `video playback did not start, timeout: ${started} + ${timeout} > ${Date.now()}`
    );
    return;
  }

  try {
    console.debug(`playing video`);
    const id = videoEl.poster;
    console.time(`Start playing the video ${id}`);
    await videoEl.play();
    console.timeEnd(`Start playing the video ${id}`);
  } catch (e) {
    console.debug(`video error: ${e}`);
    if (!videoEl) {
      console.debug(`video element is already unmounted: firing Done callback`);
      return;
    }
    const delay = videoEl.muted ? 1000 : 0;
    videoEl.muted = true;
    console.log(`Retrying to play the video in ${delay} ms`);
    setTimeout(() => play(videoEl, started), delay);
  }
}

const defaults: Param = {
  duration: 30000,
  url: "https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8",
  isMuted: false,
};

type Param = {
  duration: number;
  url: string;
  proxyUrl?: string;
  shouldSync?: boolean;
  isMuted: boolean;
};

type Props = Param & { shouldBePlaying: boolean; onLoad: () => void };
