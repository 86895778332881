import { checkWebPSupport } from "supports-webp-sync";

export const formats = ["original", "webp"] as const;
export type ImageFormat = typeof formats[number];

export default function getSupportedImageFormat(): ImageFormat {
  try {
    return checkWebPSupport() ? "webp" : "original";
  } catch (e) {
    console.log("Error in getSupportedImageFormat: " + e);
  }
  return "original";
}
