export const pullPendingCommands = `
  query pendingCommands($tenantId: String!, $playerId: String!) {
    pendingCommands(tenantId: $tenantId, playerId: $playerId) {
      commandType
      commandId
      playerSetting
      updatedBy
      updatedAt
    }
  }
`;
