import React, { CSSProperties, useEffect } from "react";
import { useMachine } from "@xstate/react";
import {
  createPlayerMachine,
  PlayerContext,
  PlayerEvent,
} from "./stateMachines/PlayerMachine";
import { Playback } from "./components/playback";
import PinScreen from "./components/pin-screen";
import { State } from "xstate";
import "animate.css";
import useAmazonIAP from "./amazon-iap/use-amazon-iap";
import { getScreenOrientationCssClasses } from "./misc/get-screen-orientation-css-classes";
import {
  getTransitionDuration,
  PlaybackMachineContext,
} from "./stateMachines/PlaybackMachine";
import HelpOverlay from "./components/help-overlay";
import { setHealthy } from "./misc/healthCheck";
import { PasswordOverlay } from "./components/password";
import Casting from "./components/casting";
import { CastingMachineContext } from "./stateMachines/CastingMachine";
import { CastingInstructions } from "./components/casting-instructions";
import { log } from "./misc/log";
// import { inspect } from "@xstate/inspect";

// inspect({
//   url: "https://statecharts.io/inspect",
//   iframe: false,
// });

type AppContentProps = {
  state: State<PlayerContext, any, any, any, any>;
  send: (event: PlayerEvent["type"] | PlayerEvent) => void;
};

const AppContent = ({ state, send }: AppContentProps) => {
  log({ playerState: state.value });
  if (
    state.matches("introVideo") ||
    state.matches("loadContext") ||
    state.matches("init") ||
    state.matches("fetchPin")
  ) {
    return (
      <video
        src="/intro.mp4"
        poster="/intro.jpg"
        autoPlay
        muted
        onEnded={() => send("INTRO_VIDEO_FINISHED")}
        className="fullScreenFixed"
      />
    );
  }

  if (state.matches("fail.fetchPin")) {
    return (
      <div>
        An error has happened. Getting PIN from the server has failed. Retrying
        in a moment ({state.context.pinRetries}) {state.context.lastError}
      </div>
    );
  }

  if (state.matches("fail")) {
    return <div>An error has happened. {state.context.lastError}</div>;
  }

  if (state.matches("checkPassword.waitingForPassword")) {
    return (
      <PasswordOverlay
        passwordIsIncorrect={state.matches(
          "checkPassword.waitingForPassword.submittedPasswordIsIncorrect"
        )}
        onSubmit={(password) => {
          send({
            type: "PASSWORD_SUBMIT",
            password,
          });
        }}
        onClear={() => {
          send("CLEAR_PASSWORD_ERROR");
        }}
      />
    );
  }

  if (
    (state.matches("pairing") || state.matches("paired")) &&
    state.context.pin
  ) {
    return (
      <PinScreen
        pin={state.context.pin}
        isExiting={state.matches("paired")}
        isAmazonRelease={state.context.isAmazonRelease}
      />
    );
  }

  if (state.matches("unpaid")) {
    return <Unpaid />;
  }

  if (state.matches("playback")) {
    // @ts-expect-error
    const playbackState = state.children.playbackMachine.state;
    const playbackContext = playbackState.context as PlaybackMachineContext;

    log({ playbackState: playbackState.value });

    if (playbackState.matches("mediaSynchronization")) {
      return (
        <div className="loading-assets">
          <div>Downloading Media...</div>
        </div>
      );
    }

    if (state.matches("playback.casting")) {
      // @ts-expect-error
      const castingMachineContext = state.children.castingMachine.state
        .context as CastingMachineContext;
      return (
        <Casting
          streams={castingMachineContext.streams}
          onError={() => send("STREAMING_ERROR")}
        />
      );
    }

    if (playbackState.matches("emptyPlaylist")) {
      return <EmptyPlaylist />;
    }

    if (state.matches("playback.savingPower")) {
      return null;
    }

    return (
      <HelpOverlay send={send}>
        <div className="playback">
          <Playback
            playbackState={playbackState}
            playbackContext={playbackContext}
            send={send}
            settings={state.context.settings}
            medias={state.context.medias}
            tenantId={state.context.tenantId}
            playerId={state.context.playerId}
          />
        </div>
        {state.context.settings.shouldDisplayCastingInstructions ? (
          <CastingInstructions
            instructions={state.context.settings.wifiInstructions}
            options={state.context.settings.castingInstructionsOptions}
            castPin={state.context.castPin}
          />
        ) : null}
      </HelpOverlay>
    );
  }

  return null;
};

const Unpaid = () => {
  useEffect(() => {
    setHealthy();
  }, []);
  return (
    <div className="loading-assets">
      Your screens have now been disabled, please visit https://fugo.ai to
      activate your account.
    </div>
  );
};

const EmptyPlaylist = () => {
  useEffect(() => {
    setHealthy();
  }, []);
  return (
    <div className="loading-assets">
      To display your content, publish a playlist to this screen
    </div>
  );
};

const App: React.FC = () => {
  // @ts-ignore
  const [state, send] = useMachine(createPlayerMachine, { devTools: false });
  const { TrialMessage } = useAmazonIAP();

  const brightness = !window.b2bapis ? state.context.currentBrightness : null;
  const style = {
    "--transition-duration": `${getTransitionDuration(
      state.context.settings
    )}ms`,
    "--brightness": brightness,
  } as CSSProperties;

  const screenOrientationCss = getScreenOrientationCssClasses(
    state.context.settings.screenOrientation
  );

  return (
    <div
      id="app-root"
      className={screenOrientationCss}
      data-testid={screenOrientationCss}
      style={style}
    >
      <TrialMessage />
      <AppContent state={state} send={send} />
    </div>
  );
};

export default App;
