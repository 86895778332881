import { useRef, memo, useEffect } from "react";
import { App, FormattedMedia } from "../../generated/router";
import { Machine } from "xstate";
import { useMachine } from "@xstate/react/lib";
import useAppMessageHandler from "./use-app-message-handler";
import { ProtocolHeaders, Event } from "./dynamic";
import genSrc from "./gen-src";
import VideoStream from "./embedded/video-stream";
import React from "react";
import { OnPremAppScreenshots } from "./embedded/on-prem-app-screenshots";

type StaticAppProps = {
  content: App;
  onContentReady: () => void;
  medias: FormattedMedia[];
  headers: ProtocolHeaders;
  shouldBePlaying: boolean;
};

const embeddedApps: { [key: string]: React.FC<any> } = {
  "video-stream": VideoStream,
  "on-prem-app-screenshots": OnPremAppScreenshots,
};

const StaticApp = ({
  content,
  onContentReady,
  medias,
  headers,
  shouldBePlaying,
}: StaticAppProps) => {
  const messageIdRef = useRef(Math.random().toString(36).substring(2, 15));
  const id = messageIdRef.current;
  const src = genSrc(content.config.url, id);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const sendParam = async () => {
    const iframe = iframeRef.current;
    if (!iframe || !iframe.contentWindow) return;
    const isInfinite = true;
    iframe.contentWindow.postMessage(
      {
        id,
        method: "setParam",
        param: content.config.param,
        headers,
        isInfinite,
      },
      "*"
    );
  };

  const [, send] = useMachine(appMachine, {
    actions: {
      onContentReady,
      sendParam,
      sendPlay: () => {
        iframeRef?.current?.contentWindow?.postMessage(
          {
            id: messageIdRef.current,
            method: "play",
          },
          "*"
        );
      },
    },
  });

  useAppMessageHandler(iframeRef, messageIdRef, send, medias, false, () => {});

  useEffect(() => {
    if (shouldBePlaying) {
      send("PLAY");
    }
  }, [shouldBePlaying, send]);

  const { kindId } = content.config;
  const embeddedApp =
    typeof kindId === "string" && embeddedApps.hasOwnProperty(kindId)
      ? embeddedApps[kindId]
      : undefined;
  if (embeddedApp) {
    return React.createElement(embeddedApp, {
      ...content.config.param,
      shouldBePlaying,
      onLoad: () => send("LOAD"),
    });
  }

  return (
    // eslint-disable-next-line jsx-a11y/iframe-has-title
    <iframe
      ref={iframeRef}
      style={{
        objectFit: "contain",
        width: "100%",
        height: "100%",
        border: "none",
      }}
      // src={`http://localhost:8001/?id=${id}`}
      src={
        process.env.REACT_APP_USE_HTTP
          ? src.replace("https://", "http://")
          : src
      }
      onLoad={() => send("LOAD")}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      sandbox="allow-scripts allow-same-origin allow-popups allow-forms allow-modals allow-top-navigation-by-user-activation allow-popups-to-escape-sandbox allow-presentation"
      allowFullScreen
    />
  );
};

interface AppMachineContext {}

type AppMachineEvent = Event;

const appMachine = Machine<AppMachineContext, AppMachineEvent>({
  initial: "idle",
  states: {
    idle: {
      on: {
        LOAD: {
          target: "iframeLoaded",
        },
      },
    },
    iframeLoaded: {
      after: {
        0: "loaded",
      },
    },
    loaded: {
      entry: ["onContentReady", "sendParam"],
      on: {
        READY: {
          target: "ready",
        },
        PARAM_READY: {
          actions: ["sendParam"],
        },
      },
    },
    ready: {
      on: {
        PLAY: {
          target: "playing",
        },
      },
    },
    playing: {
      entry: ["sendPlay"],
    },
  },
});

export default memo(StaticApp);
