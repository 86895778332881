import { useEffect, useMemo, useState } from "react";
import { Rotate } from "./Rotate";
import {
  ImageFit,
  ImageOrientation,
  shouldUseTizenAPI,
} from "../../stateMachines/PlaybackMachine";
import style from "./index.module.css";
import { getFullTizenPath } from "../../tizen/utils";
import InlineSVG from "react-inlinesvg";
import { Colors } from "../MediaUtils";
import { colorsToStyle } from "../shape";
import { shouldUseScapAPI } from "../../stateMachines/PlaybackMachine";
import { getFullScapPath } from "../../scap/utils";
import { log } from "../../misc/log";

const positions = ["0%", "50%", "100%"];
const randomPosition = () => sample(positions);

export function Image({
  orientation = 0,
  fit = "contain",
  isTinted,
  src,
  onLoad,
  mediaId,
  colors,
}: Props) {
  const [source, setSource] = useState(src);

  useEffect(() => {
    if (shouldUseTizenAPI()) return;
    if (shouldUseScapAPI()) return;

    async function replaceImage(src: string) {
      await loadImage(src);
      setSource(src);
    }

    replaceImage(src);
  }, [src]);

  useEffect(() => {
    if (shouldUseTizenAPI() && mediaId) {
      setTizenUrl(mediaId, setSource);
    }
    if (shouldUseScapAPI() && mediaId) {
      setScapUrl(mediaId, setSource);
    }
    const id = setTimeout(() => onLoad(), 500);
    return () => clearTimeout(id);
  }, [onLoad, mediaId]);

  const kenBurnsEffectOrigin = useMemo(
    () => `${randomPosition()} ${randomPosition()}`,
    []
  );

  const kenBurnsClass = fit === "kenBurnsEffect" ? style.kenBurnsStyle : "";
  const tintClass = isTinted ? style.tint : "";

  log(`Displaying image ${source}`);

  return (
    <Rotate orientation={orientation}>
      <div className={style.wrapper}>
        {src?.toLowerCase().endsWith(".svg") ? (
          <InlineSVG
            className={`${style.wrapper} ${kenBurnsClass} ${tintClass}`}
            style={{
              ...(colors ? colorsToStyle(colors) : undefined),
              transformOrigin:
                fit === "kenBurnsEffect" ? kenBurnsEffectOrigin : undefined,
            }}
            src={fixSVGAssetOrigin(src)}
            data-testid="image"
          />
        ) : (
          <div
            className={`${style.wrapper} ${kenBurnsClass} ${tintClass}`}
            draggable={false}
            style={{
              backgroundImage: `url("${source}")`,
              backgroundRepeat: fit === "tile" ? "repeat" : "no-repeat",
              backgroundSize:
                fit === "cover"
                  ? "cover"
                  : fit === "contain"
                  ? "contain"
                  : fit === "kenBurnsEffect"
                  ? "cover"
                  : fit === "crop"
                  ? "100% 100%"
                  : undefined,
              backgroundPosition: "center center",
              transformOrigin:
                fit === "kenBurnsEffect" ? kenBurnsEffectOrigin : undefined,
            }}
            data-testid="image"
          />
        )}
      </div>
    </Rotate>
  );
}

/** @deprecated fix on BE and remove the hack */
const fixSVGAssetOrigin = (src: string) => {
  return src?.replace("media.fugo.ai/", "media-cache-2.fugo.ai/");
};

function sample<T>(ary: T[]) {
  const i = ~~(Math.random() * ary.length);
  return ary[i];
}

async function setTizenUrl(
  mediaId: string,
  setSource: React.Dispatch<React.SetStateAction<string>>
) {
  const fullPath = await getFullTizenPath(mediaId);
  if (fullPath) {
    setSource(fullPath);
  }
}

async function setScapUrl(
  mediaId: string,
  setSource: React.Dispatch<React.SetStateAction<string>>
) {
  const fullPath = await getFullScapPath(mediaId);
  if (fullPath) {
    setSource(fullPath);
  }
}

function loadImage(src: string): Promise<void> {
  return new Promise((resolve) => {
    const img = new window.Image();
    img.onload = function () {
      resolve();
    };
    img.onerror = function () {
      resolve();
    };
    img.src = src;
  });
}

interface Props {
  colors?: Colors;
  orientation?: ImageOrientation;
  fit?: ImageFit;
  isTinted?: boolean;
  src: string;
  onLoad: () => void;
  mediaId?: string;
}
