import {
  App,
  FormattedAudio,
  FormattedDocument,
  FormattedImage,
  FormattedMedia,
  FormattedVideo,
  Media,
} from "../generated/router";
import {
  ContainerContent,
  MediaInstanceConfig,
} from "../stateMachines/PlaybackMachine";
import { Basic as UnsplashPhoto } from "unsplash-js/dist/methods/photos/types";
import { CSSProperties } from "react";
import { rgbaToString } from "./shape";

export function isFormattedImage(
  media: FormattedMedia
): media is FormattedImage {
  return (
    "__typename" in media &&
    (media as FormattedImage).__typename === "FormattedImage"
  );
}

export const isExternalContent = (
  content?: TileContent
): content is ExternalContent => content?.__typename === "ExternalContent";

export function isFormattedVideo(
  media: FormattedMedia
): media is FormattedVideo {
  return (
    "__typename" in media &&
    (media as FormattedVideo).__typename === "FormattedVideo"
  );
}

export function isFormattedAudio(
  media: FormattedMedia
): media is FormattedAudio {
  return (
    "__typename" in media &&
    (media as FormattedAudio).__typename === "FormattedAudio"
  );
}

export function isFormattedDocument(
  media: FormattedMedia
): media is FormattedDocument {
  return (
    "__typename" in media &&
    (media as FormattedDocument).__typename === "FormattedDocument"
  );
}

export function isDashboard(content?: any): content is DashboardInstance {
  return (
    content?.__typename === "DashboardInstance" ||
    content?.__typename === "Dashboard"
  );
}

export type Slide = {
  id: string;
  tiles: Array<Tile>;
  skip?: boolean;
};

export type Rect = {
  x: number;
  y: number;
  width: number;
  height: number;
  rotate: number;
  borderRadius?: number;
  opacity?: number;
};

export type AnimationConfig = {
  delay: number;
  entrance?: string;
  entranceDuration: number;
  motion?: string;
  motionDuration: number;
};

export const defaultAnimationConfig: AnimationConfig = {
  delay: 0,
  entrance: undefined,
  entranceDuration: 1000,
  motion: undefined,
  motionDuration: 5000,
};

export type Tile = {
  id: string;
  rect: Rect;
  content: TileContent;
  animation?: AnimationConfig;
};

export type TileContent =
  | App
  | Media
  | ShapeContent
  | FrameContent
  | ContainerContent
  | ExternalContent
  | DashboardInstance;

export type FrameItem = {
  rect: Rect; // relative to tile rect
  path: string; // clip-path string
  content?: TileContent;
  contentPosition: Rect; // position relative to item rect from 0 to 1
};

export type FrameContent = {
  __typename: "Frame";
  id: string;
  duration: number;
  fit: "contain" | "stretch";
  items: Array<FrameItem>;
  backgroundMediaId: string | undefined;
  aspectRatio: number;
};

// shapes & text
export type ShapeContent =
  | TriangleConfig
  | RectangleConfig
  | EllipseConfig
  | StarConfig
  | LineConfig
  | StaticTextConfig
  | TextConfig;

export type GenericShapeConfig = {
  __typename: "Shape";
  duration: number;
  fill?: Color;
  shadow?: {
    x: number;
    y: number;
    inset?: boolean;
    blur?: number;
    spread?: number;
    color?: Color;
  };
  border?: ShapeBorderConfig;
};

export type Color = RgbaColor;

export type Colors = Record<string, RgbaColor>;

export interface RgbColor {
  r: number;
  g: number;
  b: number;
}

export interface RgbaColor extends RgbColor {
  a: number;
}

/** from 0.0 to 1.0, default 1.0 */
export type Opacity = number;

export type ShapeBorderConfig = {
  color?: Color;
  width?: number;
};

/** triangle calculated from tile width/height */
export type TriangleConfig = GenericShapeConfig & {
  kind: "triangle";
  /** in percent of top side */
  angle?: number;
  borderRadius?: number;
};

export type RectangleConfig = GenericShapeConfig & {
  kind: "rectangle";
  borderRadius?: number;
};

export type CapConfig = { size: number } & (
  | {
      kind: "triangleArrow";
    }
  | {
      kind: "lineArrow";
    }
  | {
      kind: "circle";
    }
);

/** x, y, height from tile, width for thickness */
export type LineConfig = GenericShapeConfig & {
  kind: "line";
  width?: number;
  caps?: {
    start?: CapConfig;
    end?: CapConfig;
  };
};

export type EllipseConfig = GenericShapeConfig & {
  kind: "ellipse";
};

export type StarConfig = GenericShapeConfig & {
  kind: "star";
  count?: number;
  borderRadius?: number;
  ratio?: number;
};

export const defaultColor: RgbaColor = {
  r: 86,
  g: 93,
  b: 100,
  a: 1,
};

export const textEffectStyles: Record<
  string,
  (content: StaticTextConfig) => CSSProperties
> = {
  none: () => ({}),
  shadow: (content: StaticTextConfig) => {
    const value = content.effect as TextEffect;
    const scale = (content.fontSizeCache || 1) * 0.15;
    const color = rgbaToString({
      ...(value.color || content.fill),
      a: value.transparency / 100,
    });
    const offset = scale * (value.offset / 100);
    const angle = (value.direction * Math.PI) / 180;
    const offsetX = -Math.sin(angle) * offset;
    const offsetY = Math.cos(angle) * offset;
    const blur = scale * (value.blur / 100);
    return {
      // translateZ(0px) creates render-layer, and actually improves performance
      transform: `translateZ(0px)`,
      textShadow: `${color} ${offsetX}px ${offsetY}px ${blur}px`,
    };
  },
  lift: (content) => {
    const value = content.effect as TextEffect;
    const scale = content.fontSizeCache || 1;
    const minTransparency = 0.05;
    const maxTransparency = 0.75;
    const shadowTransparency =
      minTransparency +
      (value.intensity / 100) * (maxTransparency - minTransparency);
    const deltaX = scale * 0.05;
    const deltaY = scale * (0.05 + (value.intensity / 100) * 0.4);
    return {
      transform: "translateZ(0px)",
      textShadow: `rgba(0, 0, 0, ${shadowTransparency}) 0px ${deltaX}px ${deltaY}px`,
    };
  },
  hollow: (content) => {
    const value = content.effect as TextEffect;
    const scale = content.fontSizeCache || 1;
    const stroke = scale * (0.017 + 0.09 * (value.thickness / 100));
    const color = rgbaToString(content.fill);
    return {
      transform: "translateZ(0px)",
      fontWeight: 800,
      caretColor: color,
      WebkitTextStroke: `${stroke}px ${color}`,
      WebkitTextFillColor: "transparent",
    };
  },
  splice: (content) => {
    const value = content.effect as TextEffect;
    const scale = content.fontSizeCache || 1;
    const offset = scale * (0.166 * (value.offset / 100));
    const angle = (Math.PI / 180) * value.direction;
    const deltaX = -Math.sin(angle) * offset;
    const deltaY = Math.cos(angle) * offset;
    const color = rgbaToString(value.color || content.fill);
    const shadowColor = rgbaToString({
      ...(content.fill || defaultColor),
      a: 0.5,
    });
    const stroke = 0.1 * scale * (value.thickness / 100);
    return {
      transform: "translateZ(0px)",
      fontWeight: 800,
      textShadow: `${shadowColor} ${deltaX}px ${deltaY}px 0px`,
      caretColor: color,
      WebkitTextStroke: `${stroke}px ${color}`,
      WebkitTextFillColor: "transparent",
    };
  },
  echo: (content) => {
    const value = content.effect as TextEffect;
    const scale = content.fontSizeCache || 1;
    const offset = (value.offset / 100) * scale * 0.15;
    const angle = (Math.PI / 180) * value.direction;
    const deltaX = offset * -Math.sin(angle);
    const deltaY = offset * Math.cos(angle);
    const firstColor = rgbaToString({
      ...(value.color || content.fill || defaultColor),
      a: 0.5,
    });
    const secondColor = rgbaToString({
      ...(value.color || content.fill || defaultColor),
      a: 0.3,
    });
    return {
      transform: "translateZ(0px)",
      textShadow: `${firstColor} ${deltaX}px ${deltaY}px 0px, ${secondColor} ${
        deltaX * 2
      }px ${deltaY * 2}px 0px`,
    };
  },
  glitch: (content) => {
    const value = content.effect as TextEffect;
    const scale = content.fontSizeCache || 1;
    const offset = (value.offset / 100) * scale * 0.1;
    const angle = (Math.PI / 180) * value.direction;
    const deltaX = offset * -Math.sin(angle);
    const deltaY = offset * Math.cos(angle);
    const colorLeft = rgbaToString(value.color[0]);
    const colorRight = rgbaToString(value.color[1]);
    return {
      transform: "translateZ(0px)",
      textShadow: `${colorLeft} ${deltaX}px ${deltaY}px 0px, ${colorRight} ${-deltaX}px ${-deltaY}px 0px`,
    };
  },
  neon: (content) => {
    const value = content.effect as TextEffect;
    const scale = content.fontSizeCache || 1;
    const textColor = content.fill || defaultColor;
    const strokeColor = rgbaToString({
      r: textColor.r + (255 - textColor.r) * 0.1,
      g: textColor.g + (255 - textColor.g) * 0.1,
      b: textColor.b + (255 - textColor.b) * 0.1,
      a: 0.95,
    });
    const strokeBlur = scale * (0.017 + (0.012 * value.intensity) / 100);
    const shadowColor = rgbaToString({
      ...textColor,
      a: 0.75,
    });
    const shadowBlur = scale * (0.084 + (0.06 * value.intensity) / 100);
    const glowColor = rgbaToString({
      ...textColor,
      a: 0.44,
    });
    const glowBlur = scale * (0.25 + (0.17 * value.intensity) / 100);
    const color = rgbaToString({
      r: textColor.r + ((255 - textColor.r) * value.intensity) / 100,
      g: textColor.g + ((255 - textColor.g) * value.intensity) / 100,
      b: textColor.b + ((255 - textColor.b) * value.intensity) / 100,
      a: 1,
    });
    return {
      filter: `drop-shadow(${strokeColor} 0px 0px ${strokeBlur}px) drop-shadow(${shadowColor} 0px 0px ${shadowBlur}px) drop-shadow(${glowColor} 0px 0px ${glowBlur}px)`,
      color,
      transform: `translateZ(0px)`,
    };
  },
};

type TextEffect = {
  kind: string;
  [config: string]: any;
};

/** @deprecated */
export type TextConfig = GenericShapeConfig & {
  kind: "text";
  /** markdown */
  content?: string;
  fontFamily?: string;
  align?: "left" | "center" | "right";
  fontSizeCache?: number;
  effect?: TextEffect;
};

export type StaticTextConfig = GenericShapeConfig & {
  kind: "staticText";
  /** markdown */
  content?: string;
  fontFamily?: string;
  align?: "left" | "center" | "right";
  fontSizeCache?: number;
  effect?: TextEffect;
  lineHeight?: number;
  letterSpacing?: number;
};

export type ExternalContent = Omit<ExternalContentTemplate, "__typename"> & {
  __typename: "ExternalContent";
  id: string;
  duration: number;
  config: MediaInstanceConfig;
};

export type ExternalContentTemplate = {
  __typename: "ExternalContentTemplate";
  id: string;
  name?: string;
  original: UnsplashPhoto & { __typename: "Unsplash" };
  mediaType: Image | Video;
  attribution: ContentAttribution;
  duration?: number;
};

type Image = {
  __typename?: "Image";
  originalFormat: ImageFormat;
  thumbnailPath: string;
};

type Video = {
  __typename?: "Video";
  originalFormat: VideoFormat;
  thumbnailPath: string;
};

export type VideoFormat = {
  __typename?: "VideoFormat";
  key: string;
  sizeBytes: number;
  duration: number;
  codec: string;
  resolution: Resolution;
};

type ContentAttribution = {
  authorName: string;
  authorUrl: string;
  hostName: string;
  hostUrl: string;
};

export type ImageFormat = {
  __typename?: "ImageFormat";
  key: string;
  sizeBytes: number;
  resolution: Resolution;
};

export type Resolution = {
  __typename?: "Resolution";
  width: number;
  height: number;
};

export type DashboardInstance = {
  __typename: "DashboardInstance";
  dashboardId: string;
  duration: number;
  config: MediaInstanceConfig & {
    resolution?: { width: number; height: number };
    localIp?: string;
  };
};

export type Document = FormattedDocument & {
  duration: number;
};
