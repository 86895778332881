export function getPlayerType(): PlayerType {
  const isRaspberryPi = new URLSearchParams(window.location.search)
    .get("deviceModel")
    ?.includes("Raspberry");
  if (isRaspberryPi) return "raspberry";

  const isChromeExtension = new URLSearchParams(window.location.search).has(
    "isChromeExtension"
  );
  if (isChromeExtension) return "chrome";

  const isAndroid = window.FugoBridge;

  if (/ADAPI\/.+\(MODEL:[^)]+\)$/.test(navigator.userAgent)) {
    if (isAndroid) return "iadea-android";
    else return "iadea-web";
  }

  if (isAndroid) {
    const isFireTV = navigator.userAgent.includes("AFT");
    if (isFireTV) return "amazon";

    const isBravia = navigator.userAgent.includes("BRAVIA");
    if (isBravia) return "bravia";

    const isMecool = navigator.userAgent.includes("4K Android TV Box");
    if (isMecool) return "mecool";

    const isNvidiaShield = navigator.userAgent.includes("SHIELD Android TV");
    if (isNvidiaShield) return "nvidia-shield";

    return "android";
  }

  if (navigator.userAgent.includes("Tizen")) {
    if (window.b2bapis) {
      return "sssp";
    } else {
      return "consumer-samsung";
    }
  }

  if (navigator.userAgent.includes("Web0S")) {
    return "webos";
  }

  if (navigator.userAgent.startsWith("BrightSign")) {
    return "brightsign";
  }

  if (window.FugoElectronBridge) {
    const platform = window.FugoElectronBridge.getPlatform?.();
    if (platform === "darwin") {
      return "mac";
    }
    return "windows";
  }

  if (navigator.userAgent.includes("CrOS")) {
    return "chromeos";
  }

  return "unknown";
}

export type PlayerType =
  | "chrome"
  | "chromeos"
  | "raspberry"
  | "amazon"
  | "android"
  | "sssp"
  | "consumer-samsung"
  | "unknown"
  | "webos"
  | "bravia"
  | "iadea-web"
  | "iadea-android"
  | "brightsign"
  | "mecool"
  | "nvidia-shield"
  | "windows"
  | "mac";
