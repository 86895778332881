export enum LogLevel {
  DEBUG,
  WARN,
  ERROR,
}

let currentLogLevel = LogLevel.DEBUG;

export function setLogLevel(newLogLevel: LogLevel) {
  currentLogLevel = newLogLevel;
}

export function log(msg: any, logLevel: LogLevel = LogLevel.DEBUG) {
  if (logLevel < currentLogLevel) {
    return;
  }
  const msgFormatted = typeof msg === "object" ? JSON.stringify(msg) : msg;
  console.log(`[${new Date().toISOString()}] ${msgFormatted}`);
}
