import Peer from "peerjs";
import { createMachine, assign, sendParent } from "xstate";

const castSecretKey = `aca6b0b79d4046c99f46191af03ae61b`;

export const castingMachine = createMachine(
  {
    preserveActionOrder: true,
    id: "castingMachine",
    initial: "initializing",
    schema: {
      context: {} as CastingMachineContext,
      events: {} as CastingMachineEvents,
    },
    // going to be replaced by the passed in context
    context: {
      isEnabled: true,
      playerId: "",
      streams: [],
      connectionErrorCount: 0,
    },
    tsTypes: {} as import("./CastingMachine.typegen").Typegen0,
    on: {
      UPDATE_SETTINGS: {
        actions: "updateSettings",
        target: "initializing",
      },
    },
    states: {
      initializing: {
        id: "initializing",
        entry: ["notifyParentCastEnded", "resetStreams"],
        always: [
          {
            target: "enabled",
            cond: "isCastingEnabled",
          },
          {
            target: "disabled",
          },
        ],
      },
      enabled: {
        invoke: [
          {
            src: "castListener",
          },
        ],
        on: {
          DISABLE: { target: "disabled" },
        },
        initial: "idle",
        states: {
          idle: {
            on: {
              CAST_START: { actions: ["saveStream"], target: "casting" },
              CASTING_COMPLETE: {
                actions: ["incrementErrorCount"],
                target: "connectError",
              }, // disconnect can happen in the idle state
            },
          },
          connectError: {
            after: {
              CONNECT_AFTER_ERROR_PAUSE: { target: "#initializing" },
            },
          },
          casting: {
            entry: ["notifyParentCastStarted"],
            on: {
              // when all streams are removed or an error occurs,
              // re-initialize the machine from the start to clear out the peer
              CASTING_COMPLETE: "#initializing",
            },
            initial: "casting",
            states: {
              casting: {
                on: {
                  CAST_STOP: {
                    actions: ["removeStream"],
                    target: "shouldCompleteCasting",
                  },
                  CAST_START: {
                    actions: ["saveStream"],
                    target: "casting",
                  },
                  STREAMING_ERROR: {
                    target: "#initializing",
                  },
                },
              },
              shouldCompleteCasting: {
                always: [
                  {
                    target: "casting",
                    cond: "streamsNotEmpty",
                  },
                  {
                    target: "#initializing",
                  },
                ],
              },
            },
          },
        },
      },
      disabled: {
        on: {
          ENABLE: "enabled",
        },
      },
    },
  },
  {
    delays: {
      CONNECT_AFTER_ERROR_PAUSE: (ctx) => ctx.connectionErrorCount * 1000,
    },
    actions: {
      incrementErrorCount: assign((ctx) => ({
        connectionErrorCount: ~~ctx.connectionErrorCount + 1,
      })),
      resetStreams: assign((_ctx) => ({
        streams: [],
      })),
      updateSettings: assign((ctx, event) => ({
        isEnabled: event.settings.isCastingEnabled ?? ctx.isEnabled,
      })),
      saveStream: assign((ctx: CastingMachineContext, event: CastEvent) => ({
        streams: [...ctx.streams, event.payload.stream],
      })),
      notifyParentCastStarted: sendParent((ctx) => {
        const event: StartCasting = {
          type: "START_CASTING",
          payload: { streams: ctx.streams },
        };
        return event;
      }),
      removeStream: assign(
        (ctx: CastingMachineContext, event: CastStoppedEvent) => ({
          streams: ctx.streams.filter(
            (stream) => stream !== event.payload.stream
          ),
        })
      ),
      notifyParentCastEnded: sendParent("STOP_CASTING"),
    },
    guards: {
      isCastingEnabled: (context) => context.isEnabled,
      streamsNotEmpty: (context) => {
        console.log("streamsNotEmpty", context.streams.length);
        return context.streams.length > 0;
      },
    },
    services: {
      castListener: (context) => (sendBack) => {
        const peer = new Peer(`${castSecretKey}_${context.playerId}_player`, {
          config: {
            iceServers: [
              {
                urls: "stun:stun.relay.metered.ca:80",
              },
              {
                urls: "turn:standard.relay.metered.ca:80",
                username: "4c3c8654e0d057f1c446e4de",
                credential: "U28ifS5nS0DeE/D9",
              },
              {
                urls: "turn:standard.relay.metered.ca:80?transport=tcp",
                username: "4c3c8654e0d057f1c446e4de",
                credential: "U28ifS5nS0DeE/D9",
              },
              {
                urls: "turn:standard.relay.metered.ca:443",
                username: "4c3c8654e0d057f1c446e4de",
                credential: "U28ifS5nS0DeE/D9",
              },
              {
                urls: "turns:standard.relay.metered.ca:443?transport=tcp",
                username: "4c3c8654e0d057f1c446e4de",
                credential: "U28ifS5nS0DeE/D9",
              },
              { urls: "stun:stun.l.google.com:19302" },
              { urls: "stun:stun1.l.google.com:19302" },
              { urls: "stun:stun2.l.google.com:19302" },
              { urls: "stun:stun3.l.google.com:19302" },
              { urls: "stun:stun4.l.google.com:19302" },
              { urls: "stun:74.125.250.129:19303" },
              { urls: "stun:[2001:4860:4864:5:8000::1]:19303" },
              {
                urls: "turn:0.peerjs.com:3478",
                username: "peerjs",
                credential: "peerjsp",
              },
            ],
          },
        });
        peer.on("call", (call) => {
          call.on("stream", (remoteStream: MediaStream) => {
            console.log("Stream received", remoteStream);
            sendBack({ type: "CAST_START", payload: { stream: remoteStream } });
          });

          call.on("error", (e) => {
            console.error("Call error", e);
            sendBack("CASTING_COMPLETE");
          });

          peer.on("connection", (dataConnection) => {
            dataConnection.on("close", () => {
              console.log("Data connection closed");
              sendBack({
                type: "CAST_STOP",
                payload: { stream: call.remoteStream },
              });
            });
          });

          call.answer();
        });

        peer.on("disconnected", () => {
          console.log("Peer disconnected");
          sendBack("CASTING_COMPLETE");
        });

        peer.on("error", (e) => {
          console.error("Peer error", e);
          sendBack("CASTING_COMPLETE");
        });

        return () => {
          peer.destroy();
        };
      },
    },
  }
);

type CastingMachineEvents =
  | { type: "CASTING_COMPLETE" }
  | CastEvent
  | CastStoppedEvent
  | { type: "DISABLE" }
  | { type: "ENABLE" }
  | {
      type: "UPDATE_SETTINGS";
      settings: {
        isCastingEnabled?: boolean;
      };
    }
  | StreamingError;
type CastEvent = { type: "CAST_START"; payload: { stream: MediaStream } };
type CastStoppedEvent = {
  type: "CAST_STOP";
  payload: { stream: MediaStream };
};
export type CastingMachineContext = {
  isEnabled: boolean;
  playerId: string;
  streams: Array<MediaStream>;
  connectionErrorCount: number;
};

export type StartCasting = {
  type: "START_CASTING";
  payload: { streams: Array<MediaStream> };
};
export type StopCasting = { type: "STOP_CASTING" };
export type UpdateCasting = {
  type: "UPDATE_CAST";
  payload: { streams: Array<MediaStream> };
};
export type StreamingError = { type: "STREAMING_ERROR" };
