const CLEAN_HOUSE_GE = "f7200214-cef3-4d20-b130-f43ac37e3158";
const CLEAN_HOUSE_AM = "139260fd-5738-4b27-9268-c9f02571b3de";

export default function getDefaultMediaCacheUrl(
  tenantId?: string,
  country?: string
): string {
  if (tenantId === CLEAN_HOUSE_GE || tenantId === CLEAN_HOUSE_AM) {
    return "https://media-ge.fugo.ai/";
  }
  if (
    country?.toLowerCase().startsWith("us") ||
    country?.toLowerCase().startsWith("united states")
  ) {
    return "https://media-cache-us.fugo.ai/";
  }
  return "https://media-cache-2.fugo.ai/";
}
