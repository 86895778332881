const PSK = "qwerty";

export async function sendRequest(
  service: string,
  method: string,
  params: any
) {
  const response = await fetch("http://127.0.0.1/sony/" + service, {
    method: "POST",
    headers: {
      "X-Auth-PSK": PSK,
    },
    body: JSON.stringify({
      method: method,
      version: "1.0",
      id: 1,
      params: params ? [params] : [],
    }),
  });
  return response.json();
}
