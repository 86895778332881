import { sendRequest } from "../bravia/utils";
import { log } from "../misc/log";

export class Bravia implements BraviaAPI {
  async setPowerSavingMode(mode: PowerSavingMode) {
    await sendRequest("system", "setPowerSavingMode", { mode });
  }
  async requestReboot() {
    log("Bravia.requestReboot()");
    await sendRequest("system", "requestReboot", []);
  }
}

export class BraviaMock implements BraviaAPI {
  async setPowerSavingMode(mode: PowerSavingMode) {
    log(`Bravia.setPowerSavingMode(${mode})`);
    document.body.style.filter = `brightness(${
      mode === "pictureOff" ? "0" : "1"
    })`;
  }
  async requestReboot() {
    log(`Bravia.requestReboot()`);
  }
}

export interface BraviaAPI {
  setPowerSavingMode: (mode: PowerSavingMode) => Promise<void>;
  requestReboot: () => Promise<void>;
}

type PowerSavingMode = "off" | "pictureOff";
