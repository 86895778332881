import {
  ActorRef,
  assign,
  createMachine,
  DoneInvokeEvent,
  Machine,
  sendParent,
  spawn,
} from "xstate";
import {
  CurrentPlayback,
  Playback,
  PlaybackData,
  playbackEquals,
} from "../PlaybackData";
import moment from "moment-timezone";
import {
  DateTimeSource,
  getCurrentTime,
  getDefaultDateTimeSource,
  initialTimeData,
  TimeData,
  updateTime,
  UpdateTimeEvent,
} from "../TimeUtils";
import { del, get, keys, set, Store } from "idb-keyval";
import { mediaCacheName, mediaContentStore, mediaStore } from "../MediaStore";
import { UpdatedPulledDataEvent } from "./PullMachine";
import {
  App,
  Content,
  FormattedAudio,
  FormattedDocument,
  FormattedImage,
  FormattedMedia,
  FormattedVideo,
  Media,
  Playlist,
  PlaylistContent,
  TimeOfWeek,
  WeekSpan,
} from "../generated/router";
import {
  AnimationConfig,
  Colors,
  DashboardInstance,
  defaultAnimationConfig,
  Document,
  ExternalContent,
  FrameContent,
  FrameItem,
  isDashboard,
  isFormattedAudio,
  isFormattedDocument,
  isFormattedImage,
  isFormattedVideo,
  Rect,
  ShapeContent,
  Slide,
  TileContent,
} from "../components/MediaUtils";
import { UpdateSettingsEvent } from "./CommandsMachine";
import {
  CastingInstructionsOptions,
  VideoPreference,
  WifiInstructions,
  shouldUseHTMLScreenshot,
} from "./PlayerMachine";
import { isTizen } from "../misc/get-default-video-playback-type";
import { TIZEN_DIRECTORY } from "../tizen";
import "whatwg-fetch";
import { chromeVersion } from "../components/video-playback";
import { fetchMachine } from "./FetchMachine";
import { checkGraphqlResponse } from "../graphql/Utils";
import { Queries } from "../generated/dashboard";
import { SCAP_DOWNLOAD_DIRECTORY } from "../scap/utils";
import { getPlayerType } from "../misc/get-player-type";
import { sendMessage } from "../misc/web-transport";

// this is to prevent a bug where a dynamic content doesn't fire DONE event at all
// so we still should wait for its duration plus this timeout since video duration
// doesn't equal to its actual duration because of lags & network delays
const DYNAMIC_CONTENT_TIMEOUT = 5000;

// this is to prevent a bug where a Tile content doesn't fire LOAD event
// we use this event to wait before displaying the very first slide
const LOAD_TIMEOUT = 5000;

export interface MediaRecord {
  downloadPath: string;
  additionalPaths?: Array<string>;
}

type DownloadableMedia = MediaRecord & {
  mediaId: string;
  isVideo: boolean | null;
};

export interface PlaybackMachineContext {
  playlists: Playlist[];
  medias: FormattedMedia[];
  timeData: TimeData;
  interleaveContent: boolean;
  videoPreference: VideoPreference;
  playbackData: PlaybackData;
  screens: PlaybackScreen[];
  originalFormat: boolean;
  dateTimeSource: DateTimeSource;
  dataSuffix: string;
  retryCount: number;
  tenantId: string;
  shouldStoreMediaInIndexedDB: boolean;
  isTransitionAnimationEnabled: boolean;
  isCacheAPIForced: boolean;
  shouldDisplayCastingInstructions: boolean;
  wifiInstructions: WifiInstructions | null;
  castingInstructionsOptions: CastingInstructionsOptions | null;
  castPin: string;
  shouldHTMLScreenshotForced: boolean;
  isScreenshotEnabled: boolean;
}

type PlaybackMachineEvent =
  | UpdateTimeEvent
  | UpdatedPulledDataEvent
  | UpdateSettingsEvent
  | { type: "LOADED" }
  | { type: "DONE" };

export const playbackMachine = Machine<
  PlaybackMachineContext,
  PlaybackMachineEvent
>(
  {
    context: {
      playlists: [],
      medias: [],
      timeData: initialTimeData,
      interleaveContent: true,
      playbackData: {
        currentPlayback: CurrentPlayback.A,
        playbackA: undefined,
        playbackB: undefined,
      },
      videoPreference: "native",
      screens: [],
      originalFormat: false,
      dateTimeSource: getDefaultDateTimeSource(),
      dataSuffix: "",
      retryCount: 0,
      tenantId: "",
      shouldStoreMediaInIndexedDB: false,
      isTransitionAnimationEnabled: true,
      isCacheAPIForced: false,
      shouldDisplayCastingInstructions: false,
      wifiInstructions: null,
      castingInstructionsOptions: null,
      castPin: "",
      shouldHTMLScreenshotForced: false,
      isScreenshotEnabled: false,
    },
    initial: "init",
    on: {
      UPDATE_TIME: {
        actions: "updateTime",
      },
      UPDATE_PULLED_DATA: {
        target: "mediaSynchronization",
        actions: ["savePulledData", "resetPlaybackData", "notifyParent"],
      },
      UPDATE_SETTINGS: [
        {
          cond: "isVideoRelatedEvent",
          actions: "updateSettings",
          target: "mediaClearing",
        },
        {
          cond: "isImageRelatedEvent",
          actions: "updateSettings",
          target: "mediaClearing",
        },
        {
          cond: "isMediaCacheRelatedEvent",
          actions: "updateSettings",
          target: "mediaClearing",
        },
        {
          cond: "isTimeRelatedEvent",
          actions: "updateSettings",
          target: "init",
        },
        {
          actions: "updateSettings",
        },
      ],
    },
    states: {
      init: {
        always: [
          {
            target: "mediaSynchronization",
            cond: "playlistsNonEmpty",
            actions: "resetPlaybackData", // this is needed because this machine will be initialized with `undefined` playback state
          },
          {
            target: "emptyPlaylist",
          },
        ],
      },

      emptyPlaylist: {
        id: "emptyPlaylist",
      },

      mediaClearing: {
        invoke: {
          id: "mediaClearing",
          src: async (ctx) =>
            await clearMedia(mediaCacheName, mediaStore(ctx.dataSuffix)),
          onDone: "mediaSynchronization",
          onError: "mediaSynchronizationFailed",
        },
      },

      mediaSynchronization: {
        invoke: {
          id: "mediaSynchronization",
          src: async (context) => {
            const chromeVersion_ = chromeVersion();
            if (
              (chromeVersion_ > 0 && chromeVersion_ < 50) ||
              process.env.REACT_APP_USE_HTTP
            ) {
              return;
            }
            await synchronizeMedia(
              context.medias,
              context.videoPreference,
              mediaCacheName,
              mediaStore(context.dataSuffix),
              context.originalFormat,
              context.shouldStoreMediaInIndexedDB,
              context.isCacheAPIForced
            );
          },
          onDone: "playback",
          onError: {
            actions: ["log"],
            target: "mediaSynchronizationFailed",
          },
        },
      },

      playback: {
        activities: ["mediaIntegrity"],
        initial: "blackScreen",
        states: {
          blackScreen: {
            always: [
              {
                cond: "playlistsEmpty",
                target: "#emptyPlaylist",
              },
            ],
            entry: ["prepareNextBContent", "notifyParent"],
            on: {
              LOADED: "transitioningToB",
            },
          },
          transitioningToA: {
            id: "transitioningToA",
            entry: "notifyParent",
            after: { TRANSITION: "playingA" },
          },
          playingA: {
            entry: ["sendPlayToA", "notifyParent", "updateScreenshotA"],
            initial: "init",
            states: {
              init: {
                always: [
                  {
                    target: "infinite",
                    cond: "isSingleContent",
                  },
                  {
                    target: "regular",
                  },
                ],
              },
              infinite: {
                initial: "idle",
                states: {
                  idle: {
                    after: {
                      1000: "checkIfNewContentIsAvailable",
                    },
                  },
                  checkIfNewContentIsAvailable: {
                    always: [
                      {
                        target: "idle",
                        cond: "isSingleContent",
                      },
                      {
                        target: "transitioningToB",
                      },
                    ],
                  },
                  transitioningToB: {
                    entry: "prepareNextBContent",
                    on: {
                      LOADED: {
                        target: "#transitioningToB",
                      },
                    },
                  },
                },
              },
              regular: {
                entry: ["prepareNextBContent"],
                on: {
                  DONE: {
                    cond: "isDoneEventForA",
                    target: "#transitioningToB",
                  },
                },
              },
            },
          },
          transitioningToB: {
            id: "transitioningToB",
            entry: "notifyParent",
            after: { TRANSITION: "playingB" },
          },
          playingB: {
            entry: ["sendPlayToB", "notifyParent", "updateScreenshotB"],
            initial: "init",
            states: {
              init: {
                always: [
                  {
                    target: "infinite",
                    cond: "isSingleContent",
                  },
                  {
                    target: "regular",
                  },
                ],
              },
              infinite: {
                initial: "idle",
                states: {
                  idle: {
                    after: {
                      1000: "checkIfNewContentIsAvailable",
                    },
                  },
                  checkIfNewContentIsAvailable: {
                    always: [
                      {
                        target: "idle",
                        cond: "isSingleContent",
                      },
                      {
                        target: "transitioningToA",
                      },
                    ],
                  },
                  transitioningToA: {
                    entry: "prepareNextAContent",
                    on: {
                      LOADED: {
                        target: "#transitioningToA",
                      },
                    },
                  },
                },
              },
              regular: {
                entry: ["prepareNextAContent"],
                on: {
                  DONE: {
                    cond: "isDoneEventForB",
                    target: "#transitioningToA",
                  },
                },
              },
            },
          },
        },
      },

      mediaSynchronizationFailed: {
        entry: ["increaseRetryCount"],
        after: {
          MEDIA_SYNCHRONIZATION_RETRY_DELAY: [
            { cond: "isRetryCountExceeded", target: "playback" },
            { target: "mediaSynchronization" },
          ],
        },
      },
    },
  },
  {
    actions: {
      updateScreenshotA: (context) => {
        if (shouldUseHTMLScreenshot(context)) {
          updateScreenshot(context.screens[0]);
        }
      },
      updateScreenshotB: (context) => {
        if (shouldUseHTMLScreenshot(context)) {
          updateScreenshot(context.screens[1]);
        }
      },
      prepareNextAContent: assign((context) => {
        const { screen, playbackData } = getNextScreen(context);
        if (context.screens[0] && context.screens[0].ref.stop) {
          context.screens[0].ref.stop();
        }
        return {
          screens: [screen, context.screens[1]],
          playbackData,
        };
      }),

      prepareNextBContent: assign((context) => {
        if (context.screens[1] && context.screens[1].ref.stop) {
          context.screens[1].ref.stop();
        }
        const { screen, playbackData } = getNextScreen(context);
        return {
          screens: [context.screens[0], screen],
          playbackData,
        };
      }),

      sendPlayToB: (context) => context.screens[1].ref.send({ type: "PLAY" }),
      sendPlayToA: (context) => context.screens[0].ref.send({ type: "PLAY" }),

      // @ts-ignore
      updateTime: updateTime,

      savePulledData: assign((_context, event: any) => {
        const hasLoopedPlaylist = event.playlists.some(
          (p: Playlist) => p.manualEvent?.__typename === "PlayLoop"
        );
        // only looped playlists should stay in the playback if there's even 1 looped playlist
        const loopFilterPlaylists = hasLoopedPlaylist
          ? event.playlists.filter(({ manualEvent }: Playlist) => {
              return !!manualEvent;
            })
          : event.playlists;
        // sort so that PlayOnce playlists are played first
        const weights = {
          Standby: 0,
          PlayLoop: 1,
          PlayOnce: 2,
        } as const;
        const sortedPlaylists = loopFilterPlaylists.sort(
          (
            { manualEvent: p1Event }: Playlist,
            { manualEvent: p2Event }: Playlist
          ) => {
            if (p1Event && !p2Event) {
              return -1;
            } else if (p2Event && !p1Event) {
              return 1;
            } else if (p1Event?.__typename && p2Event?.__typename) {
              const p1Weight = weights[p1Event.__typename];
              const p2Weight = weights[p2Event.__typename];
              if (p1Weight !== p2Weight) {
                return p2Weight - p1Weight;
              } else {
                if (
                  p1Event.__typename === "PlayOnce" &&
                  p2Event.__typename === "PlayOnce"
                ) {
                  return p2Event.timestamp - p1Event.timestamp;
                }
                return 0;
              }
            }
            return 0;
          }
        );
        const filteredPlaylists = sortedPlaylists.filter(
          ({ manualEvent }: Playlist, i: number) => {
            if (i === 0) {
              return true;
            }
            if (!manualEvent) {
              return true;
            }
            // only keep 1 PlayOnce playlist in the playlists (i === 0)
            if (
              manualEvent.__typename === "PlayOnce" ||
              manualEvent.__typename === "Standby"
            ) {
              return false;
            }
            return true;
          }
        );
        return {
          playlists: filteredPlaylists,
          medias: event.medias,
        };
      }),

      updateSettings: assign({
        interleaveContent: (context, event: any) =>
          event.settings.interleaved ?? context.interleaveContent,
        videoPreference: (context, event: any) =>
          event.settings.videoPreferences ?? context.videoPreference,
        originalFormat: (context, event: any) =>
          event.settings.originalFormat ?? context.originalFormat,
        dateTimeSource: (context, event: any) =>
          event.settings.dateTimeSource ?? context.dateTimeSource,
        shouldStoreMediaInIndexedDB: (context, event: any) =>
          event.settings.shouldStoreMediaInIndexedDB ??
          context.shouldStoreMediaInIndexedDB,
        isTransitionAnimationEnabled: (context, event: any) =>
          event.settings.isTransitionAnimationEnabled ??
          context.isTransitionAnimationEnabled,
        isCacheAPIForced: (context, event: any) =>
          event.settings.isCacheAPIForced ?? context.isCacheAPIForced,
        shouldHTMLScreenshotForced: (context, event: any) =>
          event.settings.shouldHTMLScreenshotForced ??
          context.shouldHTMLScreenshotForced,
        isScreenshotEnabled: (context, event: any) =>
          event.settings.isScreenshotEnabled ?? context.isScreenshotEnabled,
      }),

      resetPlaybackData: assign((context) => {
        // killing orphan screen machines because the whole playback is reset
        context.screens?.forEach((screen) => {
          if (screen.ref?.stop) {
            console.log(`Stopping ${screen.id}`);

            // we can't use `screen.ref.stop()` because XState won't put the machine
            // into the final state and will auto redirect events to a stopped machine
            // this means all screen machines should implement FORCE_STOP event
            screen.ref.send({ type: "FORCE_STOP" });
          }
        });
        return {
          playbackData: {
            currentPlayback: CurrentPlayback.A,
            playbackA: undefined,
            playbackB: undefined,
          },
          screens: [createBlankScreen()],
        };
      }),

      log: (context, event) => {
        console.log("context", context);
        console.log("event", event);
      },

      increaseRetryCount: assign((context) => ({
        retryCount: context.retryCount + 1,
      })),

      notifyParent: sendParent((context) => ({
        type: "CONTENT_UPDATED",
        playbackData: context.playbackData,
        playlists: context.playlists,
        medias: context.medias,
      })),
    },

    delays: {
      CONTENT_AVAILABLE_DELAY: 5000,
      MEDIA_SYNCHRONIZATION_RETRY_DELAY: 10000,
      TRANSITION: (context) => getTransitionDuration(context),
    },

    guards: {
      playlistsNonEmpty: (context) => context.playlists.length !== 0,
      playlistsEmpty: (context) => context.playlists.length === 0,

      isSingleContent: (context) => isSingleContent(context),

      isVideoRelatedEvent: (_context, event: any) => {
        if (event.settings?.videoPreferences) return true;
        if (event.settings?.videoCodec) return true;
        if ("originalFormat" in event.settings) return true;
        if ("shouldStoreMediaInIndexedDB" in event.settings) return true;
        if ("isCacheAPIForced" in event.settings) return true;
        return false;
      },

      isImageRelatedEvent: (_context, event: any) => {
        return !!event.settings?.imageFormat;
      },

      isTimeRelatedEvent: (_context, event: any) => {
        if (event.settings?.dateTimeSource) return true;
        return false;
      },

      isMediaCacheRelatedEvent: (_context, event: any) => {
        return !!event.settings?.mediaCacheUrl;
      },

      // these guards are created in case an incorrect DONE event is sent in the future
      // it could be a result of a bug
      //   - in the view
      //   - orphan screen machines
      isDoneEventForA: (context, event) => isDoneEventFor(context, event, 0),
      isDoneEventForB: (context, event) => isDoneEventFor(context, event, 1),
      isRetryCountExceeded: (context) => context.retryCount >= 3,
    },

    activities: {
      mediaIntegrity: (context) => {
        const interval = setInterval(
          () =>
            mediaIntegrity(
              context.medias,
              context.videoPreference,
              mediaCacheName,
              mediaStore(context.dataSuffix),
              context.originalFormat,
              context.shouldStoreMediaInIndexedDB,
              context.isCacheAPIForced
            ),
          45000
        );
        return () => clearInterval(interval);
      },
    },
  }
);

function isDoneEventFor(
  context: PlaybackMachineContext,
  event: any,
  screenIndex: number
) {
  const { id: eventId } = event;
  if (!eventId) {
    console.log(
      "No eventID, meaning a screen machine sends incorrect DONE event"
    );
    return true;
  }
  const screenId = context.screens[screenIndex].id;
  const isDone = eventId === screenId;
  if (!isDone) {
    console.log(
      `Received done event from a wrong machine: ${eventId}. Might be a bug.`
    );
  }
  return isDone;
}

function isSingleContent(context: PlaybackMachineContext) {
  let curScreen =
    context.playbackData.currentPlayback === CurrentPlayback.A
      ? context.playbackData.playbackA
      : context.playbackData.playbackB;

  const nextPlaybackData = calculateNextContent(
    context,
    getCurrentTime(context.timeData, context.dateTimeSource)
  );

  const nextScreen =
    nextPlaybackData.currentPlayback === CurrentPlayback.A
      ? nextPlaybackData.playbackA
      : nextPlaybackData.playbackB;

  if (!curScreen && !nextScreen) return true;

  const isSameContent = playbackEquals(curScreen, nextScreen);
  return isSameContent;
}

function getNextScreen(context: PlaybackMachineContext): {
  screen: PlaybackScreen;
  playbackData: PlaybackData;
} {
  const playbackData = calculateNextContent(
    context,
    getCurrentTime(context.timeData, context.dateTimeSource)
  );
  const current =
    playbackData.currentPlayback === CurrentPlayback.A
      ? playbackData.playbackA
      : playbackData.playbackB;
  if (!current) {
    console.debug(
      "Blank screen because next content calculated to be undefined"
    );
    return { screen: createBlankScreen(), playbackData }; // ???
  }

  const isInfinite = isSingleContent({ ...context, playbackData });
  const { playlistIndex } = current;
  const content =
    context.playlists[playlistIndex].contents[
      current.playlistsOffsets[playlistIndex]
    ];
  const screen = toScreen(
    content,
    context.medias,
    isInfinite,
    context.isTransitionAnimationEnabled,
    context.tenantId
  );
  return { screen, playbackData };
}

// storage can be cleared by the browser
// so we need to re-download the files
async function mediaIntegrity(
  medias: FormattedMedia[],
  videoPreference: VideoPreference,
  cacheName: string,
  dbStore: Store,
  originalFormat: boolean,
  shouldStoreMediaInIndexedDB: boolean,
  isCacheAPIForced: boolean
) {
  console.debug("Media integrity");
  if (!isCacheAPIForced) {
    if (shouldUseTizenAPI()) return;
    if (shouldStoreMediaInIndexedDB) return; // TODO: implement this using indexeddb
    if (shouldUseScapAPI()) return;
  }

  const cache = await caches.open(cacheName);
  const cachedMediaURLs = new Set((await cache.keys()).map((key) => key.url));
  const storedMediaIds = new Set(
    (await keys(dbStore)).map((key) => key.toString())
  );

  console.debug(
    `stored: ${storedMediaIds.size}, cached: ${cachedMediaURLs.size}`
  );

  const downloadableMedias = toDownloadableMedias(medias, originalFormat);
  storedMediaIds.forEach(async (mediaId) => {
    const media = downloadableMedias.find((m) => m.mediaId === mediaId);
    if (!media) return;
    if (!cachedMediaURLs.has(media?.downloadPath)) {
      // if the video playback is native, we don't need to re-download videos
      // because they're in the native cache or if it's BrightSign we have
      // them on SD card
      if (
        !(
          media.isVideo &&
          (videoPreference === "native" || shouldStoreOnBrightSign())
        )
      ) {
        console.debug(`Downloading a missing file ${media.downloadPath}`);
        downloadMedia(
          media,
          cache,
          dbStore,
          videoPreference,
          shouldStoreMediaInIndexedDB,
          isCacheAPIForced
        );
      }
    }

    // we're storing first/last frame of the video in the web cache
    if (media.additionalPaths) {
      media.additionalPaths
        .filter((path) => !cachedMediaURLs.has(path))
        .forEach((url) => {
          console.debug(`Downloading a missing file ${url}`);
          downloadCache(cache, url);
        });
    }
  });
}

export async function synchronizeMedia(
  medias: FormattedMedia[],
  videoPreference: VideoPreference,
  cacheName: string,
  dbStore: Store,
  originalFormat: boolean,
  shouldStoreMediaInIndexedDB: boolean,
  isCacheAPIForced: boolean
) {
  const cache = await caches.open(cacheName);
  const storedMediaIds = (await keys(dbStore)).map((key) => key.toString());
  const storedMediaPaths = await Promise.all(
    storedMediaIds.map((mediaId) => get<MediaRecord>(mediaId, dbStore))
  );
  const storedMedias: DownloadableMedia[] = storedMediaIds.map(
    (mediaId, i) => ({
      mediaId,
      isVideo: null,
      ...storedMediaPaths[i],
    })
  );
  const mediasToDownload: DownloadableMedia[] = toDownloadableMedias(
    medias,
    originalFormat
  );
  await removeUnnecessaryMedias(mediasToDownload, storedMedias, cache, dbStore);
  await downloadMedias(
    mediasToDownload,
    storedMedias,
    cache,
    dbStore,
    videoPreference,
    shouldStoreMediaInIndexedDB,
    isCacheAPIForced
  );
}

function toDownloadableMedias(
  medias: FormattedMedia[],
  originalFormat: boolean
): DownloadableMedia[] {
  const mediaFiles = medias.map((media) => {
    let additionalPaths: Array<string> = [];
    if (
      isFormattedVideo(media) &&
      media.frames?.first.url &&
      media.frames?.last.url
    ) {
      additionalPaths = [media.frames.first.url, media.frames.last.url];
    }
    return {
      mediaId: media.mediaId,
      isVideo: isFormattedVideo(media),
      downloadPath: originalFormat ? media.originalUrl : media.formatUrl,
      additionalPaths,
    } as DownloadableMedia;
  });
  return mediaFiles;
}

async function removeUnnecessaryMedias(
  mediasToDownload: DownloadableMedia[],
  storedMedias: DownloadableMedia[],
  cache: Cache,
  dbStore: Store
) {
  const mediaToDownloadPaths = new Set(
    mediasToDownload.map((media) => media.downloadPath)
  );

  for (const storedMedia of storedMedias) {
    if (!mediaToDownloadPaths.has(storedMedia.downloadPath)) {
      await removeMedia(storedMedia.downloadPath, storedMedia.mediaId, cache);
      await del(storedMedia.mediaId, dbStore);
      if (storedMedia.additionalPaths) {
        const delPromises = storedMedia.additionalPaths.map((url) =>
          cache.delete(url)
        );
        Promise.all(delPromises);
      }
    }
  }
}

async function removeMedia(
  downloadPath: string,
  mediaId: string,
  cache: Cache
) {
  console.log(`Removing media: ${downloadPath}`);
  if (window.FugoBridge) {
    console.log(`window.FugoBridge.removeVideo(${downloadPath})`);
    window.FugoBridge.removeVideo(downloadPath);
  }
  if (shouldUseTizenAPI()) {
    await removeTizen(mediaId);
  }
  if (shouldUseScapAPI()) {
    await removeScap(downloadPath, mediaId);
  }
  if (shouldStoreOnBrightSign()) {
    await removeFileFromBrightSign(mediaId);
  }
  await cache.delete(downloadPath);
}

export function shouldUseTizenAPI() {
  const isTizenAvailable = !!(
    isTizen() &&
    window?.webapis?.avplay &&
    window.tizen
  );
  if (!isTizenAvailable) return false;

  const canDownload = window.tizen.systeminfo.getCapability(
    "http://tizen.org/feature/download"
  );
  return canDownload;
}

async function removeTizen(mediaId: string) {
  try {
    await removeFileTizen(mediaId);
  } catch (err) {
    console.error(err);
  }
}

function removeFileTizen(path: string): Promise<void> {
  return new Promise((resolve, reject) => {
    try {
      window.tizen.filesystem.resolve(
        `${TIZEN_DIRECTORY}/`,
        (dir) => {
          try {
            dir.listFiles(
              (files) => {
                let hasFile = false;
                files.forEach((file) => {
                  if (file.name.startsWith(path)) {
                    hasFile = true;
                    try {
                      dir.deleteFile(
                        file.fullPath,
                        () => {
                          console.log(`removed file: ${file.fullPath}`);
                          resolve();
                        },
                        (err) => reject(err)
                      );
                    } catch (err) {
                      reject(err);
                    }
                  }
                });
                if (!hasFile) {
                  reject(
                    `File not found ${path}, ${files
                      .map((file) => file.name)
                      .join("\n")}`
                  );
                  return;
                }
              },
              (err: any) => reject(err),
              null
            );
          } catch (err) {
            reject(err);
          }
        },
        (err) => reject(err),
        "w"
      );
    } catch (err) {
      reject(err);
    }
  });
}

export function shouldUseScapAPI() {
  if (
    getPlayerType() === "webos" &&
    window.Storage &&
    window.Power &&
    window.Signage
  ) {
    return true;
  }
  return false;
}

async function removeScap(downloadPath: string, mediaId: string) {
  try {
    const downloadName = downloadPath.split("/").pop();
    if (downloadName) {
      const extension = downloadName.split(".").pop();
      await removeFileScap(SCAP_DOWNLOAD_DIRECTORY + mediaId + "." + extension);
    } else {
      return;
    }
  } catch (err) {
    console.error(err);
  }
}

function removeFileScap(filePath: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const successCb = () => {
      resolve();
    };

    const failureCb = (cbObject: { errorCode: string; errorText: string }) => {
      reject(
        new Error(`Error Code [${cbObject.errorCode}]: ${cbObject.errorText}`)
      );
    };

    const options = {
      file: filePath,
      recursive: true,
    };

    const storage = new Storage();
    storage.removeFile(successCb, failureCb, options);
  });
}

async function clearMedia(cacheName: string, dbStore: Store) {
  const cache = await caches.open(cacheName);
  const storedMediaIds = (await keys(dbStore)).map((key) => key.toString());
  for (const storedMediaId of storedMediaIds) {
    const mediaRecord = await get<MediaRecord>(storedMediaId, dbStore);
    await removeMedia(mediaRecord.downloadPath, storedMediaId, cache);
    await del(storedMediaId, dbStore);
  }
}

async function downloadMedias(
  mediasToDownload: DownloadableMedia[],
  storedMedias: DownloadableMedia[],
  cache: Cache,
  dbStore: Store,
  videoPreference: VideoPreference,
  shouldStoreMediaInIndexedDB: boolean,
  isCacheAPIForced: boolean
) {
  const storedMediaPaths = new Set(
    storedMedias.map((media) => media.downloadPath)
  );
  for (const media of mediasToDownload) {
    if (!storedMediaPaths.has(media.downloadPath)) {
      await downloadMedia(
        media,
        cache,
        dbStore,
        videoPreference,
        shouldStoreMediaInIndexedDB,
        isCacheAPIForced
      );
    }
  }
}

async function downloadMedia(
  media: DownloadableMedia,
  cache: Cache,
  dbStore: Store,
  videoPreference: VideoPreference,
  shouldStoreMediaInIndexedDB: boolean,
  isCacheAPIForced: boolean
): Promise<void> {
  const { downloadPath } = media;
  console.log(`should download ${downloadPath}`);
  const mediaRecord: MediaRecord = {
    downloadPath,
    additionalPaths: media.additionalPaths,
  };
  if (isCacheAPIForced) {
    await downloadCache(cache, downloadPath);
  } else if (shouldStoreMediaInIndexedDB) {
    await downloadToIndexedDB(downloadPath, media.mediaId);
  } else if (
    media.isVideo &&
    window.FugoBridge &&
    videoPreference === "native"
  ) {
    console.log(`window.FugoBridge.storeVideo(${downloadPath})`);
    window.FugoBridge.storeVideo(downloadPath);
  } else if (shouldUseTizenAPI()) {
    await downloadTizen(downloadPath, media.mediaId);
  } else if (shouldUseScapAPI()) {
    await downloadScap(downloadPath, media.mediaId);
  } else if (shouldStoreOnBrightSign() && media.isVideo) {
    try {
      await downloadToBrightSign(downloadPath, media.mediaId);
    } catch (e) {
      console.error(e);
    }
  } else {
    await downloadCache(cache, downloadPath);
  }
  console.log("saving media record");
  await set(media.mediaId, mediaRecord, dbStore);

  if (media.additionalPaths) {
    const downloadPromises = media.additionalPaths.map((url) =>
      downloadCache(cache, url)
    );
    await Promise.all(downloadPromises);
  }
}

async function downloadCache(cache: Cache, downloadPath: string) {
  try {
    await cache.add(downloadPath);
  } catch (e: any) {
    console.error(
      `[${new Date().toISOString()}] Failed to download ${downloadPath}. Error: ${e}, ${
        e.message
      }`
    );
    const resp = await fetch(downloadPath);
    if (resp.ok) {
      // re-trying to add the file using cache.put because cache.add
      // fails on Chrome 66 for some reason
      await cache.put(downloadPath, resp);
    } else {
      throw new Error("Response status code is not ok.");
    }
  }
  console.log(`downloaded ${downloadPath}`);
}

function downloadTizen(url: string, mediaId: string): Promise<void> {
  return new Promise((resolve) => {
    const downloadRequest = new window.tizen.DownloadRequest(
      url,
      TIZEN_DIRECTORY,
      mediaId
    );
    const downloadId = window.tizen.download.start(downloadRequest);
    window.tizen.download.setListener(downloadId, {
      oncompleted: (_downloadId: number, path: string) => {
        console.log(`${url} is downloaded by Tizen to ${path}`);
        resolve();
      },

      onfailed: (id, err) => {
        console.error(`download #${id} has failed to finish: ${err}`);
        resolve();
      },

      oncanceled: (id) => {
        console.error(`download #${id} is canceled`);
        resolve();
      },
    });
  });
}

function downloadScap(url: string, mediaId: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const fileExtension = url.split(".").pop();
    const options = {
      action: "start",
      source: url,
      destination: `${SCAP_DOWNLOAD_DIRECTORY}${mediaId}.${fileExtension}`,
    };
    const successCb = (cbObject: { ticket: string }) => {
      console.log(`File Downloaded successfully. Ticket: ${cbObject.ticket}`);
      resolve(options.destination);
    };
    const failureCb = (cbObject: { errorCode: string; errorText: string }) => {
      reject(
        new Error(`Error Code [${cbObject.errorCode}]: ${cbObject.errorText}`)
      );
    };
    const storage = new Storage();
    storage.downloadFile(successCb, failureCb, options);
  });
}

export function shouldStoreOnBrightSign() {
  // eslint-disable-next-line no-unreachable
  const hasNode =
    typeof process !== "undefined" &&
    process.versions != null &&
    process.versions.node != null;
  return getPlayerType() === "brightsign" && hasNode;
}

function removeFileFromBrightSign(mediaId: string) {
  try {
    // eslint-disable-next-line no-eval
    const fs = eval(`require("fs")`);
    const filePath = `/storage/sd/${mediaId}`;
    fs.unlinkSync(filePath);
  } catch (e) {
    console.error(e);
  }
}

async function downloadToBrightSign(url: string, mediaId: string) {
  // eslint-disable-next-line no-eval
  const fs = eval(`require("fs")`);

  const writer = fs.createWriteStream(`/storage/sd/${mediaId}`, {
    defaultEncoding: "binary",
  });

  let soFar = 0;
  let contentLength = 0;

  const res = await fetch(url);
  soFar = 0;
  contentLength = ~~(res.headers.get("Content-Length") ?? 0);
  if (contentLength) {
    console.log(`Content length is ${contentLength}`);
  } else {
    console.log("Content length is not known");
  }
  if (res.body) {
    return pump(res.body.getReader());
  } else {
    throw new Error("There's no body of the media file. Abort.");
  }

  async function pump(reader: ReadableStreamDefaultReader): Promise<void> {
    const result = await reader.read();
    if (result.done) {
      console.log(`All done! ${soFar} bytes total`);
      writer.end();
      return;
    }

    const chunk = result.value;
    writer.write(new Buffer(chunk));

    soFar += chunk.byteLength;
    updateProgress(contentLength, soFar);
    return await pump(reader);
  }

  function updateProgress(contentLength: number, soFar: number) {
    if (contentLength) {
      console.log(
        ((soFar / contentLength) * 100).toFixed(2) + "% is downloaded"
      );
    } else {
      console.log(soFar + " bytes are downloaded");
    }
  }
}

function toScreen(
  content: PlaylistContent | TileContent,
  medias: FormattedMedia[],
  isInfinite: boolean,
  isTransitionAnimationEnabled: boolean,
  tenantId: string
): PlaybackScreen {
  if (!content.__typename) return createBlankScreen();

  if (
    content.__typename === "App" &&
    !content.config.param.hasDoneEventSupport
  ) {
    const id = genId();
    return {
      id,
      type: "staticApp",
      props: content,
      ref: spawn(createStaticAppMachine(content, id), { autoForward: true }),
    };
  }

  if (
    content.__typename === "App" &&
    content.config.param.hasDoneEventSupport
  ) {
    const id = genId();
    return {
      id,
      type: "dynamicApp",
      props: content,
      ref: spawn(createDynamicAppMachine(content, id), { autoForward: true }),
    };
  }

  if (content.__typename === "Media") {
    const media = medias.find((media) => media.mediaId === content.mediaId);
    if (!media) {
      console.debug("Blank screen because media is not found", content, medias);
      return createBlankScreen();
    }
    if (isFormattedImage(media)) {
      const props = {
        duration: content.duration,
        config: content.config,
        media,
      };
      const id = genId();
      return {
        id,
        type: "image",
        props,
        ref: spawn(createImageMachine(props, id), {
          autoForward: true,
        }),
      } as ImageScreen;
    } else if (isFormattedVideo(media)) {
      const id = genId();
      return {
        id,
        type: "video",
        props: { ...media, config: content.config },
        ref: spawn(createVideoMachine(media, id), {
          autoForward: true,
        }),
      };
    } else if (isFormattedAudio(media)) {
      const id = genId();
      return {
        id,
        type: "audio",
        props: media,
        ref: spawn(createAudioMachine(media, id), { autoForward: true }),
      };
    } else if (isFormattedDocument(media)) {
      const id = genId();
      return {
        id,
        type: "document",
        props: { ...media, duration: content.duration },
        ref: spawn(createDocumentMachine(media, id), { autoForward: true }),
      };
    }
  }

  if (content.__typename === "Content") {
    const id = genId();
    const slides = content.body as Slide[];
    return {
      id,
      type: "content",
      props: { ...content, isInfinite, slides },
      ref: spawn(
        createContentMachine(
          content,
          id,
          medias,
          isInfinite,
          isTransitionAnimationEnabled,
          tenantId
        ),
        {
          autoForward: true,
        }
      ),
    };
  }

  if (content.__typename === "Shape") {
    const id = genId();
    return {
      id,
      type: "shape",
      props: { ...content },
      ref: spawn(createShapeMachine(content, id), { autoForward: true }),
    };
  }

  if (content.__typename === "Frame") {
    const id = genId();
    return {
      id,
      type: "frame",
      props: { ...content },
      ref: spawn(
        createFrameMachine(
          content,
          id,
          medias,
          isInfinite,
          isTransitionAnimationEnabled,
          tenantId
        ),
        {
          autoForward: true,
        }
      ),
    };
  }

  if (content.__typename === "TileContentContainer") {
    const id = genId();
    return {
      id,
      type: "container",
      props: {
        ...content,
      },
      ref: spawn(
        createContainerMachine(
          content,
          id,
          medias,
          isInfinite,
          isTransitionAnimationEnabled,
          tenantId
        ),
        {
          autoForward: true,
        }
      ),
    };
  }

  if (content.__typename === "ExternalContent") {
    const id = genId();
    if (content.mediaType.__typename === "Image") {
      return {
        id,
        type: "externalImageContent",
        props: { ...content },
        ref: spawn(createExternalImageContentMachine(content, id), {
          autoForward: true,
        }),
      };
    } else if (content.mediaType.__typename === "Video") {
      return {
        id,
        type: "externalVideoContent",
        props: { ...content },
        ref: spawn(createExternalVideoContentMachine(content, id), {
          autoForward: true,
        }),
      };
    }
  }

  if (isDashboard(content)) {
    const id = genId();

    return {
      id,
      type: "dashboard",
      props: { ...content },
      ref: spawn(createDashboardMachine(content, id, isInfinite, tenantId), {
        autoForward: true,
      }),
    };
  }

  console.debug(
    `Creating blank screen because content is not matching anything: ${JSON.stringify(
      content
    )}`
  );
  return createBlankScreen();
}

function genId() {
  return Math.random().toString();
}

function createBlankScreen(): BlankScreen {
  const id = genId();
  return {
    id,
    type: "blank",
    ref: spawn(
      createMachine<any>(
        {
          id: "blank",
          initial: "blank",
          entry: "sendParentLoaded",
          on: {
            FORCE_STOP: { target: "done" },
          },
          states: {
            blank: {
              on: { PLAY: "playing" },
            },
            playing: {
              after: {
                DURATION: "done",
              },
            },
            done: {
              entry: "sendParentDone",
              type: "final",
            },
          },
        },
        {
          actions: {
            sendParentDone: sendParent({ type: "DONE", id, screen: "blank" }),
            sendParentLoaded: sendParent({
              type: "LOADED",
              id,
              screen: "blank",
            }),
          },
          delays: {
            DURATION: 5000,
          },
        }
      )
    ),
    props: {},
  };
}

export type DashboardMachineContext = DashboardInstance & {
  errorMessage: string;
  isInfinite: boolean;
  screenshotUrl: string;
  resolution: {
    width: number;
    height: number;
  };
};

type DashboardMachineEvents =
  | { type: "PLAY" }
  | { type: "FORCE_STOP" }
  | { type: "CERTIFICATES_LOADED"; certificates: Array<Certificate> };
function createDashboardMachine(
  props: DashboardInstance,
  id: string,
  isInfinite: boolean,
  tenantId: string
) {
  if (props.config.localIp) {
    return createOnPremDashboardMachine(props, id, isInfinite, tenantId);
  } else {
    return createCloudDashboardMachine(props, id, isInfinite);
  }
}

type Certificate = {
  certificateId: string;
  expiresAt: number;
  hash: string;
};
const INDEXEDDB_CERTS_KEY = "web-transport-certificates";
const CERTIFICATE_EXPIRATION_BUFFER = 1000 * 60 * 60 * 24; // 1 day; API generates new certificates 2 days before expiration
export const webTransportCertificatesMachine = createMachine(
  {
    schema: {
      context: {} as {},
      events: {} as any,
      services: {} as {},
    },
    id: "web-transport-certificates",
    context: {},
    initial: "loadingCertificatesFromCache",
    states: {
      loadingCertificatesFromCache: {
        invoke: {
          src: "cacheLoader",
          onDone: [
            {
              actions: ["sendParentCertificates"],
              cond: "areCertificatesActual",
            },
            { target: "downloadingNewCertificates" },
          ],
          onError: {
            target: "downloadingNewCertificates",
          },
        },
      },
      downloadingNewCertificates: {
        invoke: {
          src: "certificateDownloader",
          onDone: {
            actions: ["sendParentCertificates", "saveCertificatesToCache"],
          },
          onError: {
            actions: ["sendError"],
          },
        },
      },
    },
  },
  {
    actions: {
      sendParentCertificates: sendParent((_ctx, event) => {
        return {
          type: "CERTIFICATES_LOADED",
          certificates: event.data,
        };
      }),
      saveCertificatesToCache: (_ctx, event: any) => {
        return set(INDEXEDDB_CERTS_KEY, {
          certificates: event.data,
        });
      },
    },
    guards: {
      areCertificatesActual: (_ctx, event: any) => {
        const nowWithExpirationBuffer =
          Date.now() + CERTIFICATE_EXPIRATION_BUFFER;
        const isCertActual = event.data.some(
          (cert: any) => nowWithExpirationBuffer < cert.expiresAt
        );
        return isCertActual;
      },
    },
    services: {
      cacheLoader: async () => {
        const cache = (await get(INDEXEDDB_CERTS_KEY)) as
          | undefined
          | { certificates: Array<Certificate> };
        if (!cache) throw new Error("Certificate cache is empty");
        return cache.certificates;
      },
      certificateDownloader: async () => {
        const certificateHashesUrl =
          "https://apps-api.fugo.ai/on-premise-certificates/client";
        const resp = await fetch(certificateHashesUrl);
        const respJson = await resp.json();
        const certs = respJson.data;
        if (!Array.isArray(certs)) {
          throw new Error("Failed to get certificates");
        }
        return certs;
      },
    },
  }
);

function createOnPremDashboardMachine(
  props: DashboardInstance,
  id: string,
  isInfinite: boolean,
  tenantId: string
) {
  return createMachine(
    {
      schema: {
        context: {} as DashboardMachineContext & {
          certificates: Array<Certificate>;
          wtConnection: WebTransport | null;
          shouldBePlaying: boolean;
        },
        events: {} as DashboardMachineEvents,
        services: {} as {
          dashboard: DoneInvokeEvent<{ body?: { data?: Queries } }>;
        },
      },
      id: "dashboard-on-prem",
      context: {
        ...props,
        isInfinite,
        errorMessage: "",
        screenshotUrl: "",
        resolution: {
          width: props.config.resolution?.width || 1920,
          height: props.config.resolution?.height || 1080,
        },
        certificates: [],
        wtConnection: null,
        shouldBePlaying: false,
      },
      initial: "loadingCertificates",
      on: {
        PLAY: {
          actions: ["enableShouldPlay"],
        },
        FORCE_STOP: {
          target: "done",
        },
      },
      states: {
        loadingCertificates: {
          invoke: {
            src: "certificatesLoader",
            onError: {
              target: "errored",
            },
          },
          on: {
            CERTIFICATES_LOADED: {
              actions: ["saveCertificates"],
              target: "connectingWT",
            },
          },
        },
        connectingWT: {
          invoke: {
            src: "wtConnector",
            onDone: {
              actions: ["saveWtConnection"],
              target: "requestingURL",
            },
            onError: {
              actions: ["saveError"],
              target: "errored",
            },
          },
        },
        requestingURL: {
          invoke: {
            src: "urlRequester",
            onDone: {
              actions: ["saveScreenshotUrl"],
              target: "gettingImage",
            },
            onError: {
              actions: ["saveError"],
              target: "errored",
            },
          },
        },
        gettingImage: {
          invoke: {
            src: "imageLoader",
            onDone: {
              actions: ["saveImage"],
              target: "imageIsReady",
            },
            onError: {
              actions: ["saveError"],
              target: "errored",
            },
          },
        },
        imageIsReady: {
          entry: ["sendParentLoaded"],
          always: [
            {
              cond: "shouldBePlaying",
              target: "playing",
            },
          ],
          on: {
            PLAY: {
              target: "playing",
            },
          },
        },
        playing: {
          after: {
            DURATION: "done",
          },
        },
        done: {
          id: "done",
          entry: ["sendParentDone"],
          type: "final",
        },
        errored: {
          initial: "idle",
          entry: ["sendParentLoaded"],
          states: {
            idle: {
              always: [
                {
                  cond: "shouldBePlaying",
                  target: "displayingError",
                },
              ],
              on: {
                PLAY: { target: "displayingError" },
              },
            },
            displayingError: {
              after: {
                5000: { target: "#done" },
              },
            },
          },
        },
      },
    },
    {
      delays: {
        DURATION: props.duration || 10000,
      },
      actions: {
        sendParentLoaded: sendParent({
          type: "LOADED",
          id,
          screen: "dashboard",
        }),
        sendParentDone: sendParent({ type: "DONE", id, screen: "dashboard" }),
        saveCertificates: assign({
          certificates: (_ctx, event: any) => event.certificates,
        }),
        saveError: assign({
          errorMessage: (_ctx, event: any) => `${event.data}`,
        }),
        saveWtConnection: assign({
          wtConnection: (_ctx, event: any) => event.data,
        }),
        saveScreenshotUrl: assign({
          screenshotUrl: (_ctx, event: any) => event.data,
        }),
        saveImage: assign({
          screenshotUrl: (_ctx, event: any) => event.data,
        }),
        enableShouldPlay: assign({
          shouldBePlaying: (_ctx) => true,
        }),
      },
      guards: {
        shouldBePlaying: (ctx) => {
          return ctx.shouldBePlaying;
        },
      },
      services: {
        certificatesLoader: webTransportCertificatesMachine,
        wtConnector: async (ctx) => {
          const hashes = ctx.certificates.map(
            ({ hash }) =>
              new Uint8Array(hash.split(",").map((char) => ~~char)).buffer
          );
          const url = `https://${ctx.config.localIp}/wt`;
          const client = new window.WebTransport(url, {
            serverCertificateHashes: [
              ...hashes.map((value) => ({
                algorithm: "sha-256",
                value,
              })),
            ],
          });
          client.closed
            .then(() =>
              console.log(`The HTTP/3 connection to ${url} closed gracefully`)
            )
            .catch((error: any) =>
              console.error(
                `The HTTP/3 connection to ${url} closed due to error ${error}`
              )
            );
          const timeout = new Promise((_resolve, reject) =>
            setTimeout(
              () => reject(new Error("WebTransport connection timeout")),
              10000
            )
          );
          const ready = client.ready;
          await Promise.race([timeout, ready]);
          console.log("CLIENT CREATED");
          return client;
        },
        urlRequester: async (ctx) => {
          const stream = await ctx.wtConnection!.createBidirectionalStream();
          const readable = stream.readable;
          const message = JSON.stringify({
            query: `
              query getScreenshotUrl($dashboardId: String!, $width: Long!, $height: Long!, $tenantId: String!, $base: String!) {
                getScreenshotUrl(dashboardId: $dashboardId, width: $width, height: $height, tenantId: $tenantId, base: $base) {
                  dashboardId
                  width
                  height
                  presignedUrl
                }
              }`,
            variables: {
              dashboardId: props.dashboardId,
              tenantId,
              base: process.env.REACT_APP_DASHBOARD_BASE,
              ...ctx.resolution,
            },
          });
          sendMessage(stream, message);
          const reader = readable.getReader();
          let reply = "";
          const decoder = new TextDecoder("utf-8");
          while (true) {
            const { value, done } = await reader.read();
            if (done) {
              break;
            }
            const chunk = decoder.decode(value).toString();
            reply += chunk;
          }
          const parsedReply = JSON.parse(reply);
          const url = parsedReply.data?.getScreenshotUrl?.presignedUrl;
          if (!url) {
            throw new Error("Failed to get screenshot URL: " + reply);
          }
          return url;
        },
        imageLoader: async (ctx) => {
          const stream = await ctx.wtConnection!.createBidirectionalStream();
          const readable = stream.readable;
          sendMessage(stream, ctx.screenshotUrl);
          const reader = readable.getReader();
          let chunks = [];
          while (true) {
            const { value, done } = await reader.read();
            if (done) {
              break;
            }
            chunks.push(value);
          }
          const reply = new Blob(chunks);
          return URL.createObjectURL(reply);
        },
      },
    }
  );
}

function createCloudDashboardMachine(
  props: DashboardInstance,
  id: string,
  isInfinite: boolean
) {
  const getDashboardInvokingConfig = (context: DashboardMachineContext) => {
    return {
      url: `${process.env.REACT_APP_API_HOST}/player/dashboard`,
      requestBody: {
        query: `
          query getScreenshotUrl($dashboardId: String!, $width: Long!, $height: Long!) {
            getScreenshotUrl(dashboardId: $dashboardId, width: $width, height: $height) {
              dashboardId
              width
              height
              presignedUrl
            }
          }`,
        variables: {
          dashboardId: props.dashboardId,
          ...context.resolution,
        },
      },
    };
  };

  return createMachine(
    {
      schema: {
        context: {} as DashboardMachineContext,
        events: {} as DashboardMachineEvents,
        services: {} as {
          dashboard: DoneInvokeEvent<{ body?: { data?: Queries } }>;
        },
      },
      id: "dashboard",
      context: {
        ...props,
        isInfinite,
        errorMessage: "",
        screenshotUrl: "",
        resolution: {
          width: props.config.resolution?.width || 1920,
          height: props.config.resolution?.height || 1080,
        },
      },
      type: "parallel",
      states: {
        loading: {
          initial: "loading",
          states: {
            loading: {
              after: {
                30000: "ready",
              },
              invoke: {
                src: "dashboard",
                data: getDashboardInvokingConfig,
                onDone: [
                  {
                    cond: "hasScreenshot",
                    actions: "saveScreenshot",
                    target: "ready",
                  },
                  { actions: "saveError", target: "ready" },
                ],
                onError: { actions: "saveError", target: "ready" },
              },
            },
            ready: {
              entry: "sendParentLoaded",
            },
          },
        },
        displaying: {
          initial: "idle",
          on: {
            FORCE_STOP: { target: ".done" },
          },
          states: {
            idle: {
              on: {
                PLAY: {
                  target: "playing",
                },
              },
            },
            playing: {
              initial: "init",
              states: {
                init: {
                  always: [
                    { cond: "hasError", target: "error" },
                    { target: "ok" },
                  ],
                },
                error: {
                  initial: "idle",
                  states: {
                    idle: {
                      after: {
                        5000: [
                          { cond: "isInfinite", target: "retrying" },
                          { target: "#done" },
                        ],
                      },
                    },
                    retrying: {
                      invoke: {
                        src: "dashboard",
                        data: getDashboardInvokingConfig,
                        onDone: [
                          {
                            cond: "hasScreenshot",
                            target: "#ok",
                            actions: "saveScreenshot",
                          },
                          { actions: "saveError", target: "idle" },
                        ],
                        onError: { actions: "saveError", target: "idle" },
                      },
                    },
                  },
                },
                ok: {
                  id: "ok",
                  initial: "init",
                  states: {
                    init: {
                      always: [
                        { cond: "isInfinite", target: "infinite" },
                        { target: "finite" },
                      ],
                    },
                    finite: {
                      after: {
                        DURATION: "#done",
                      },
                    },
                    infinite: {
                      initial: "idle",
                      states: {
                        idle: {
                          after: { DURATION: "refetching" },
                        },
                        refetching: {
                          invoke: {
                            src: "dashboard",
                            data: getDashboardInvokingConfig,
                            onDone: [
                              {
                                cond: "hasScreenshot",
                                actions: "saveScreenshot",
                                target: "idle",
                              },
                              { target: "idle" },
                            ],
                            onError: "idle",
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            done: {
              id: "done",
              entry: "sendParentDone",
              type: "final",
            },
          },
        },
      },
    },
    {
      actions: {
        sendParentLoaded: sendParent({
          type: "LOADED",
          id,
          screen: "dashboard",
        }),
        sendParentDone: sendParent({ type: "DONE", id, screen: "dashboard" }),
        saveError: assign({
          errorMessage: (_context, event) => JSON.stringify(event, null, 2),
        }),
        saveScreenshot: assign({
          screenshotUrl: (_context, event: any) =>
            event.data.body?.data?.getScreenshotUrl?.presignedUrl ?? "",
        }),
      },
      guards: {
        hasScreenshot: (_context, event: any) =>
          checkGraphqlResponse<Queries>(
            event,
            (data) => !!data?.getScreenshotUrl
          ),
        hasError: (context: DashboardMachineContext) =>
          context.errorMessage.length > 0,
        isInfinite: (context: DashboardMachineContext) => context.isInfinite,
      },
      delays: {
        DURATION: props.duration || 5000,
      },
      services: {
        dashboard: fetchMachine,
      },
    }
  );
}

function createImageMachine(props: ImageProps, id: string) {
  return createMachine(
    {
      id: "image",
      initial: "loading",
      context: {},
      on: {
        // interrupt loading state
        PLAY: {
          target: "playing",
        },
        FORCE_STOP: { target: "done" },
      },
      states: {
        loading: {
          after: {
            5000: "ready",
          },
          on: {
            [`LOADED-${id}`]: {
              target: "ready",
            },
          },
        },
        ready: {
          entry: "sendParentLoaded",
        },
        playing: {
          after: {
            DURATION: "done",
          },
        },
        done: {
          entry: "sendParentDone",
          type: "final",
        },
      },
    },
    {
      actions: {
        sendParentLoaded: sendParent({ type: "LOADED", id, screen: "image" }),
        sendParentDone: sendParent({ type: "DONE", id, screen: "image" }),
      },
      delays: {
        DURATION: props.duration || 5000,
      },
    }
  );
}

function createExternalImageContentMachine(props: ExternalContent, id: string) {
  return createMachine<ExternalContentContext>(
    {
      id: "externalImageContent",
      initial: "loading",
      context: {
        props,
      },
      on: {
        // interrupt loading state
        PLAY: {
          target: "playing",
        },
        FORCE_STOP: { target: "done" },
      },
      states: {
        loading: {
          after: {
            5000: "ready",
          },
          on: {
            [`LOADED-${id}`]: {
              target: "ready",
            },
          },
        },
        ready: {
          entry: "sendParentLoaded",
        },
        playing: {
          after: {
            DURATION: "done",
          },
        },
        done: {
          entry: "sendParentDone",
          type: "final",
        },
      },
    },
    {
      actions: {
        sendParentLoaded: sendParent({
          type: "LOADED",
          id,
          screen: "externalContent",
        }),
        sendParentDone: sendParent({
          type: "DONE",
          id,
          screen: "externalContent",
        }),
      },
      delays: {
        DURATION: props.duration || 5000,
      },
    }
  );
}

function createExternalVideoContentMachine(props: ExternalContent, id: string) {
  return createMachine(
    {
      id: "externalVideoContent",
      initial: "loading",
      context: {
        props,
      },
      on: {
        PLAY: "playing",
        FORCE_STOP: { target: "done" },
      },
      after: {
        TIMEOUT: "done",
      },
      states: {
        loading: {
          after: {
            [LOAD_TIMEOUT]: "ready",
          },
          on: {
            [`LOADED-${id}`]: {
              target: "ready",
            },
          },
        },
        ready: {
          entry: "sendParentLoaded",
        },
        playing: {
          on: {
            [`DONE-${id}`]: "done",
          },
        },
        done: {
          entry: "sendParentDone",
        },
      },
    },
    {
      actions: {
        sendParentLoaded: sendParent({ type: "LOADED", id, screen: "video" }),
        sendParentDone: sendParent({ type: "DONE", id, screen: "video" }),
      },
      delays: {
        TIMEOUT: props.duration + DYNAMIC_CONTENT_TIMEOUT,
      },
    }
  );
}

function createVideoMachine(props: FormattedVideo, id: string) {
  return createMachine(
    {
      id: "video",
      initial: "loading",
      context: props,
      on: {
        PLAY: "playing",
        FORCE_STOP: { target: "done" },
      },
      states: {
        loading: {
          after: {
            5000: "ready",
          },
          on: {
            [`LOADED-${id}`]: {
              target: "ready",
            },
          },
        },
        ready: {
          entry: "sendParentLoaded",
        },
        playing: {
          // TODO: bring it back. It was commented out bc props.duration was in
          // seconds instead of ms and it was firing prematurely
          // after: {
          //   TIMEOUT: "done",
          // },
          on: {
            [`DONE-${id}`]: "done",
          },
        },
        done: {
          entry: "sendParentDone",
          type: "final",
        },
      },
    },
    {
      actions: {
        sendParentLoaded: sendParent({ type: "LOADED", id, screen: "video" }),
        sendParentDone: sendParent({ type: "DONE", id, screen: "video" }),
      },
      delays: {
        TIMEOUT: props.duration + 30000,
      },
    }
  );
}

function createStaticAppMachine(props: App, id: string) {
  // identical to the image machine
  return createMachine(
    {
      id: "app",
      initial: "loading",
      context: props,
      on: {
        PLAY: { cond: "isNotPlaying", target: "playing" },
        FORCE_STOP: { target: "done" },
      },
      states: {
        loading: {
          after: {
            5000: "ready",
          },
          on: {
            [`LOADED-${id}`]: {
              target: "ready",
            },
          },
        },
        ready: {
          entry: "sendParentLoaded",
        },
        playing: {
          after: {
            DURATION: "done",
          },
        },
        done: {
          entry: "sendParentDone",
          type: "final",
        },
      },
    },
    {
      actions: {
        sendParentLoaded: sendParent({
          type: "LOADED",
          id,
          screen: "static-app",
        }),
        sendParentDone: sendParent({ type: "DONE", id, screen: "static-app" }),
      },
      guards: {
        isNotPlaying: (_context, _event, meta) =>
          !meta.state.matches("playing"),
      },
      delays: {
        DURATION: props.config?.param?.duration || props.duration || 30000,
      },
    }
  );
}

function createDynamicAppMachine(props: App, id: string) {
  return createMachine(
    {
      id: "dynamicApp",
      initial: "loading",
      context: props,
      on: {
        PLAY: { cond: "isNotPlaying", target: "playing" },
        FORCE_STOP: { target: "done" },
      },
      states: {
        loading: {
          after: {
            5000: "ready",
          },
          on: {
            [`LOADED-${id}`]: {
              target: "ready",
            },
          },
        },
        ready: {
          entry: "sendParentLoaded",
        },
        playing: {
          // TODO: this triggers finish prematurely for some reason
          // after: {
          //   TIMEOUT: "done",
          // },
          on: {
            [`DONE-${id}`]: "done",
          },
        },
        done: {
          entry: "sendParentDone",
          type: "final",
        },
      },
    },
    {
      actions: {
        sendParentLoaded: sendParent({
          type: "LOADED",
          id,
          screen: "dynamic-app",
        }),
        sendParentDone: sendParent({ type: "DONE", id, screen: "dynamic-app" }),
      },
      delays: {
        TIMEOUT: props.duration + 30000,
      },
      guards: {
        isNotPlaying: (_context, _event, meta) =>
          !meta.state.matches("playing"),
      },
    }
  );
}

function createAudioMachine(props: FormattedAudio, id: string) {
  return createMachine(
    {
      id: "audio",
      initial: "loading",
      context: props,
      on: {
        PLAY: { cond: "isNotPlaying", target: "playing" },
        FORCE_STOP: { target: "done" },
      },
      states: {
        loading: {
          after: {
            5000: "ready",
          },
          on: {
            [`LOADED-${id}`]: {
              target: "ready",
            },
          },
        },
        ready: {
          entry: "sendParentLoaded",
        },
        playing: {
          // TODO: this triggers finish prematurely for some reason
          // after: {
          //   TIMEOUT: "done",
          // },
          on: {
            [`DONE-${id}`]: "done",
          },
        },
        done: {
          entry: "sendParentDone",
          type: "final",
        },
      },
    },
    {
      actions: {
        sendParentLoaded: sendParent({ type: "LOADED", id, screen: "audio" }),
        sendParentDone: sendParent({ type: "DONE", id, screen: "audio" }),
      },
      delays: {
        // TIMEOUT: props.codec. * 1000 + 30000,
      },
      guards: {
        isNotPlaying: (_context, _event, meta) =>
          !meta.state.matches("playing"),
      },
    }
  );
}

function createDocumentMachine(props: FormattedDocument, id: string) {
  return createMachine(
    {
      id: "document",
      initial: "loading",
      context: props,
      on: {
        PLAY: "playing",
        FORCE_STOP: { target: "done" },
      },
      states: {
        loading: {
          after: {
            5000: "ready",
          },
          on: {
            [`LOADED-${id}`]: {
              target: "ready",
            },
          },
        },
        ready: {
          entry: "sendParentLoaded",
        },
        playing: {
          on: {
            [`DONE-${id}`]: "done",
          },
        },
        done: {
          entry: "sendParentDone",
          type: "final",
        },
      },
    },
    {
      actions: {
        sendParentLoaded: sendParent({
          type: "LOADED",
          id,
          screen: "document",
        }),
        sendParentDone: sendParent({ type: "DONE", id, screen: "document" }),
      },
    }
  );
}

function getNextSlideIndex({
  currentSlideIndex,
  slides,
}: Pick<ContentScreenContext, "currentSlideIndex" | "slides">): number {
  const nextSlideIndex = currentSlideIndex + 1;
  const slide = slides[nextSlideIndex];
  if (!slide) {
    return -1;
  }
  if (slide.skip) {
    return getNextSlideIndex({
      currentSlideIndex: nextSlideIndex,
      slides,
    });
  }
  return nextSlideIndex;
}

function getNextSlideIndexLoop(
  {
    currentSlideIndex,
    slides,
  }: Pick<ContentScreenContext, "currentSlideIndex" | "slides">,
  iteration = 0
): number {
  if (iteration > slides.length) {
    console.error("Infinite loop detected");
    return 0;
  }
  const nextSlideIndex = getNextSlideIndex({ currentSlideIndex, slides });
  if (nextSlideIndex === -1) {
    return getNextSlideIndexLoop(
      {
        currentSlideIndex: nextSlideIndex,
        slides,
      },
      iteration + 1
    );
  }
  return nextSlideIndex;
}

function createContentMachine(
  props: Content,
  id: string,
  medias: FormattedMedia[],
  isInfinite: boolean,
  isTransitionAnimationEnabled: boolean,
  tenantId: string
) {
  const slides = props.body as Slide[];
  return createMachine<ContentScreenContext>(
    {
      id: "content",
      initial: "init",
      context: {
        done: {},
        loaded: {},
        screenA: [],
        screenB: [],
        slides,
        isInfinite,
        isTransitionAnimationEnabled,
        currentSlideIndex: -1,
        isFirstRender: true,
        returnBackToSlideIndex: [],
      },
      on: {
        PLAY: { cond: "isNotPlaying", target: "playing" },
        FORCE_STOP: { target: "done" },
      },
      states: {
        init: {
          always: {
            actions: "prepareA",
            target: "loading",
          },
        },
        loading: {
          after: {
            10000: "ready",
          },
          on: {
            "*": {
              actions: "handleLoaded",
              target: "maybeLoaded",
            },
          },
        },
        maybeLoaded: {
          always: [
            {
              cond: "isLoaded",
              target: "ready",
            },
            {
              target: "loading",
            },
          ],
        },
        ready: {
          entry: "sendParentLoaded",
        },
        playing: {
          entry: ["setFirstRender", "incCurrentSlideIndex"],
          initial: "playingA",
          states: {
            playingA: {
              entry: ["sendPlayA", "resetDone"],
              on: {
                USER_INTERACTION: {
                  actions: "handleUserInteraction",
                  target: "playingB",
                },
              },
              initial: "init",
              states: {
                init: {
                  always: [
                    {
                      target: "single",
                      cond: "isSingle",
                    },
                    {
                      target: "carousel",
                    },
                  ],
                },
                single: {
                  initial: "playing",
                  states: {
                    init: {
                      always: [
                        {
                          cond: "isInfinite",
                          target: "infinite",
                        },
                        {
                          target: "playing",
                        },
                      ],
                    },
                    infinite: {
                      type: "final",
                    },
                    playing: {
                      on: {
                        "*": {
                          actions: "handleDone",
                          target: "maybeDone",
                        },
                      },
                    },
                    maybeDone: {
                      always: [
                        { cond: "isContentDone", target: "#done" },
                        {
                          target: "playing",
                        },
                      ],
                    },
                  },
                },
                carousel: {
                  entry: "prepareB",
                  initial: "playing",
                  states: {
                    playing: {
                      on: {
                        "*": {
                          actions: "handleDone",
                          cond: "isDoneEvent",
                          target: "maybeDone",
                        },
                      },
                    },
                    maybeDone: {
                      always: [
                        { cond: "isContentDone", target: "#done" },
                        { cond: "isCurrentScreenDone", target: "#transitionB" },
                        { target: "playing" },
                      ],
                    },
                  },
                },
              },
            },
            transitionB: {
              id: "transitionB",
              after: {
                TRANSITION: {
                  target: "playingB",
                  actions: ["incCurrentSlideIndex"],
                },
              },
            },
            playingB: {
              entry: ["sendPlayB", "resetDone", "prepareA"],
              on: {
                USER_INTERACTION: {
                  actions: "handleUserInteraction",
                  target: "playingA",
                },
              },
              initial: "carousel",
              states: {
                carousel: {
                  initial: "playing",
                  states: {
                    playing: {
                      on: {
                        "*": {
                          actions: "handleDone",
                          cond: "isDoneEvent",
                          target: "maybeDone",
                        },
                      },
                    },
                    maybeDone: {
                      always: [
                        { cond: "isContentDone", target: "#done" },
                        { cond: "isCurrentScreenDone", target: "#transitionA" },
                        { target: "playing" },
                      ],
                    },
                  },
                },
              },
            },
            transitionA: {
              id: "transitionA",
              after: {
                TRANSITION: {
                  target: "playingA",
                  actions: ["incCurrentSlideIndex"],
                },
              },
            },
          },
        },
        done: {
          id: "done",
          entry: ["sendParentDone"],
          type: "final",
        },
      },
    },
    {
      actions: {
        handleUserInteraction: assign((context, event, { state }) => {
          if (
            event.type === "USER_INTERACTION" &&
            event.action.kind === "goToSlide"
          ) {
            const { slideId, returnBack } = event.action;
            const slideIndex = context.slides.findIndex(
              (slide) => slide.id === slideId
            );
            if (slideIndex === -1) {
              console.warn("Could not find slide", slideId);
              return {};
            }
            const nextScreenKey = state?.matches("playing.playingA")
              ? "screenB"
              : "screenA";
            return {
              currentSlideIndex: slideIndex,
              returnBackToSlideIndex: returnBack
                ? [context.currentSlideIndex, ...context.returnBackToSlideIndex]
                : context.returnBackToSlideIndex,
              [nextScreenKey]: getContentForSlide(
                context,
                medias,
                slideIndex,
                false,
                isTransitionAnimationEnabled,
                tenantId
              ),
            };
          }
          return {};
        }),
        incCurrentSlideIndex: assign((context) => {
          const { returnBackToSlideIndex } = context;
          if (returnBackToSlideIndex.length > 0) {
            const [nextSlideIndex, ...rest] = returnBackToSlideIndex;
            return {
              currentSlideIndex: nextSlideIndex,
              returnBackToSlideIndex: rest,
            };
          }
          return {
            currentSlideIndex: getNextSlideIndexLoop(context),
          };
        }),
        setFirstRender: assign((_context) => ({ isFirstRender: false })),
        resetDone: assign((_context) => ({ done: {} })),
        handleDone: assign((context, event) => {
          if (event.type === "DONE") {
            return {
              done: { ...context.done, [`DONE-${event.id}`]: true },
            };
          }

          if (event.type.startsWith("DONE-")) {
            return {
              done: { ...context.done, [event.type]: true },
            };
          }

          return {};
        }),
        handleLoaded: assign((context, event) => {
          if (event.type.startsWith("LOADED-")) {
            return {
              loaded: { ...context.loaded, [event.type]: true },
            };
          }

          if (event.type === "LOADED") {
            return {
              loaded: { ...context.loaded, [`LOADED-${event.id}`]: true },
            };
          }

          return {};
        }),
        sendParentLoaded: sendParent({ type: "LOADED", id, screen: "content" }),
        sendParentDone: sendParent({ type: "DONE", id, screen: "content" }),
        prepareA: assign((context) => {
          const isInfinite = context.isInfinite && context.slides.length === 1;
          const screenA = getNextContent(
            context,
            medias,
            isInfinite,
            isTransitionAnimationEnabled,
            tenantId
          );
          context.screenA.forEach((tile) => {
            if (tile.screen.ref.stop) {
              tile.screen.ref.stop();
            }
          });
          return {
            screenA,
          };
        }),
        prepareB: assign((context) => {
          const screenB = getNextContent(
            context,
            medias,
            false,
            isTransitionAnimationEnabled,
            tenantId
          );
          context.screenB.forEach((tile) => {
            if (tile.screen.ref.stop) {
              tile.screen.ref.stop();
            }
          });
          return {
            screenB,
          };
        }),
        sendPlayB: (context) => {
          context.screenB.forEach((tile) =>
            tile.screen.ref.send({ type: "PLAY" })
          );
        },
        sendPlayA: (context) => {
          context.screenA.forEach((tile) =>
            tile.screen.ref.send({ type: "PLAY" })
          );
        },
      },
      guards: {
        isSingle: (context) => context.slides.length <= 1, // && context.isInfinite,
        isLoaded: (context) => {
          const apps = context.screenA.map((app) => app.screen.id);
          const loadingApps = apps.filter(
            (appId) => !context.loaded[`LOADED-${appId}`]
          );
          return loadingApps.length === 0;
        },
        isCurrentScreenDone,
        isDoneEvent: (context, event) => event.type.startsWith("DONE"),
        isContentDone: (context, event: any, meta: any) => {
          if (context.isInfinite) {
            return false;
          }

          const isDone = isCurrentScreenDone(context, event, meta);
          if (!isDone) {
            return false;
          }

          if (context.returnBackToSlideIndex.length > 0) {
            return false;
          }

          const nextSlideIndex = getNextSlideIndex(context);
          if (nextSlideIndex !== -1) {
            return false;
          }

          return true;
        },
        isInfinite: (context) => context.isInfinite,
        isNotPlaying: (_context, _event, meta) =>
          !meta.state.matches("playing"),
      },
      delays: {
        TRANSITION: (context) => getTransitionDuration(context),
      },
    }
  );
}

function createContainerMachine(
  props: ContainerContent,
  id: string,
  medias: FormattedMedia[],
  isInfinite: boolean,
  isTransitionAnimationEnabled: boolean,
  tenantId: string
) {
  return createMachine<ContainerContext>(
    {
      id: "container",
      initial: "loading",
      context: {
        items: props.items,
        screenA: null,
        screenB: null,
        isInfinite,
        isTransitionAnimationEnabled,
        currentSlideIndex: -1,
        isFirstRender: true,
      },
      on: {
        PLAY: { cond: "isNotPlaying", target: "playing" },
        FORCE_STOP: { target: "done" },
      },
      states: {
        loading: {
          entry: "prepareA",
          after: {
            5000: "ready",
          },
          on: {
            "*": "ready",
          },
        },
        ready: {
          entry: "sendParentLoaded",
        },
        playing: {
          entry: "setFirstRender",
          initial: "playingA",
          states: {
            playingA: {
              entry: ["incCurrentSlideIndex", "sendPlayA"],
              initial: "init",
              states: {
                init: {
                  always: [
                    {
                      cond: "isSingle",
                      target: "single",
                    },
                    {
                      target: "carousel",
                    },
                  ],
                },
                single: {
                  initial: "playing",
                  states: {
                    init: {
                      always: [
                        {
                          cond: "isInfinite",
                          target: "infinite",
                        },
                        {
                          target: "playing",
                        },
                      ],
                    },
                    infinite: {
                      type: "final",
                    },
                    playing: {
                      on: {
                        DONE: [
                          { cond: "isContainerDone", target: "#done" },
                          { target: "playing" },
                        ],
                      },
                    },
                  },
                },
                carousel: {
                  after: { 500: { actions: "prepareB" } },
                  on: {
                    DONE: [
                      { cond: "isContainerDone", target: "#done" },
                      { cond: "isDone", target: "#transitionB" },
                    ],
                  },
                },
              },
            },
            transitionB: {
              id: "transitionB",
              after: { TRANSITION: "playingB" },
            },
            playingB: {
              entry: ["incCurrentSlideIndex", "sendPlayB"],
              after: { 500: { actions: "prepareA" } },
              initial: "carousel",
              states: {
                carousel: {
                  on: {
                    DONE: [
                      { cond: "isContainerDone", target: "#done" },
                      { cond: "isDone", target: "#transitionA" },
                    ],
                  },
                },
              },
            },
            transitionA: {
              id: "transitionA",
              after: { TRANSITION: "playingA" },
            },
          },
        },
        done: {
          id: "done",
          entry: "sendParentDone",
          type: "final",
        },
      },
    },
    {
      actions: {
        incCurrentSlideIndex: assign((context) => {
          return {
            currentSlideIndex:
              (context.currentSlideIndex + 1) % context.items.length,
          };
        }),
        setFirstRender: assign((_context) => ({ isFirstRender: false })),
        sendParentLoaded: sendParent({ type: "LOADED", id, screen: "content" }),
        sendParentDone: sendParent({ type: "DONE", id, screen: "content" }),
        prepareA: assign((context) => {
          const newIndex =
            (context.currentSlideIndex + 1) % context.items.length;
          const item = context.items[newIndex];
          const isInfinite = context.items.length === 1 && context.isInfinite;
          const itemMachine = toScreen(
            item,
            medias,
            isInfinite,
            isTransitionAnimationEnabled,
            tenantId
          );
          return {
            screenA: itemMachine,
          };
        }),
        prepareB: assign((context) => {
          const newIndex =
            (context.currentSlideIndex + 1) % context.items.length;
          const item = context.items[newIndex];
          const itemMachine = toScreen(
            item,
            medias,
            false,
            isTransitionAnimationEnabled,
            tenantId
          );
          return {
            screenB: itemMachine,
          };
        }),
        sendPlayB: (context) => {
          context.screenB?.ref.send({ type: "PLAY" });
        },
        sendPlayA: (context) => {
          context.screenA?.ref.send({ type: "PLAY" });
        },
      },
      guards: {
        isSingle: (context) => context.items.length <= 1,
        isDone: (context, event, meta) => {
          if (!isContainerEvent(context, event, meta)) return false;
          return true;
        },
        isContainerDone: (context, event, meta) => {
          if (context.isInfinite) return false;
          if (!isContainerEvent(context, event, meta)) return false;
          if (context.currentSlideIndex < context.items.length - 1) {
            return false;
          }
          return true;
        },
        isInfinite: (context) => context.isInfinite,
        isNotPlaying: (_context, _event, meta) =>
          !meta.state.matches("playing"),
      },
      delays: {
        TRANSITION: (context) => getTransitionDuration(context),
      },
    }
  );
}

function createShapeMachine(props: ShapeContent, id: string) {
  return createMachine<ShapeContent>(
    {
      id: "shape",
      initial: "ready",
      context: props,
      on: {
        PLAY: {
          target: "playing",
        },
        FORCE_STOP: { target: "done" },
      },
      states: {
        ready: {
          entry: "sendParentLoaded",
        },
        playing: {
          after: {
            DURATION: "done",
          },
        },
        done: {
          entry: "sendParentDone",
          type: "final",
        },
      },
    },
    {
      actions: {
        sendParentLoaded: sendParent({ type: "LOADED", id, screen: "shape" }),
        sendParentDone: sendParent({ type: "DONE", id, screen: "shape" }),
      },
      delays: {
        DURATION: props.duration || 5000,
      },
    }
  );
}

function createFrameMachine(
  props: FrameContent,
  id: string,
  medias: FormattedMedia[],
  isInfinite: boolean,
  isTransitionAnimationEnabled: boolean,
  tenantId: string
) {
  const machineId = "frame";
  return createMachine<FramesScreenContext>(
    {
      id: machineId,
      initial: "ready",
      context: {
        items: props.items
          .filter((frameItem) => frameItem.content)
          .map((frameItem) => ({
            item: frameItem,
            screen: toScreen(
              frameItem.content as TileContent,
              medias,
              isInfinite,
              isTransitionAnimationEnabled,
              tenantId
            ),
          })),
        done: {},
        isInfinite,
      },
      on: {
        PLAY: {
          target: "playing",
        },
        FORCE_STOP: { target: "done" },
      },
      states: {
        ready: {
          entry: "sendParentLoaded",
        },
        playing: {
          initial: "playing",
          states: {
            playing: {
              on: {
                "*": {
                  actions: "handleDone",
                  target: "maybeDone",
                },
              },
            },
            maybeDone: {
              always: [
                { cond: "isDone", target: "#done" },
                { target: "playing" },
              ],
            },
          },
        },
        done: {
          id: "done",
          entry: "sendParentDone",
          type: "final",
        },
      },
    },
    {
      actions: {
        sendParentLoaded: sendParent({ type: "LOADED", id, screen: machineId }),
        sendParentDone: sendParent({ type: "DONE", id, screen: machineId }),
        handleDone: assign((context, event) => {
          if (event.type === "DONE") {
            return {
              done: { ...context.done, [`DONE-${event.id}`]: true },
            };
          }

          if (event.type.startsWith("DONE-")) {
            return {
              done: { ...context.done, [event.type]: true },
            };
          }

          return {};
        }),
      },
      guards: {
        isDone: (context) => {
          if (context.isInfinite) return false;

          const numberOfItems = context.items.length;
          const numberOfDoneItems = Object.keys(context.done).length;
          if (numberOfItems > numberOfDoneItems) return false;

          return true;
        },
      },
      delays: {
        DURATION: props.duration || 5000, // not used atm but todo
      },
    }
  );
}

function isCurrentScreenDone(
  context: ContentScreenContext,
  _event: any,
  { state }: any
) {
  const currentScreen = state.matches("playing.playingA")
    ? context.screenA
    : context.screenB;
  const apps = currentScreen.map((app) => app.screen.id);
  const playingApps = apps.filter((appId) => !context.done[`DONE-${appId}`]);
  return playingApps.length === 0;
}

function isContainerEvent(
  context: ContainerContext,
  event: any,
  { state }: any
) {
  const currentScreen = state.matches("playing.playingA")
    ? context.screenA
    : context.screenB;
  return currentScreen?.id === event.id;
}

function getContentForSlide(
  context: ContentScreenContext,
  medias: FormattedMedia[],
  index: number,
  isInfinite: boolean,
  isTransitionAnimationEnabled: boolean,
  tenantId: string
): Tile[] {
  const slide = context.slides[index];
  return slide.tiles
    .map((tile) => ({
      ...tile,
      content: enrichWithTypename(tile.content),
    }))
    .map((tile) => ({
      ...tile,
      screen: toScreen(
        tile.content as TileContent,
        medias,
        isInfinite,
        isTransitionAnimationEnabled,
        tenantId
      ),
      animation: tile.animation || defaultAnimationConfig,
    }));
}

function getNextContent(
  context: ContentScreenContext,
  medias: FormattedMedia[],
  isInfinite: boolean,
  isTransitionAnimationEnabled: boolean,
  tenantId: string
): Tile[] {
  if (areNoMoreSlides(context)) {
    return [];
  }
  const newIndex =
    context.returnBackToSlideIndex[0] ?? getNextSlideIndexLoop(context);
  return getContentForSlide(
    context,
    medias,
    newIndex,
    isInfinite,
    isTransitionAnimationEnabled,
    tenantId
  );
}

function areNoMoreSlides(context: ContentScreenContext) {
  if (context.currentSlideIndex >= context.slides.length) {
    return !context.isInfinite;
  }

  return false;
}

function enrichWithTypename(content: TileContent) {
  if ("mediaId" in content) {
    return { ...content, __typename: "Media" };
  }
  if ("appId" in content) {
    return { ...content, __typename: "App" };
  }
  return content;
}

export function calculateNextContent(
  { playlists, playbackData, interleaveContent }: PlaybackMachineContext,
  currentTime: moment.Moment
): PlaybackData {
  const calculate = (playback: Playback) => {
    const currentPlayback = calculateNextPlayback(
      playback,
      interleaveContent,
      playlists,
      currentTime
    );

    let nextPlayback = undefined;
    if (currentPlayback) {
      const { playlistIndex, playlistsOffsets } = currentPlayback;
      const content =
        playlists[playlistIndex].contents[playlistsOffsets[playlistIndex]];
      nextPlayback = calculateNextPlayback(
        currentPlayback,
        interleaveContent,
        playlists,
        currentTime.add(content.duration, "milliseconds")
      );
    }
    return { currentPlayback, nextPlayback };
  };

  if (
    playbackData.playbackA &&
    playbackData.currentPlayback === CurrentPlayback.A
  ) {
    const { currentPlayback, nextPlayback } = calculate(playbackData.playbackA);

    return {
      currentPlayback: CurrentPlayback.B,
      playbackA: nextPlayback,
      playbackB: currentPlayback,
    };
  } else if (
    playbackData.playbackB &&
    playbackData.currentPlayback === CurrentPlayback.B
  ) {
    const { currentPlayback, nextPlayback } = calculate(playbackData.playbackB);

    return {
      currentPlayback: CurrentPlayback.A,
      playbackA: currentPlayback,
      playbackB: nextPlayback,
    };
  } else {
    const { currentPlayback, nextPlayback } = calculate(
      initializePlayback(playlists)
    );

    return {
      currentPlayback: CurrentPlayback.A,
      playbackA: currentPlayback,
      playbackB: nextPlayback,
    };
  }
}

export function calculateNextPlayback(
  playback: Playback,
  interleaveContent: boolean,
  playlists: Playlist[],
  currentTime: moment.Moment
): Playback | undefined {
  if (!playlists.length) {
    return undefined;
  }

  const { playlistIndex, playlistsOffsets } = playback;

  const currentPlaylist = playlists[playlistIndex];

  if (interleaveContent) {
    return playNextAvailablePlaylist(
      currentTime,
      playlists,
      playlistIndex,
      playlistsOffsets
    );
  } else {
    const isEndOfPlaylist =
      playlistsOffsets[playlistIndex] === currentPlaylist.contents.length - 1;

    if (!isEndOfPlaylist && isPlaylistAvailable(currentTime, currentPlaylist)) {
      const newPlaylistsOffsets = [...playlistsOffsets];
      newPlaylistsOffsets[playlistIndex] = playlistsOffsets[playlistIndex] + 1;

      return {
        playlistIndex: playlistIndex,
        playlistsOffsets: newPlaylistsOffsets,
      };
    } else {
      return playNextAvailablePlaylist(
        currentTime,
        playlists,
        playlistIndex,
        playlistsOffsets
      );
    }
  }
}

export function playNextAvailablePlaylist(
  currentTime: moment.Moment,
  playlists: Playlist[],
  currentPlaylistIndex: number,
  playlistsOffsets: number[]
): Playback | undefined {
  const nextAvailablePlaylistIndex = findNextAvailablePlaylist(
    currentTime,
    playlists,
    currentPlaylistIndex
  );

  if (nextAvailablePlaylistIndex === undefined) return undefined;

  const newPlaylistsOffsets = [...playlistsOffsets];
  newPlaylistsOffsets[nextAvailablePlaylistIndex] =
    (playlistsOffsets[nextAvailablePlaylistIndex] + 1) %
    playlists[nextAvailablePlaylistIndex].contents.length;

  return {
    playlistIndex: nextAvailablePlaylistIndex,
    playlistsOffsets: newPlaylistsOffsets,
  };
}

export function findNextAvailablePlaylist(
  currentTime: moment.Moment,
  playlists: Playlist[],
  currentPlaylistIndex: number
): number | undefined {
  if (playlists.length === 0) return undefined;

  let playlistIndex = (currentPlaylistIndex + 1) % playlists.length;

  while (
    !isPlaylistAvailable(currentTime, playlists[playlistIndex]) &&
    playlistIndex !== currentPlaylistIndex
  ) {
    playlistIndex = (playlistIndex + 1) % playlists.length;
  }

  if (isPlaylistAvailable(currentTime, playlists[playlistIndex])) {
    return playlistIndex;
  } else {
    return undefined;
  }
}

export function isPlaylistAvailable(
  currentTime: moment.Moment,
  playlist: Playlist
): boolean {
  if (playlist.manualEvent) {
    if (playlist.manualEvent.__typename === "PlayLoop") {
      return true;
    } else if (playlist.manualEvent.__typename === "PlayOnce") {
      const isBefore = currentTime.isBefore(
        playlist.manualEvent.timestamp + playlist.duration
      );
      return isBefore;
    } else {
      return false;
    }
  }
  return (
    playlist.timerEvents?.length === 0 &&
    playlist.contents &&
    playlist.contents.length !== 0 &&
    currentTime.isAfter(
      moment.tz(playlist.startDate, currentTime.tz() ?? moment.tz.guess())
    ) &&
    (!playlist.endDate ||
      currentTime.isBefore(
        moment.tz(playlist.endDate, currentTime.tz() ?? moment.tz.guess())
      )) &&
    playlist.weekSchedule
      .map((weekSpan: WeekSpan) => isWeekSpanAvailable(currentTime, weekSpan))
      .includes(true)
  );
}

export function isWeekSpanAvailable(
  currentTime: moment.Moment,
  weekSpan: WeekSpan
): boolean {
  const isEntireWeekSelected =
    weekSpan.start.dayOfWeek === weekSpan.end.dayOfWeek &&
    weekSpan.start.time === weekSpan.end.time;
  if (isEntireWeekSelected) {
    return true;
  }

  const [startHour, startMinute] = weekSpan.start.time.split(":");
  const startTime = currentTime
    .clone()
    .isoWeekday(weekSpan.start.dayOfWeek)
    .hour(parseInt(startHour))
    .minute(parseInt(startMinute))
    .second(0);

  const { endHour, endMinute, endSeconds, dayOfWeek } = parseEndTime(
    weekSpan.end
  );
  const endTime = currentTime
    .clone()
    .isoWeekday(dayOfWeek)
    .hour(endHour)
    .minute(endMinute)
    .second(endSeconds);

  return startTime.isBefore(currentTime) && endTime.isAfter(currentTime);
}

function parseEndTime(end: TimeOfWeek) {
  const [endHour, endMinute] = end.time.split(":").map(Number);
  if (end.dayOfWeek === "MONDAY" && endHour === 0 && endMinute === 0) {
    // when the whole day is selected the CMS sends us the next day at 00:00
    // so for the whole day, the end date will be MONDAY 00:00
    // but if we set currentTime to Monday, the resulting time will be 1 week back
    return { dayOfWeek: "SUNDAY", endHour: 23, endMinute: 59, endSeconds: 59 };
  } else {
    return { dayOfWeek: end.dayOfWeek, endHour, endMinute, endSeconds: 0 };
  }
}

export function initializePlayback(playlists: Playlist[]): Playback {
  return {
    playlistIndex: 0,
    playlistsOffsets: playlists.map(() => -1),
  };
}

export function getTransitionDuration({
  isTransitionAnimationEnabled,
}: Pick<PlaybackMachineContext, "isTransitionAnimationEnabled">) {
  if (isTransitionAnimationEnabled) {
    return ~~(process.env.REACT_APP_TRANSITION_DURATION ?? 1000) || 1000;
  } else {
    return 0;
  }
}

export interface BlankScreen extends Screen {
  id: string;
  type: "blank";
  ref: ActorRef<any>;
  props: any;
}

export interface ImageScreen extends Screen {
  id: string;
  type: "image";
  props: ImageProps;
  ref: ActorRef<any>;
}

export const mediaFitOptions = [
  "contain",
  "cover",
  "originalSize",
  "tile",
  "kenBurnsEffect",
  "crop",
] as const;

export type ImageFit = typeof mediaFitOptions[number];

export type ImageOrientation = 0 | 90 | 180 | 270;

export interface ImageProps {
  duration: number;
  config?: MediaInstanceConfig;
  media: FormattedImage;
}

export interface MediaInstanceConfig {
  fit: ImageFit;
  orientation: ImageOrientation;
  isTinted: boolean;
  colors?: Colors;
  isMuted?: boolean;
}

export interface VideoScreen extends Screen {
  id: string;
  type: "video";
  props: FormattedVideo & { config?: MediaInstanceConfig };
  ref: ActorRef<any>;
}

export interface StaticAppScreen extends Screen {
  id: string;
  type: "staticApp";
  props: App;
  ref: ActorRef<any>;
}

export interface DynamicAppScreen extends Screen {
  id: string;
  type: "dynamicApp";
  props: App;
  ref: ActorRef<any>;
}

export interface AudioScreen extends Screen {
  id: string;
  type: "audio";
  props: FormattedAudio;
  ref: ActorRef<any>;
}

export interface DocumentScreen extends Screen {
  id: string;
  type: "document";
  props: Document;
  ref: ActorRef<any>;
}

export type ContentScreenProps = Content & {
  isInfinite: boolean;
  slides: Slide[];
};

export interface ContentScreen extends Screen {
  id: string;
  type: "content";
  props: ContentScreenProps;
  ref: ActorRef<any>;
}

export interface ShapeScreen extends Screen {
  id: string;
  type: "shape";
  props: ShapeContent;
  ref: ActorRef<any>;
}

export interface FrameScreen extends Screen {
  id: string;
  type: "frame";
  props: FrameContent;
  ref: ActorRef<any>;
}

export interface ContainerScreen extends Screen {
  id: string;
  type: "container";
  props: ContainerContent;
  ref: ActorRef<any>;
}

export interface ExternalImageContentScreen extends Screen {
  id: string;
  type: "externalImageContent";
  props: ExternalContent;
  ref: ActorRef<any>;
}

export interface ExternalVideoContentScreen extends Screen {
  id: string;
  type: "externalVideoContent";
  props: ExternalContent;
  ref: ActorRef<any>;
}

export interface DashboardScreen extends Screen {
  id: string;
  type: "dashboard";
  props: DashboardInstance;
  ref: ActorRef<any>;
}

export interface ContainerContent {
  __typename: "TileContentContainer";
  items: ContainerItem[];
}

export interface ContainerContext {
  items: ContainerItem[];
  screenA: PlaybackScreen | null;
  screenB: PlaybackScreen | null;
  isInfinite: boolean;
  currentSlideIndex: number;
  isFirstRender: boolean;
  isTransitionAnimationEnabled: boolean;
}

type ContainerItem = Media | App;

export interface ContentScreenContext {
  done: TODO;
  loaded: TODO;
  screenA: Tile[];
  screenB: Tile[];
  slides: Slide[];
  isInfinite: boolean;
  currentSlideIndex: number;
  isFirstRender: boolean;
  isTransitionAnimationEnabled: boolean;
  returnBackToSlideIndex: number[];
}

export interface FramesScreenContext {
  items: {
    item: FrameItem;
    screen: PlaybackScreen;
  }[];
  done: object;
  isInfinite: boolean;
}

export interface ExternalContentContext {
  props: ExternalContent;
}

type TODO = any;

export type InteractionClick = {
  kind: "click";
};

export type InteractionTrigger = InteractionClick;

export type InteractionGoToSlide = {
  kind: "goToSlide";
  slideId: string;
  returnBack: boolean;
};

export type InteractionAction = InteractionGoToSlide;

export type InteractionConfig<
  T extends InteractionTrigger = InteractionTrigger,
  A extends InteractionAction = InteractionAction
> = {
  trigger: T;
  action: A;
};

export type InteractionsConfig = Array<InteractionConfig>;

export type Tile = {
  rect: Rect;
  screen: PlaybackScreen;
  animation: AnimationConfig;
  contentRect?: Rect;
  interactions?: InteractionsConfig;
};

export type PlaybackScreen =
  | BlankScreen
  | ImageScreen
  | VideoScreen
  | DocumentScreen
  | StaticAppScreen
  | DynamicAppScreen
  | AudioScreen
  | ContentScreen
  | ShapeScreen
  | ContainerScreen
  | FrameScreen
  | ExternalImageContentScreen
  | ExternalVideoContentScreen
  | DashboardScreen;

interface Screen {
  id: string;
  type:
    | "content"
    | "audio"
    | "dynamicApp"
    | "staticApp"
    | "video"
    | "image"
    | "document"
    | "blank"
    | "shape"
    | "frame"
    | "container"
    | "externalImageContent"
    | "externalVideoContent"
    | "dashboard";
  props:
    | ContentScreenProps
    | FormattedAudio
    | FormattedDocument
    | App
    | FormattedVideo
    | ImageProps
    | ShapeContent
    | FrameContent
    | ContainerContent
    | ExternalContent
    | DashboardInstance
    | never;
  ref: ActorRef<any>;
}

async function downloadToIndexedDB(downloadPath: string, mediaId: string) {
  const store = mediaContentStore();
  const resp = await fetch(downloadPath);
  await set(mediaId, await resp.blob(), store);
}

async function updateScreenshot(screen: PlaybackScreen | null): Promise<void> {
  const screenshot = await renderHTMLScreenshot(screen);
  await saveScreenshot(screenshot);
}

async function renderHTMLScreenshot(
  screen: PlaybackScreen | null
): Promise<Blob> {
  if (screen?.type === "image") {
    return await loadImage(screen.props.media.formatUrl);
  } else if (screen?.type === "video") {
    const frame =
      screen.props.frames?.first.url || screen.props.frames?.last.url;
    if (frame) {
      return await loadImage(frame);
    } else {
      return await renderText("Frameless video");
    }
  } else if (screen?.type === "document") {
    return await renderText(
      `PDF screenshot support is coming ${screen.props.mediaId}`
    );
  } else if (screen?.type === "content") {
    return await renderText(
      `Studio Content screenshot support is coming ${screen.props.contentId}`
    );
  }
  return await renderText(`Screenshot support for: ${screen?.type} is coming`);
}

async function saveScreenshot(screenshot: Blob): Promise<void> {
  await set("screenshot", screenshot);
}

async function loadImage(src: string): Promise<Blob> {
  const reply = await fetch(src);
  const response = await reply.blob();
  return response;
}

async function renderText(text: string): Promise<Blob> {
  const canvas = window.document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    throw new Error("Could not get canvas context");
  }
  ctx.fillText(text, 10, 50);
  const blob = await canvasToBlob(canvas);
  return blob;
}

function canvasToBlob(canvas: HTMLCanvasElement): Promise<Blob> {
  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      if (blob) {
        resolve(blob);
      }
    });
  });
}
