import { createMachine, sendParent, assign } from "xstate";
import { NotSupported } from "./PlayerMachine";

export const powerSavingMachine = createMachine(
  {
    id: "powerSavingMachine",
    schema: {
      context: {} as PowerSavingMachineContext,
      events: {} as PowerSavingMachineEvents,
    },
    tsTypes: {} as import("./PowerSavingMachine.typegen").Typegen0,
    initial: "updateCurrentStatus",
    context: {
      powerSavingSchedule: null,
    },
    on: {
      UPDATE_SETTINGS: {
        actions: "updateSettings",
        target: "updateCurrentStatus",
      },
    },
    states: {
      updateCurrentStatus: {
        always: {
          actions: "sendParentCurrentStatus",
          target: "scheduleNextUpdate",
        },
      },
      scheduleNextUpdate: {
        after: {
          NEXT_UPDATE_DELAY: {
            target: "updateCurrentStatus",
          },
        },
      },
    },
  },
  {
    actions: {
      sendParentCurrentStatus: sendParent((context) => {
        const isEnabled = determineCurrentStatusFromSchedule(context);
        return {
          type: isEnabled ? "ENABLE_POWER_SAVING" : "DISABLE_POWER_SAVING",
        };
      }),
      updateSettings: assign({
        powerSavingSchedule: (context: PowerSavingMachineContext, event: any) =>
          event.settings.powerSavingSchedule
            ? event.settings.powerSavingSchedule
            : context.powerSavingSchedule,
      }),
    },
    delays: {
      NEXT_UPDATE_DELAY: (context) => {
        return calcMsToNextUpdate(context);
      },
    },
  }
);

export function calcMsToNextUpdate(
  context: PowerSavingMachineContext
): DayMillis {
  if (
    !context.powerSavingSchedule ||
    context.powerSavingSchedule.isEnabled === false
  ) {
    return 1000 * 60 * 60 * 24;
  }
  const currentMillis = getCurrentDayMillis();
  const startMillis = getMillisFromTime(context.powerSavingSchedule?.start);
  const endMillis = getMillisFromTime(context.powerSavingSchedule?.end);
  const isOn = determineCurrentStatusFromSchedule(context);
  const target = isOn ? endMillis : startMillis;
  if (target > currentMillis) {
    return target - currentMillis;
  } else {
    return target + (86_400_000 - currentMillis);
  }
}

export function determineCurrentStatusFromSchedule(
  context: PowerSavingMachineContext
): boolean {
  if (
    !context.powerSavingSchedule ||
    context.powerSavingSchedule.isEnabled === false
  ) {
    return false;
  }
  const currentMillis = getCurrentDayMillis();
  const startMillis = getMillisFromTime(context.powerSavingSchedule?.start);
  const endMillis = getMillisFromTime(context.powerSavingSchedule?.end);
  if (startMillis < endMillis) {
    return currentMillis >= startMillis && currentMillis <= endMillis;
  } else {
    return currentMillis >= startMillis || currentMillis <= endMillis;
  }
}

function getMillisFromTime(time: string): DayMillis {
  const [hours, minutes] = time.split(":").map(Number);
  return 1000 * 60 * minutes + 1000 * 60 * 60 * hours;
}

function getCurrentDayMillis(): DayMillis {
  const dt = new Date();
  const millis =
    dt.getMilliseconds() +
    dt.getSeconds() * 1000 +
    1000 * 60 * dt.getMinutes() +
    1000 * 60 * 60 * dt.getHours();
  return millis;
}

type DayMillis = number; // 0 – 86,400,000

export interface EnablePowerSavingEvent {
  type: "ENABLE_POWER_SAVING";
}

export interface DisablePowerSavingEvent {
  type: "DISABLE_POWER_SAVING";
}

export interface PowerSavingMachineContext {
  powerSavingSchedule: PowerSavingSchedule;
}

type PowerSavingMachineEvents = {
  type: "UPDATE_SETTINGS";
  settings: {
    powerSavingSchedule: PowerSavingSchedule;
  };
};

export type PowerSavingSchedule =
  | {
      start: string;
      end: string;
      isEnabled: boolean;
    }
  | NotSupported;
