import { useCallback, useEffect, useRef, useState } from "react";
import { FormattedAudio } from "../../generated/router";
import styles from "./index.module.css";

export const Audio = ({
  media,
  onDone,
  onContentReady,
  shouldBePlaying,
  duration,
  isInfinite,
}: Props) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const play = useCallback(() => {
    try {
      if (isInfinite) {
        audioRef.current?.setAttribute("loop", "true");
      } else {
        audioRef.current?.removeAttribute("loop");
      }
      audioRef.current?.play();
    } catch (e) {
      // an error has happened but we don't switch to the next slide in case it's actually playing
      // despite the error
      console.error(e);
    }
    setIsPlaying(true);
  }, [isInfinite]);

  // start playing when <Playback /> is asking us to play
  useEffect(() => {
    if (shouldBePlaying) play();
  }, [shouldBePlaying, play]);

  // signal that the component is ready to play
  // also start playing if previously <Playback /> has asked us to play
  const handleCanPlay = () => {
    onContentReady();
    if (shouldBePlaying) play();
  };

  // timeout: switch to the next slide in case the audio didn't dispatch onEnded event
  const TIMEOUT = 10 * 1000; // 10 sec
  useEffect(() => {
    if (!isPlaying) return;

    // do not set timeout if duration is not set
    // 10000 ms is a default value when duration is unknown
    if (!duration || duration === 10000) return;

    const id = setTimeout(() => {
      console.error("Audio timeout");
      onDone();
    }, duration + TIMEOUT);
    return () => clearTimeout(id);
  }, [isPlaying, TIMEOUT, onDone, duration]);

  return (
    <div className={styles.root}>
      <div>{media.formatName || media.mediaId}</div>
      <div>
        {~~(duration / 1000 / 60)}:{(duration / 1000) % 60}
      </div>
      <audio
        controls
        onCanPlay={handleCanPlay}
        ref={audioRef}
        onEnded={() => onDone()}
      >
        <source src={media.formatUrl} type="audio/mpeg" />
      </audio>
    </div>
  );
};

interface Props {
  media: FormattedAudio;
  onDone: (error?: string) => void;
  onContentReady: () => void;
  shouldBePlaying: boolean;
  duration: number;
  isInfinite: boolean;
}
