import { DoneInvokeEvent } from "xstate";

export function checkGraphqlResponse<T>(
  event: DoneInvokeEvent<FetchMachineResult<T>>,
  checkData: (data: T) => boolean
): boolean {
  const body = event.data.body;
  if (body) {
    const data = body.data;
    return data && checkData(data);
  }
  return false;
}

export interface FetchMachineResult<T> {
  body?: {
    data: T;
  };
}
