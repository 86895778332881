import React, {
  CSSProperties,
  SyntheticEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { AVPlayManager } from "tizen-tv-webapis";
import { FormattedVideo } from "../../generated/router";
import { TizenPlayer } from "../../global";
import { getAPKVersion } from "../../misc/get-apk-version";
import { isTizen } from "../../misc/get-default-video-playback-type";
import {
  ImageFit,
  shouldStoreOnBrightSign,
  shouldUseTizenAPI,
  shouldUseScapAPI,
} from "../../stateMachines/PlaybackMachine";
import { getFullTizenPath } from "../../tizen/utils";
import { useSize } from "../image/useSize";
import styles from "./index.module.css";
import { Property } from "csstype";
import { getPlayerType } from "../../misc/get-player-type";
import { mediaContentStore } from "../../MediaStore";
import { get } from "idb-keyval";
import { getFullScapPath } from "../../scap/utils";
import HTMLVideo from "./html-video";

type Props = {
  uniqueId: string;
  media: FormattedVideo;
  onDone: (error?: string) => void;
  onContentReady: () => void;
  shouldBePlaying: boolean;
  screenOrientation: number;
  shouldForceHtmlVideo: boolean;
  shouldPrerenderVideo: boolean;
  isInfinite: boolean;
  isFullscreen: boolean;
  originalFormat: boolean;
  isSurface: boolean;
  isFlickeringWorkaroundEnabled: boolean;
  isMuted: boolean;
  fit?: ImageFit;
  shouldStoreMediaInIndexedDB: boolean;
  isCacheAPIForced: boolean;
};

const SCREEN_ORIENTATION_LANDSCAPE_0 = 0;

export const VideoPlaybackComponent = ({
  uniqueId,
  media,
  onDone,
  onContentReady,
  shouldBePlaying,
  screenOrientation = 0,
  shouldForceHtmlVideo,
  shouldPrerenderVideo,
  isInfinite,
  isFullscreen,
  originalFormat,
  isSurface,
  isFlickeringWorkaroundEnabled,
  isMuted,
  fit,
  shouldStoreMediaInIndexedDB,
  isCacheAPIForced,
}: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const nativePlaceholderRef = useRef<HTMLDivElement>(null);
  const [wasFirstFrameDisplayed, setFirstFrameDisplayed] = useState(false);
  const [isContentReady, setContentReady] = useState(false);
  const mediaUrl = originalFormat ? media.originalUrl : media.formatUrl;
  const [src, setSrc] = useState(mediaUrl);
  const mediaDuration: number = media.duration || 60000;
  const tizenPlayer = useRef<TizenPlayer | null>(null);

  const shouldRenderNative = window.FugoBridge && !shouldForceHtmlVideo;
  const shouldRenderTizen =
    !!(isTizen() && window?.webapis?.avplay) && !shouldForceHtmlVideo;
  const isStoredOnBrightSign = shouldStoreOnBrightSign();

  useEffect(() => {
    (async function () {
      if (isCacheAPIForced) {
        console.log("Cache API forced, using default media url");
      } else if (shouldUseTizenAPI()) {
        console.log("using tizen media url");
        const fullPath = await getFullTizenPath(media.mediaId);
        const url = fullPath != null ? fullPath : mediaUrl;
        setSrc(url);
      } else if (shouldStoreMediaInIndexedDB) {
        console.log("using indexed db media url");
        const store = mediaContentStore();
        const blob = await get<Blob>(media.mediaId, store);
        if (blob) {
          const url = URL.createObjectURL(blob);
          setSrc(url);
        } else {
          console.log(
            "Empty blob. Video is not downloaded or evicted from IndexedDB",
            media.mediaId
          );
        }
      } else if (shouldUseScapAPI()) {
        const fullPath = await getFullScapPath(media.mediaId);
        const url = fullPath != null ? fullPath : mediaUrl;
        setSrc(url);
      } else if (isStoredOnBrightSign) {
        const url = `file:///${media.mediaId}`;
        setSrc(url);
      }
    })();
  }, [
    media.mediaId,
    mediaUrl,
    shouldStoreMediaInIndexedDB,
    isStoredOnBrightSign,
    isCacheAPIForced,
  ]);

  useEffect(() => {
    if (shouldRenderNative) {
      setContentReady(true);
    }

    if (shouldRenderTizen) {
      preparePlayer(uniqueId, media.mediaId, mediaUrl)
        .then((tp) => {
          tizenPlayer.current = tp;
          setContentReady(true);
        })
        .catch((err) => console.log(JSON.stringify(err, null, 2)));
    }
  }, [
    shouldRenderNative,
    shouldRenderTizen,
    uniqueId,
    mediaUrl,
    media.mediaId,
  ]);

  useEffect(() => {
    if (isContentReady) onContentReady();
  }, [isContentReady, onContentReady]);

  useEffect(() => {
    if (shouldRenderNative || shouldRenderTizen) return;
    if (!shouldBePlaying) return;

    const timer = setTimeout(() => {
      if (!wasFirstFrameDisplayed) {
        // It may occur that for some reasons the video can't be displayed.
        // After timeout if first frame was not rendered, then `onDone()` function
        // will be called and next content will be displayed.
        console.log("First frame timeout fired");
        onDone();
      }
    }, 30000);
    return () => clearTimeout(timer);
  }, [
    onDone,
    wasFirstFrameDisplayed,
    shouldRenderNative,
    shouldRenderTizen,
    shouldBePlaying,
  ]);

  const onTimeUpdate = (event: React.SyntheticEvent<HTMLVideoElement>) => {
    if (
      event.type === "timeupdate" &&
      !wasFirstFrameDisplayed &&
      (videoRef.current?.currentTime ?? 0) > 0
    ) {
      setFirstFrameDisplayed(true);
    }
  };

  const [hasFinished, setHasFinished] = useState(false);
  const onDoneRef = useRef(() => {});
  onDoneRef.current = onDone;
  const isOnDoneCalled = useRef(false);
  const onDoneOnce = useCallback(function onDoneOnce() {
    if (isOnDoneCalled.current) return;
    isOnDoneCalled.current = true;
    onDoneRef.current();
    setHasFinished(true);
    console.log("Has finished playing a video");
  }, []);

  const backgroundImage = media.frames
    ? wasFirstFrameDisplayed
      ? media.frames.last.url
      : media.frames.first.url
    : undefined;
  const styleWithBackgroundImage = backgroundImage
    ? {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: getVideoObjectCover(fit),
      }
    : {};

  const startPlaying = useCallback(
    function startPlaying() {
      if (shouldRenderTizen) {
        try {
          playTizen(
            mediaDuration,
            onDoneOnce,
            screenOrientation,
            tizenPlayer.current
          );
          const id = setTimeout(() => {
            console.log("Tizen timeout fired");
            onDoneOnce();
            freeTizenPlayer(tizenPlayer.current);
          }, mediaDuration + 10000);
          const frameTimeoutId = setTimeout(
            () => setFirstFrameDisplayed(true),
            1000
          );
          return () => {
            clearTimeout(id);
            clearTimeout(frameTimeoutId);
            freeTizenPlayer(tizenPlayer.current);
          };
        } catch (e) {
          console.error((e as Error).message);
          console.log({ player: webapis.avinfo });
          return;
        }
      } else if (shouldRenderNative) {
        let isMounted = true;
        const apkVersion = getAPKVersion();
        let isNativeVideoDestroyed = false;
        const videoSize =
          nativePlaceholderRef.current?.getBoundingClientRect() ??
          new DOMRect();

        const randomCallbackFunctionName: string =
          "_native_video_" + Math.random().toString(36).substr(2, 9);

        // @ts-expect-error
        window[randomCallbackFunctionName] = () => {
          isNativeVideoDestroyed = true;
          if (isFullscreen) {
            console.log("window.FugoBridge.destroyVideoFullscreen()");
            window.FugoBridge.destroyVideoFullscreen();
          } else {
            console.log(
              `window.FugoBridge.destroyVideo(${randomCallbackFunctionName})`
            );
            window.FugoBridge.destroyVideo(randomCallbackFunctionName);
          }
          onDoneRef.current();
          if (isInfinite && isMounted) {
            startPlaying();
          }

          // @ts-expect-error
          window[randomCallbackFunctionName] = () => {
            console.log(
              `Video is already destroyed ${randomCallbackFunctionName}`
            );
          };
        };

        if (isFullscreen) {
          console.log(
            `window.FugoBridge.playVideoFullscreen(${mediaUrl}, ${SCREEN_ORIENTATION_LANDSCAPE_0}, ${randomCallbackFunctionName}, ${isSurface}, ${isFlickeringWorkaroundEnabled}, ${isMuted})`
          );
          if (apkVersion.major >= 2) {
            if (apkVersion.minor >= 6) {
              window.FugoBridge.playVideoFullscreen(
                mediaUrl,
                SCREEN_ORIENTATION_LANDSCAPE_0,
                randomCallbackFunctionName,
                isSurface,
                isFlickeringWorkaroundEnabled,
                isMuted
              );
            } else {
              window.FugoBridge.playVideoFullscreen(
                mediaUrl,
                SCREEN_ORIENTATION_LANDSCAPE_0,
                randomCallbackFunctionName,
                isSurface
              );
            }
          } else {
            window.FugoBridge.playVideoFullscreen(
              mediaUrl,
              SCREEN_ORIENTATION_LANDSCAPE_0,
              randomCallbackFunctionName
            );
          }
        } else {
          const pixelRatio = getDevicePixelRatio();
          const x = ~~(videoSize.x * pixelRatio);
          const y = ~~(videoSize.y * pixelRatio);
          const width = ~~(videoSize.width * pixelRatio);
          const height = ~~(videoSize.height * pixelRatio);

          console.log(`window.FugoBridge.playVideo(
            ${mediaUrl},
            ${SCREEN_ORIENTATION_LANDSCAPE_0},
            ${x},
            ${y},
            ${width},
            ${height},
            ${randomCallbackFunctionName},
            ${isSurface},
            false,
            ${isMuted}
          )`);

          if (apkVersion.major >= 2) {
            window.FugoBridge.playVideo(
              mediaUrl,
              SCREEN_ORIENTATION_LANDSCAPE_0,
              x,
              y,
              width,
              height,
              randomCallbackFunctionName,
              isSurface,
              false,
              isMuted
            );
          } else {
            window.FugoBridge.playVideo(
              mediaUrl,
              SCREEN_ORIENTATION_LANDSCAPE_0,
              x,
              y,
              width,
              height,
              randomCallbackFunctionName
            );
          }
        }
        const firstFrameDuration = mediaDuration / 2;
        const id = setTimeout(
          () => setFirstFrameDisplayed(true),
          firstFrameDuration
        );

        const finishTimeoutMs = mediaDuration + 5000;
        const finishTimeout = setTimeout(() => {
          console.log(
            `Android native video playback timeout fired after ${finishTimeoutMs}ms`
          );
          onDoneOnce();
        }, finishTimeoutMs);

        return () => {
          isMounted = false;
          clearTimeout(id);
          clearTimeout(finishTimeout);

          if (!isNativeVideoDestroyed) {
            isNativeVideoDestroyed = true;
            if (isFullscreen) {
              console.log(
                `window.FugoBridge.destroyVideoFullscreen() on unmount`
              );
              window.FugoBridge.destroyVideoFullscreen();
            } else {
              console.log(
                `window.FugoBridge.destroyVideo(${randomCallbackFunctionName})`
              );
              window.FugoBridge.destroyVideo(randomCallbackFunctionName);
            }
          }
        };
      } else if (videoRef.current) {
        play(videoRef, onDone, mediaDuration, isInfinite);
        startWatching(videoRef, onDone);
      }
    },
    // ignoring isSurface and isFlickeringWorkaroundEnabled
    // so it doesn't disrupt playback and only applied on the next video
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      shouldRenderTizen,
      shouldRenderNative,
      mediaDuration,
      onDoneOnce,
      screenOrientation,
      isFullscreen,
      isInfinite,
      mediaUrl,
      onDone,
    ]
  );

  useLayoutEffect(() => {
    if (shouldBePlaying) {
      if (isStoredOnBrightSign) {
        setTimeout(() => setFirstFrameDisplayed(true), 5000);
      }
      return startPlaying();
    }
  }, [isStoredOnBrightSign, shouldBePlaying, startPlaying]);

  const handleContentReady = useCallback(() => setContentReady(true), []);

  const [htmlRootRect, htmlRootRef] = useSize();

  if (shouldRenderTizen) {
    return (
      <div className={styles.root} style={styleWithBackgroundImage}>
        {shouldBePlaying ? (
          <object
            type="application/avplayer"
            aria-label="Tizen"
            className={styles.video}
            id={`av-player-${uniqueId}`}
          ></object>
        ) : null}
      </div>
    );
  }

  if (shouldRenderNative) {
    return (
      <div
        className={styles.root}
        style={styleWithBackgroundImage}
        ref={nativePlaceholderRef}
      />
    );
  }

  const objectFit = getVideoObjectCover(fit);
  let style: CSSProperties = {
    objectFit,
  };
  if (!shouldUseTizenAPI()) {
    if (screenOrientation === 90) {
      style = {
        ...style,
        position: "absolute",
        ...(originalFormat
          ? {
              width: htmlRootRect?.width,
              height: htmlRootRect?.height,
            }
          : {
              transform: `rotate(${screenOrientation * -1}deg)`,
              width: htmlRootRect?.height,
              height: htmlRootRect?.width,
              transformOrigin: "top left",
              top: "100%",
            }),
      };
    } else if (screenOrientation === 180) {
      style = {
        ...style,
        ...(originalFormat
          ? {}
          : {
              transform: `rotate(${screenOrientation * -1}deg)`,
            }),
      };
    } else if (screenOrientation === 270) {
      style = {
        ...style,
        position: "absolute",
        ...(originalFormat
          ? {
              width: htmlRootRect?.width,
              height: htmlRootRect?.height,
            }
          : {
              transform: `rotate(${screenOrientation * -1}deg)`,
              width: htmlRootRect?.height,
              height: htmlRootRect?.width,
              transformOrigin: "top left",
              left: "100%",
            }),
      };
    }
  }

  let poster = media.frames?.first.url;
  if (getPlayerType() === "brightsign") {
    poster = undefined;
  } else if (screenOrientation === 0) {
    poster =
      "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";
  }

  const canPlayHandler = async (e: SyntheticEvent<HTMLVideoElement>) => {
    if (shouldPrerenderVideo && !shouldBePlaying) {
      const videoEl = e.currentTarget;
      videoEl.muted = true;
      console.log("play to be paused");
      await videoEl.play();
      videoEl.pause();
      console.log("paused");
      videoEl.muted = isMuted;
    }
  };

  if (isStoredOnBrightSign) {
    return (
      <div
        className={styles.root}
        style={styleWithBackgroundImage}
        ref={htmlRootRef}
      >
        {shouldBePlaying ? (
          <HTMLVideo
            ref={videoRef}
            onEnded={onDoneOnce}
            onTimeUpdate={onTimeUpdate}
            onLoad={handleContentReady}
            onLoadedData={handleContentReady}
            style={style}
            muted={isMuted}
            poster={poster}
            onCanPlay={canPlayHandler}
            src={src}
            hwz={`on; transform:${toBSRotation(screenOrientation)}; z-index:1`}
          />
        ) : null}
      </div>
    );
  }

  return (
    <div
      className={styles.root}
      style={styleWithBackgroundImage}
      ref={htmlRootRef}
    >
      {(shouldBePlaying && !hasFinished) || shouldPrerenderVideo ? (
        <video
          className={styles.video}
          ref={videoRef}
          onEnded={onDoneOnce}
          onTimeUpdate={onTimeUpdate}
          onLoad={handleContentReady}
          onLoadedData={handleContentReady}
          preload="auto"
          // @ts-expect-error
          hwz={`on; transform:${toBSRotation(screenOrientation)}; z-index:1`}
          style={style}
          muted={isMuted}
          poster={poster}
          onCanPlay={canPlayHandler}
        >
          <source src={src} type={`video/${getFileExtension(mediaUrl)}`} />
          There is a problem with displaying video.
        </video>
      ) : null}
    </div>
  );
};

async function playTizen(
  mediaDuration: number,
  onDone: () => void,
  screenOrientation: number,
  tizenPlayer: TizenPlayer | null
) {
  if (!tizenPlayer) {
    onDone();
    return;
  }
  const player = tizenPlayer.player;

  player.setListener({
    oncurrentplaytime: (currentTime) => {
      const duration = mediaDuration;
      if (currentTime >= duration) {
        console.log(`Done: ${currentTime} >= ${duration}`);
      }
    },
    onerror: (err) => console.error(err),
    onstreamcompleted: function () {
      freeTizenPlayer(tizenPlayer);
      onDone();
      console.log("Stream Completed");
    },
  });

  // @ts-expect-error bc this is a hidden b2b api
  player.setVideoStillMode("false");

  const videoOrientation = `PLAYER_DISPLAY_ROTATION_${screenOrientation}`;
  // @ts-expect-error bc this is a hidden b2b api
  player.setDisplayRotation(videoOrientation);

  player.prepareAsync(
    () => {
      console.log(`${tizenPlayer.uniqueId} is prepared`);
      console.log(`start tizen play ${player.play()}`);
    },
    (err) => console.error(`prep error of ${tizenPlayer.uniqueId}, ${err}`)
  );
}

// the number of players Tizen allows to pre-buffer
const TIZEN_PLAYER_LIMIT = 4;

async function preparePlayer(
  uniqueId: string,
  mediaId: string,
  mediaUrl: string
): Promise<TizenPlayer | null> {
  if (!window.tizenPlayers) {
    window.tizenPlayers = {
      [uniqueId]: {
        isBusy: false,
        player: webapis.avplaystore.getPlayer(),
        uniqueId,
      },
      tmp: {
        isBusy: false,
        player: webapis.avplaystore.getPlayer(),
        uniqueId: "tmp",
      },
    };
    window.addEventListener("unload", function () {
      Object.entries(window.tizenPlayers || {}).forEach(
        ([_uniqueId, tizenPlayer]) => {
          tizenPlayer.player.stop();
          tizenPlayer.player.close();
        }
      );
    });
  }

  let player: AVPlayManager | null = null;

  // there's a free player
  const freePlayer = Object.entries(window.tizenPlayers).find(
    ([_uniqueId, player]) => !player.isBusy
  );
  if (freePlayer) {
    console.log(`reusing tizen player ${freePlayer[0]}`);
    player = freePlayer[1].player;
    delete window.tizenPlayers[freePlayer[0]];
  } else if (Object.keys(window.tizenPlayers).length < TIZEN_PLAYER_LIMIT) {
    console.log("creating new tizen player");
    player = webapis.avplaystore.getPlayer();
  }

  // if this happens this might interrupt the current playback
  // this will happen in 2 cases: a bug and 6 videos in Studio content
  if (!player) {
    console.log("using tizen avplay");
    player = webapis.avplay;
  }

  const newPlayer: TizenPlayer = {
    player,
    isBusy: true,
    uniqueId,
  };
  window.tizenPlayers[uniqueId] = newPlayer;

  const fullPath = await getFullTizenPath(mediaId);
  const url = fullPath != null ? fullPath : mediaUrl;
  console.log(`video url: ${url}`);
  console.log(`tizen open: ${player.open(url)}`);

  player.setStreamingProperty("SET_MODE_4K", "TRUE");

  player.setDisplayRect(0, 0, 1920, 1080);

  return newPlayer;
}

function freeTizenPlayer(tizenPlayer: TizenPlayer | null) {
  console.log(
    `Freeing tizen player ${tizenPlayer?.uniqueId}, ${tizenPlayer?.isBusy}`
  );
  if (!tizenPlayer) return;
  if (!tizenPlayer?.isBusy) return;

  tizenPlayer.isBusy = false;
  tizenPlayer.player.setDisplayRect(0, 0, 0, 0);
  // @ts-expect-error bc this is a hidden b2b api
  tizenPlayer.player.setVideoStillMode("true");
  tizenPlayer.player.stop();
}

function toBSRotation(screenOrientation: number) {
  return "rot0";
}

// we have implemented this bridge method because I couldn't figure out how window.devicePixelRatio works.
// We saw obscure coofiecients that didn't match with the real screen dimensions and the viewport size.
// That lead for the videos to be too big or too small on screens with different DPI.
export function getDevicePixelRatio() {
  try {
    const { width }: { width: number } = JSON.parse(
      window.FugoBridge.getViewSize()
    );
    if (!width) {
      throw new Error(`incorrect screen dimensions: ${width}`);
    }
    return width / window.innerWidth;
  } catch (e) {
    console.error(e);
  }

  const devicePixelRatio = isMobile()
    ? Math.min(window.devicePixelRatio, 2)
    : 1;
  return devicePixelRatio;
}

export function getVideoObjectCover(fit?: ImageFit): Property.ObjectFit {
  if (fit === "cover") {
    return "cover";
  }
  return "contain";
}

export function isMobile() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(
    navigator.userAgent ||
      navigator.vendor ||
      // @ts-expect-error
      window.opera
  );
  return check;
}

export async function play(
  ref: React.RefObject<HTMLVideoElement>,
  onDone: () => void,
  duration: number,
  isInfinite: boolean,
  started = Date.now()
) {
  const timeout = Math.min(30000, duration);

  // unmounted
  if (ref.current === null) {
    console.debug("video is already unmounted");
    return;
  }

  // already playing
  if (ref.current.currentTime > 1 && !ref.current.ended) {
    console.debug(`video is already playing ${ref.current.currentTime}`);
    return;
  }

  // timeout
  if (started + timeout < Date.now() && !isInfinite) {
    console.log(
      `video playback did not start, timeout: ${started} + ${timeout} > ${Date.now()}`
    );
    onDone();
    return;
  }

  try {
    console.debug(`playing video`);

    if (isInfinite) {
      ref.current.setAttribute("loop", "true");
    } else {
      ref.current.removeAttribute("loop");
    }
    const id = ref.current.poster;
    console.time(`Start playing the video ${id}`);
    await ref.current.play();
    console.timeEnd(`Start playing the video ${id}`);
  } catch (e) {
    console.debug(`video error: ${e}`);
    if (!ref.current) {
      console.debug(`video element is already unmounted: firing Done callback`);
      onDone();
      return;
    }
    const delay = ref.current.muted ? 1000 : 0;
    ref.current.muted = true;
    console.log(`Retrying to play the video in ${delay} ms`);
    setTimeout(() => play(ref, onDone, duration, isInfinite, started), delay);
  }
}

// start watching if the video has frozen
// this is hapenning on IAdea MBR-1100
function startWatching(
  ref: React.RefObject<HTMLMediaElement>,
  onDone: (error?: string) => void
) {
  // only do this for outdated browser
  // since they are likely to run on less powerful devices and freeze
  if (!isOutdatedBrowser()) {
    return;
  }

  // the initial value is -1 so it doesn't match right off the bat
  let currentTime = -1;

  function check() {
    // unmounted
    if (!ref.current) return;

    // ended
    if (ref.current.ended) return;

    // video didn't progress since we checked it last time
    if (ref.current.currentTime === currentTime) {
      onDone(`Video did not progress. Video time: ${ref.current.currentTime}`);
      return;
    }

    currentTime = ref.current.currentTime;

    setTimeout(check, 30000);
  }

  check();
}

export function isOutdatedBrowser() {
  if (chromeVersion() < 80) return true;
  return false;
}

export function chromeVersion() {
  const [, chromeVersion] = navigator.userAgent.match(/Chrome\/([^.]+)/) ?? [];
  return ~~chromeVersion;
}

function getFileExtension(mediaUrl: string) {
  const ext = mediaUrl.split(".").pop();
  if (!ext || ext?.length > 4) {
    return "mp4";
  }
  return ext;
}
