import * as React from "react";
import { useActor } from "@xstate/react";
import { ActorRef } from "xstate";
import { FormattedMedia } from "../../generated/router";
import {
  ContentScreenContext,
  ContentScreenProps,
  ImageFit,
  InteractionConfig,
  PlaybackScreen,
  Tile,
} from "../../stateMachines/PlaybackMachine";
import { Screen } from "../playback";
import styles from "./index.module.css";
import { Settings } from "../../stateMachines/PlayerMachine";
import { AnimationConfig, Rect } from "../MediaUtils";
import { ProtocolHeaders } from "../app/dynamic";
import { Contain } from "../image/Contain";
import { BaseSyntheticEvent, ComponentProps, ReactElement } from "react";
import { Size } from "../image/Size";

export function Content(props: Props) {
  const [state, sendToContent] = useActor(props.actor);

  const isInfinite =
    props.isInfinite && state.matches("playing.playingA.single");

  const shouldPlayA =
    state.matches("playing.playingA") || state.matches("done");

  const transitionA = state.matches("playing.transitionA");

  const a = state.context.isFirstRender || shouldPlayA || transitionA;

  const shouldPlayB =
    state.matches("playing.playingB") || state.matches("done");

  const transitionB = state.matches("playing.transitionB");

  const b = shouldPlayB || transitionB;

  const isTransition = transitionA || transitionB;

  const valA = a ? 1 : 0;
  const valB = b ? 1 : 0;

  // change viewport to match Studio content
  const { settings: contentSettings } = props.value;
  const {
    width: contentWidth = 1920,
    height: contentHeight = 1080,
    disableViewport = false,
  } = contentSettings ?? {};
  const { width, height } = disableViewport
    ? { width: window.innerWidth, height: window.innerHeight }
    : { width: contentWidth, height: contentHeight };
  const viewport: Size = { width, height };

  const getContentStyle = ({
    width: containerWidth,
    height: containerHeight,
  }: {
    width: number;
    height: number;
  }) => ({
    transformOrigin: "0 0",
    transform: `scale(${Math.min(
      containerWidth / width,
      containerHeight / height
    )})`,
    width,
    height,
  });

  const handleInteraction = (interaction: InteractionConfig) => {
    sendToContent({
      type: "USER_INTERACTION",
      action: interaction.action,
    });
  };

  return (
    <Contain aspectRatio={width / height}>
      {(containerStyle) => (
        <div className={styles.root} style={containerStyle}>
          <div
            className={`${styles.fullScreen} ${
              isTransition ? styles.opacityTransition : ""
            }`}
            style={{
              opacity: valA,
              zIndex: valA,
              ...getContentStyle(containerStyle),
            }}
          >
            <Slide
              viewport={viewport}
              slide={(state.context as ContentScreenContext).screenA}
              shouldPlay={shouldPlayA}
              send={props.send}
              medias={props.medias}
              isInfinite={isInfinite}
              settings={props.settings}
              headers={props.headers}
              onInteraction={handleInteraction}
            />
          </div>
          <div
            className={`${styles.fullScreen} ${
              isTransition ? styles.opacityTransition : ""
            }`}
            style={{
              opacity: valB,
              zIndex: valB,
              ...getContentStyle(containerStyle),
            }}
          >
            <Slide
              viewport={viewport}
              slide={(state.context as ContentScreenContext).screenB}
              shouldPlay={shouldPlayB}
              send={props.send}
              medias={props.medias}
              isInfinite={isInfinite}
              settings={props.settings}
              headers={props.headers}
              onInteraction={handleInteraction}
            />
          </div>
        </div>
      )}
    </Contain>
  );
}

const getAnimateProps = (animation: AnimationConfig, shouldPlay: boolean) => {
  const { entrance, entranceDuration, delay, motion, motionDuration } =
    animation;

  return {
    containerStyle:
      motion === "ticker"
        ? {
            overflow: "hidden",
          }
        : undefined,
    className:
      !shouldPlay && (entrance || motion)
        ? `animate__hidden`
        : entrance
        ? `animate__animated animate__delay-1s animate__${entrance}`
        : motion
        ? `animate__motion__${motion}`
        : "",
    style: {
      "--animate-duration": `${entranceDuration}ms`,
      "--animate-delay": `${delay}ms`,
      "--animate-motion-duration": `${motionDuration || 0}ms`,
    },
  };
};

export const TileCrop = ({
  tile,
  children,
}: {
  tile: Tile;
  children: ReactElement;
}) => {
  if (!tile.contentRect || getTileFit(tile.screen) !== "crop") {
    return children;
  }
  return (
    <div
      style={{
        position: "absolute",
        left: `${tile.contentRect.x * 100}%`,
        top: `${tile.contentRect.y * 100}%`,
        width: `${tile.contentRect.width * 100}%`,
        height: `${tile.contentRect.height * 100}%`,
      }}
    >
      {children}
    </div>
  );
};

function Slide({
  slide,
  ...props
}: {
  slide: Tile[];
} & Omit<ComponentProps<typeof SlideTile>, "tile">) {
  return (
    <>
      {slide.map((tile) => (
        <SlideTile {...props} tile={tile} key={tile.screen.id} />
      ))}
    </>
  );
}

let lastInteraction: number = 0;

function SlideTile({
  viewport,
  tile,
  shouldPlay,
  send,
  medias,
  isInfinite,
  settings,
  headers,
  onInteraction,
}: {
  viewport: Size;
  tile: Tile;
  shouldPlay: boolean;
  send: (args: any) => void;
  medias: FormattedMedia[];
  isInfinite: boolean;
  settings: Settings;
  headers: ProtocolHeaders;
  onInteraction: (interaction: InteractionConfig) => void;
}) {
  const clickInteraction = shouldPlay
    ? tile.interactions?.find(({ trigger: { kind } }) => kind === "click")
    : undefined;

  const clickHandler = (e: BaseSyntheticEvent) => {
    if (!clickInteraction) {
      return;
    }
    if (Date.now() - lastInteraction < 500) {
      return;
    }
    lastInteraction = Date.now();
    e.stopPropagation();
    onInteraction(clickInteraction);
  };
  const { entrance } = tile.animation;
  const animateProps = getAnimateProps(tile.animation, shouldPlay);
  const style = convertRectToCSS(viewport, tile);
  const element = (
    <div style={style}>
      <div
        onClick={clickHandler}
        style={{
          pointerEvents: clickInteraction ? "all" : "none",
          ...animateProps.style,
          borderRadius: (style as any).borderRadius,
          ...(tile.screen.type === "shape" &&
          tile.screen.props.kind === "staticText"
            ? {}
            : {
                position: "absolute",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                width: "100%",
                height: "100%",
                overflow: "hidden",
              }),
        }}
        className={animateProps.className}
        data-testid={entrance && shouldPlay ? "tile-animated" : "tile"}
      >
        <TileCrop tile={tile}>
          <Screen
            key={tile.screen.id}
            value={tile.screen}
            send={send}
            settings={settings}
            shouldBePlaying={shouldPlay}
            medias={medias}
            isInfinite={isInfinite}
            isFullScreen={false}
            headers={headers}
          />
        </TileCrop>
      </div>
    </div>
  );
  if (tile.screen.type === "shape" && tile.screen.props.kind === "staticText") {
    return (
      <div
        key={`container_${tile.screen.id}`}
        style={{
          ...animateProps.containerStyle,
          ...getTextTileContainerStyle(tile),
        }}
      >
        {element}
      </div>
    );
  }
  return element;
}

export const getTileFit = (screen: PlaybackScreen): ImageFit | undefined => {
  if (screen.type === "image") return screen.props.config?.fit;
  if (screen.type === "video") return screen.props.config?.fit;
  if (screen.type === "externalImageContent") return screen.props.config.fit;
  if (screen.type === "externalVideoContent") return screen.props.config.fit;
  if (screen.type === "dashboard") return screen.props.config.fit;
  if (screen.type === "staticApp" || screen.type === "dynamicApp") {
    return "crop";
  }
  return "contain";
};

const relativeToAbsolute = <T extends Partial<Rect>>(
  viewport: Size,
  rect: T
): T => {
  return Object.entries(rect)
    .filter<[keyof Rect, number]>(
      (keyValue): keyValue is [keyof Rect, number] => keyValue[1] != null
    )
    .map<[keyof Rect, number]>(([key, value]) => [
      key,
      key === "rotate"
        ? value
        : key === "x" || key === "width"
        ? Math.round(value * viewport.width)
        : Math.round(value * viewport.height),
    ])
    .reduce<T>((result, [key, value]) => {
      result[key] = value;
      return result;
    }, {} as T);
};

const toDeg = (rad: number) => (rad * 180) / Math.PI;

const getAngle = ({
  width,
  height,
}: {
  width: number;
  height: number;
}): number => -toDeg(Math.atan2(width, height));

const rectToLine = ({ x, y, width, height }: Rect): Rect => ({
  x,
  y,
  width: 2,
  height: (width ** 2 + height ** 2) ** 0.5,
  rotate: getAngle({ width, height }),
});

const getTextTileContainerStyle = (tile: Tile): React.CSSProperties => {
  const { rect } = tile;
  if (rect.width) {
    return {
      position: "absolute",
      left: `${rect.x * 100}%`,
      top: `${rect.y * 100}%`,
      width: `${rect.width * 100}%`,
      height: `${(1 - rect.y) * 100}%`,
      willChange: `transform, width, height`,
    };
  }
  const delta = Math.min(rect.x, 1 - rect.x);
  const { align } = tile.screen.props;
  const x =
    (align || "center") === "center"
      ? rect.x - delta
      : align === "right"
      ? 0
      : rect.x;
  const width =
    (align || "center") === "center"
      ? delta * 2
      : align === "right"
      ? rect.x
      : 1 - rect.x;
  return {
    position: "absolute",
    left: `${x * 100}%`,
    top: `${rect.y * 100}%`,
    width: `${width * 100}%`,
    height: `${(1 - rect.y) * 100}%`,
    willChange: `transform, width, height`,
  };
};

function convertRectToCSS(viewport: Size, tile: Tile): React.CSSProperties {
  const { rect } = tile;
  if (!rect) return { left: 0, top: 0, width: 0, height: 0 };
  if (tile.screen.type === "shape" && tile.screen.props.kind === "staticText") {
    const { align } = tile.screen.props;
    if (rect.width) {
      return {
        transform: `
          rotate(${rect.rotate}deg)`,
        width: "100%",
        textAlign: align,
      };
    }
    return {
      transform: `
        rotate(${rect.rotate}deg)`,
      maxWidth: "100%",
      width: "max-content",
      textAlign: align,
      [(align || "center") === "center"
        ? "margin"
        : align === "right"
        ? "marginLeft"
        : "marginRight"]: "auto",
    };
  }
  if (tile.screen.type === "shape" && tile.screen.props.kind === "line") {
    const line = rectToLine(relativeToAbsolute(viewport, rect));
    return {
      left: line.x,
      top: line.y,
      transformOrigin: "0 0",
      transform: `rotate(${line.rotate}deg)`,
      width: tile.screen.props.width ?? 2,
      height: line.height,
      position: "absolute",
      opacity: tile.rect.opacity || 1,
    };
  }

  return {
    left: `${rect.x * 100}%`,
    top: `${rect.y * 100}%`,
    width: `${rect.width * 100}%`,
    height: `${rect.height * 100}%`,
    transformOrigin: "50% 50%",
    transform: rect.rotate != null ? `rotate(${rect.rotate}deg)` : undefined,
    position: "absolute",
    borderRadius: (rect.borderRadius ?? 0 / 100) * viewport.width,
    overflow: "hidden",
    opacity: tile.rect.opacity || 1, // FIX: doesn't work for children with absolute positioning
  };
}

type Props = {
  shouldBePlaying: boolean;
  isInfinite: boolean;
  value: ContentScreenProps;
  actor: ActorRef<any>;
  send: (type: any) => void;
  settings: Settings;
  medias: FormattedMedia[];
  headers: ProtocolHeaders;
  isPortrait: boolean;
};
