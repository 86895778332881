import { useEffect } from "react";
import { Sender } from "xstate";
import { FormattedMedia } from "../../generated/router";
import { Event } from "./dynamic";

export default function useAppMessageHandler(
  iframeRef: React.RefObject<HTMLIFrameElement>,
  messageIdRef: React.MutableRefObject<string>,
  send: Sender<Event>,
  medias: FormattedMedia[],
  isInfinite: boolean,
  onMessage: (data: any) => void
) {
  useEffect(() => {
    const eventHandler = (event: MessageEvent) => {
      const iframe = iframeRef.current;
      if (!iframe || !iframe.contentWindow) return;
      if (event.data.id !== messageIdRef.current) return;
      if (event.data.method === "done") {
        send("DONE");
      }

      if (event.data.method === "ready") {
        send("READY");
      }

      if (event.data.method === "paramReady") {
        send("PARAM_READY");
      }

      if (event.data.method === "getAsset") {
        const { assetId, tag } = event.data;
        const asset = medias.find((media) => media.mediaId === assetId);
        iframe.contentWindow.postMessage(
          {
            id: messageIdRef.current,
            method: "sendAsset",
            asset: asset?.formatUrl ?? assetId,
            tag,
            isInfinite,
          },
          "*"
        );
      }

      if (event.data.method === "isFeatureSupported") {
        const { feature } = event.data.payload;
        if (feature === "displayWebsite") {
          const isSupported =
            !!window.FugoBridge?.displayWebsite ||
            !!window.FugoElectronBridge?.displayWebsite;
          iframe.contentWindow.postMessage(
            {
              id: messageIdRef.current,
              method: "isFeatureSupported",
              payload: {
                feature,
                isSupported,
              },
            },
            "*"
          );
        }
      }

      onMessage(event.data);
    };

    window.addEventListener("message", eventHandler);
    return () => window.removeEventListener("message", eventHandler);
  }, [send, medias, isInfinite, iframeRef, messageIdRef, onMessage]);
}
