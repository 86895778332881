import React from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  gql,
  useQuery,
} from "@apollo/client";
import placeholder from "./placeholder.png";

const GET_SCREENSHOT_URL = gql`
  query getAppScreenshotUrl($appName: String!) {
    getAppScreenshotUrl(appName: $appName) {
      dashboardId
      width
      height
      presignedUrl
    }
  }
`;

export function OnPremAppScreenshots(props: Props) {
  const { appName, localIp } = props;
  const [client, setClient] = React.useState(() => {
    if (!localIp) return null;
    return new ApolloClient({
      uri: `https://${localIp}/web-transfer-proxy`,
      cache: new InMemoryCache(),
    });
  });
  React.useEffect(() => {
    if (!localIp) return;
    setClient(
      new ApolloClient({
        uri: `https://${localIp}/web-transfer-proxy`,
        cache: new InMemoryCache(),
      })
    );
  }, [localIp]);

  if (!appName || !localIp || !client)
    return (
      <div
        id="placeholder"
        style={{ backgroundImage: `url(${placeholder})` }}
      />
    );

  return (
    <ApolloProvider client={client}>
      <Screenshot appName={appName} />
    </ApolloProvider>
  );
}

function Screenshot({ appName }: { appName: string }) {
  const [now, setNow] = React.useState(Date.now());
  React.useEffect(() => {
    const interval = setInterval(() => {
      setNow(Date.now());
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const { loading, error, data } = useQuery(GET_SCREENSHOT_URL, {
    variables: { appName },
    pollInterval: 10000,
    fetchPolicy: "network-only",
  });
  if (loading) return <p>Loading...</p>;
  if (error)
    return (
      <p style={{ color: "white", fontSize: 48 }}>Error: {error.message}</p>
    );

  return (
    <img
      src={`${data.getAppScreenshotUrl.presignedUrl}?${now}`}
      alt="app screenshot"
      style={{ width: "100%", height: "100%", objectFit: "contain" }}
    />
  );
}

type Param = {
  duration: number;
  appName: string;
  localIp: string | null;
};

type Props = Param & { shouldBePlaying: boolean; onLoad: () => void };
