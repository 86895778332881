import { PDF } from "./pdf";
import style from "./index.module.css";
import { ZippedImages } from "./zipped-images";

export function Document({
  src,
  format,
  duration,
  onContentReady,
  onDone,
  shouldBePlaying,
  isInfinite,
}: Props) {
  return (
    <div className={style.wrapper}>
      {format === "pdf" ? (
        <PDF
          src={src}
          onContentReady={onContentReady}
          onDone={onDone}
          isInfinite={isInfinite}
          shouldBePlaying={shouldBePlaying}
          duration={duration}
        />
      ) : format === "png.zip" ? (
        <ZippedImages
          src={src}
          onContentReady={onContentReady}
          onDone={onDone}
          isInfinite={isInfinite}
          shouldBePlaying={shouldBePlaying}
          duration={duration}
        />
      ) : (
        <div className={style.error}>Unsupported document: {format}</div>
      )}
    </div>
  );
}

interface Props {
  src: string;
  format: string;
  onDone: (error?: string) => void;
  onContentReady: () => void;
  shouldBePlaying: boolean;
  duration: number;
  isInfinite: boolean;
}
