import "core-js";
import "react-app-polyfill/stable";
import { createRoot } from "react-dom/client";
import preval from "preval.macro";
import "./index.css";
import App from "./App";

import { initFugoBridgeMock } from "./mocks/FugoBridge";
import { silentReload } from "./misc/silent-reload";
import LogRocket from "logrocket";
import { Bravia, BraviaMock } from "./mocks/MockBravia";
import { isOutdatedBrowser } from "./components/video-playback";
import { getPlayerType } from "./misc/get-player-type";
import * as brightsign from "./platform-specific/brightsign";
import { checkForAppUpdates } from "./scap/utils";
import { shouldUseScapAPI } from "./stateMachines/PlaybackMachine";
import { noop } from "lodash";

console.log(
  `Build Date: ${preval`module.exports = new Date().toLocaleString();`}`
);

if (process.env.NODE_ENV !== "development") {
  LogRocket.init("5qbjuy/fugo-player-prod");
}

const MOCK_ANDROID_FUGO_API_FOR_NATIVE_VIDEO = false; // set 'true' to emulate Android (fire tv)
const MOCK_SONY_BRAVIA_API = false; // set MOCK_ANDROID_FUGO_API_FOR_NATIVE_VIDEO to true as well

if (MOCK_ANDROID_FUGO_API_FOR_NATIVE_VIDEO) {
  initFugoBridgeMock();
}
if (window.FugoBridge) {
  window.globalAndroidLogs = noop;
}
window.bravia = MOCK_SONY_BRAVIA_API
  ? new BraviaMock()
  : getPlayerType() === "bravia"
  ? new Bravia()
  : undefined;

const container = document.getElementById("root");
if (container === null) throw new Error("Root container missing in index.html");
const root = createRoot(container);
root.render(<App />);

if (shouldUseScapAPI()) {
  const ONE_HOUR = 1000 * 60 * 60;
  setInterval(checkForAppUpdates, ONE_HOUR);
  checkForAppUpdates();
}

// reload every 6 hours in order to fix possible memory leaks
let reloadIntervalHours = 6;
if (isOutdatedBrowser() || getPlayerType() === "brightsign") {
  reloadIntervalHours = 3;
}
setInterval(() => {
  if (getPlayerType() === "brightsign") {
    brightsign.reboot();
  } else if (window.bravia?.requestReboot) {
    window.bravia.requestReboot();
  } else {
    silentReload();
  }
  // in case the player doesn't restart, reload the page
  setTimeout(() => {
    console.error("Player didn't reload in time, reloading the page");
    window.location.reload();
  }, 1000 * 10);
}, reloadIntervalHours * 60 * 60 * 1000);

// restart the player at 5 in the morning
const RELOADING_HOUR = 5; // 5 a.m.
restartInMorning();

function restartInMorning() {
  const currentHour = new Date().getHours();
  scheduleReloading(currentHour);
}

function scheduleReloading(currentHour: number) {
  if (currentHour === RELOADING_HOUR) {
    setTimeoutInHours(restart, 24);
  } else if (currentHour < RELOADING_HOUR) {
    setTimeoutInHours(restart, RELOADING_HOUR - currentHour);
  } else {
    setTimeoutInHours(restart, 24 - currentHour + RELOADING_HOUR);
  }
}

function restart() {
  if (window.bravia?.requestReboot) {
    window.bravia.requestReboot();
  } else if (window.FugoBridge) {
    window.FugoBridge.restart();
    window.location.reload();
  } else if (window.b2bapis) {
    window.b2bapis.b2bcontrol.rebootDevice(
      () => console.log("Rebooting SSSP"),
      (err) => console.error(err)
    );
  } else if (getPlayerType() === "brightsign") {
    brightsign.reboot();
  } else {
    silentReload();
  }
  // in case the player doesn't restart, reload the page
  setTimeout(() => {
    console.error("Player didn't restart in time, reloading the page");
    window.location.reload();
  }, 1000 * 10);
}

function setTimeoutInHours(fn: () => void, hours: number) {
  setTimeout(fn, hoursToMs(hours));
}

function hoursToMs(hours: number) {
  return hours * 60 * 60 * 1000;
}

runSafely(async function requestPersist() {
  if (!("storage" in navigator)) return;
  const persisted = await navigator.storage.persist();
  console.log(`Is data persistent: ${persisted}`);
});

runSafely(async function wakeLock() {
  if (!("wakeLock" in navigator)) return;
  // @ts-expect-error
  await navigator.wakeLock.request("screen");
  console.log("Wake lock is granted");
});

runSafely(function requestKeepAwake() {
  if (typeof window?.chrome?.power?.requestKeepAwake !== "function") return;
  chrome.power.requestKeepAwake("display");
});

async function runSafely(cb: Function) {
  try {
    await cb();
  } catch (e) {
    console.error(e);
  }
}
