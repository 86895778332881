import styles from "./index.module.css";
import { MouseEventHandler, ReactNode, useCallback, useState } from "react";
import { PlayerEvent } from "../../stateMachines/PlayerMachine";

function HelpOverlay({ send, children }: Props) {
  const [isVisible, setIsVisible] = useState(false);

  const handleReset = useCallback(() => {
    send("PLAYER_UNREGISTERED");
  }, [send]);

  const handleClose = useCallback<MouseEventHandler>((e) => {
    e.preventDefault();
    setIsVisible(false);
  }, []);

  const handleSettingsClick = useCallback(() => setIsVisible(true), []);

  const helpOverlayStyle = { display: isVisible ? "flex" : "none" };

  return (
    <>
      {children}
      <img
        className={styles.cogIcon}
        src="./cog-icon.svg"
        alt="Settings"
        onClick={handleSettingsClick}
      />
      <div className={styles.root} style={helpOverlayStyle}>
        <div>
          <button className={styles.close} onClick={handleClose}>
            ×
          </button>
          <h1>Unpairing from the CMS</h1>
          <ol>
            <li>
              Head over to{" "}
              <a target="_blank" rel="noreferrer" href="https://fugo.ai/app">
                fugo.ai/app
              </a>{" "}
              and log in to your account
            </li>
            <li>Navigate to the Screens page in the top navigation bar</li>
            <li>
              Remove the screen by clicking on the three-dot button and
              selecting "Remove"
            </li>
          </ol>
          <h1>Guide links</h1>
          <ul>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.fugo.ai/en/articles/5842342-fugo-pin-play-setup-guide"
              >
                Pairing
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.fugo.ai/en/articles/5842728-how-to-install-fugo-on-your-chrome-browser"
              >
                Chrome Extension
              </a>
            </li>
          </ul>
          <button className={styles.reset} onClick={handleReset}>
            Reset the player
          </button>
        </div>
      </div>
    </>
  );
}

interface Props {
  send: (event: PlayerEvent["type"]) => void;
  children: ReactNode;
}

export default HelpOverlay;
