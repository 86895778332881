import { useCallback, useEffect, useRef, useState } from "react";
import { Size } from "./Size";

export const useSize = (): [Size, (elem: HTMLElement | null) => void] => {
  const [size, setSize] = useState<Size>({ width: 0, height: 0 });
  const elemRef = useRef<HTMLElement | null>();
  const updateSize = useCallback(() => {
    const elem = elemRef.current;
    if (!elem) {
      return;
    }
    setSize((size) => {
      if (
        elem &&
        (elem.offsetWidth !== size.width || elem.offsetHeight !== size.height)
      ) {
        return { width: elem.offsetWidth, height: elem.offsetHeight };
      }
      return size;
    });
  }, []);
  const ref = (elem: HTMLElement | null) => {
    elemRef.current = elem;
    updateSize();
  };
  useEffect(() => {
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, [updateSize]);
  return [size, ref];
};
