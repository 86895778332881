import { Machine, sendParent, assign } from "xstate";
import { fetchMachine } from "./FetchMachine";
import { pullPendingCommands } from "../graphql/PullSettings";
import { commandSuccess } from "../graphql/CommandSuccess";
import { commandFailed } from "../graphql/CommandFailed";
import { BrightnessSchedule } from "./BrightnessMachine";
import { VideoCodec } from "../misc/get-supported-video-codecs";
import { DateTimeSource } from "../TimeUtils";
import { AndroidScreenshotMethod } from "./ScreenshotMachine";
import { ImageFormat } from "../misc/get-supported-image-format";
import { PowerSavingSchedule } from "./PowerSavingMachine";
import { CastingInstructionsOptions, WifiInstructions } from "./PlayerMachine";
import { DocumentFormat } from "../misc/get-supported-document-format";

const settingKeysValues = [
  "interleaved",
  "screenOrientation",
  "autostart",
  "kiosk",
  "videoPreferences",
  "videoCodec",
  "imageFormat",
  "documentFormat",
  "originalFormat",
  "isDisplayOn",
  "brightnessSchedule",
  "powerSavingSchedule",
  "dateTimeSource",
  "isSurface",
  "isFlickeringWorkaroundEnabled",
  "isScreenshotEnabled",
  "shouldHTMLScreenshotForced",
  "screenshotInterval",
  "isVideoPrerenderingEnabled",
  "androidScreenshotMethod",
  "isTestScreen",
  "password",
  "shouldStoreMediaInIndexedDB",
  "isCastingEnabled",
  "isTransitionAnimationEnabled",
  "mediaCacheUrl",
  "isRemoteLogsEnabled",
  "isCacheAPIForced",
  "shouldDisplayCastingInstructions",
  "wifiInstructions",
  "castingInstructionsOptions",
] as const;
type SettingKeysTypes = typeof settingKeysValues[number];
type SettingTypes = {
  interleaved: boolean;
  screenOrientation: number;
  autostart: boolean;
  kiosk: boolean;
  videoPreferences: "native" | "html";
  videoCodec: VideoCodec;
  imageFormat: ImageFormat;
  documentFormat: DocumentFormat;
  originalFormat: boolean;
  isDisplayOn: boolean;
  brightnessSchedule: BrightnessSchedule;
  powerSavingSchedule: PowerSavingSchedule;
  dateTimeSource: DateTimeSource;
  isSurface: boolean;
  isFlickeringWorkaroundEnabled: boolean;
  isScreenshotEnabled: boolean;
  shouldHTMLScreenshotForced: boolean;
  screenshotInterval: number;
  isVideoPrerenderingEnabled: boolean;
  androidScreenshotMethod: AndroidScreenshotMethod;
  isTestScreen: boolean;
  password: string;
  shouldStoreMediaInIndexedDB: boolean;
  isCastingEnabled: boolean;
  isTransitionAnimationEnabled: boolean;
  mediaCacheUrl: string;
  isRemoteLogsEnabled: boolean;
  isCacheAPIForced: boolean;
  shouldDisplayCastingInstructions: boolean;
  wifiInstructions: WifiInstructions;
  castingInstructionsOptions: CastingInstructionsOptions;
};
type SettingCommands<T extends SettingKeysTypes> = {
  playerSetting: {
    [key in T]: SettingTypes[T];
  };
  commandType: T;
};
function isSettingsCommand(
  command: Command
): command is SettingCommands<SettingKeysTypes> {
  return settingKeysValues.includes(command.commandType as any);
}

type Command =
  | RestartCommand
  | OpenGooglePlayCommand
  | SettingCommands<SettingKeysTypes>;

interface RestartCommand {
  commandType: "reset";
}

interface OpenGooglePlayCommand {
  commandType: "openGooglePlay";
}

interface CommandsMachineContext {
  playerId: string;
  tenantId: string;
  pendingCommands: Command[];
  processedCommandResult: string;
}

export type UpdateSettingsEvent = {
  type: "UPDATE_SETTINGS";
  settings: { [key in SettingKeysTypes]: SettingTypes[SettingKeysTypes] };
};

export type CommandRestartEvent = {
  type: "COMMAND_RESTART";
};

export type OpenGooglePlayEvent = {
  type: "OPEN_GOOGLE_PLAY";
};

const getCommandResponseMessage = (commandResult: String) => {
  if (commandResult === "success") {
    // To omit not needed message field
    return undefined;
  }
  if (commandResult === "failed") {
    return "Command failed";
  }
  if (commandResult === "unknown") {
    return "Command is unknown";
  }

  console.error("Wrong command result value.");
  return undefined;
};

export const commandsMachine = Machine<CommandsMachineContext>(
  {
    context: {
      playerId: "",
      tenantId: "",
      pendingCommands: [],
      processedCommandResult: "",
    },
    initial: "fetch",
    on: {
      UPDATE_SETTINGS: {
        target: "responseOnCommand",
        actions: "commandWasSuccessful",
      },
      COMMAND_RESTART: {
        target: "responseOnCommand",
        actions: "commandWasSuccessful",
      },
    },
    states: {
      idle: {
        after: { PLAYER_FETCH_PENDING_COMMANDS_DELAY: "fetch" },
      },
      fetch: {
        invoke: {
          id: "fetchPendingCommands",
          src: fetchMachine,
          data: (context) => ({
            url: `${process.env.REACT_APP_API_HOST}/player/player`,
            requestBody: {
              query: pullPendingCommands,
              variables: {
                tenantId: context.tenantId,
                playerId: context.playerId,
              },
            },
          }),
          onDone: {
            target: "checkIfHaveCommandsToProcess",
            actions: "savePendingCommands",
          },
          onError: {
            target: "idle",
            actions: "consoleError",
          },
        },
      },
      responseOnCommand: {
        invoke: {
          id: "responseOnCommand",
          src: fetchMachine,
          data: (context) => ({
            url: `${process.env.REACT_APP_API_HOST}/player/player`,
            requestBody: {
              query:
                context.processedCommandResult === "success"
                  ? commandSuccess
                  : commandFailed,
              variables: {
                tenantId: context.tenantId,
                playerId: context.playerId,
                commandType: context.pendingCommands[0].commandType,
                message: getCommandResponseMessage(
                  context.processedCommandResult
                ),
              },
            },
          }),
          onDone: {
            target: "checkIfHaveCommandsToProcess",
            actions: "shiftPendingCommand",
          },
        },
      },
      checkIfHaveCommandsToProcess: {
        always: [
          {
            target: "idle",
            cond: "noPendingCommandToProcess",
          },
          {
            target: "responseOnCommand",
            cond: "pendingCommandHaveUnknownType",
            actions: "commandWasUnknown",
          },
          {
            target: "idle",
            actions: "processPendingCommand",
          },
        ],
      },
    },
  },
  {
    actions: {
      consoleError: (_context, event: any) => console.error(event.data),
      savePendingCommands: assign((_context, event: any) => ({
        pendingCommands: event.data.body
          ? event.data.body.data.pendingCommands
          : [],
      })),
      commandWasSuccessful: assign((_context) => ({
        processedCommandResult: "success",
      })),
      commandWasUnknown: assign((_context) => ({
        processedCommandResult: "unknown",
      })),
      processPendingCommand: sendParent((context: CommandsMachineContext) => {
        const command: Command = context.pendingCommands[0];
        if (isSettingsCommand(command)) {
          const settingCommand = command as SettingCommands<SettingKeysTypes>;
          return {
            type: "UPDATE_SETTINGS",
            settings: settingCommand.playerSetting,
          } as UpdateSettingsEvent;
        } else if (command.commandType === "reset") {
          return {
            type: "COMMAND_RESTART",
          };
        } else if (command.commandType === "openGooglePlay") {
          return {
            type: "OPEN_GOOGLE_PLAY",
          };
        }

        return { type: "COMMAND_UNKNOWN" };
      }),
      shiftPendingCommand: assign((context: CommandsMachineContext) => ({
        processedCommandResult: "",
        pendingCommands: context.pendingCommands.splice(1),
      })),
    },
    guards: {
      noPendingCommandToProcess: (context) =>
        context.pendingCommands.length === 0,
      pendingCommandHaveUnknownType: (context) => {
        const COMMANDS_TYPES = [
          "screenOrientation",
          "reset",
          "interleaved",
          "autostart",
          "kiosk",
          "videoPreferences",
          "videoCodec",
          "imageFormat",
          "documentFormat",
          "originalFormat",
          "isDisplayOn",
          "brightnessSchedule",
          "powerSavingSchedule",
          "dateTimeSource",
          "isSurface",
          "isFlickeringWorkaroundEnabled",
          "isScreenshotEnabled",
          "shouldHTMLScreenshotForced",
          "isVideoPrerenderingEnabled",
          "screenshotInterval",
          "androidScreenshotMethod",
          "isTestScreen",
          "password",
          // "openGooglePlay",
          "mediaCacheUrl",
          "shouldStoreMediaInIndexedDB",
          "isCastingEnabled",
          "isTransitionAnimationEnabled",
          "isRemoteLogsEnabled",
          "isCacheAPIForced",
          "shouldDisplayCastingInstructions",
          "wifiInstructions",
          "castingInstructionsOptions",
        ];
        const { commandType } = context.pendingCommands[0];
        return !COMMANDS_TYPES.find((type) => type === commandType);
      },
    },
    delays: {
      PLAYER_FETCH_PENDING_COMMANDS_DELAY: 30 * 1000,
    },
  }
);
