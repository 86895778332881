import { useActor } from "@xstate/react";
import { State } from "xstate";
import { ActorRef } from "xstate/lib/types";
import { DashboardMachineContext } from "../../stateMachines/PlaybackMachine";
import { Image } from "../image";
import styles from "./index.module.css";
import PlayerDashboard from "./player-dashboard";

export default function Dashboard(props: Props) {
  const [state] = useActor<any, State<DashboardMachineContext>>(props.actor);
  if (state.context.config.localIp) {
    if (state.context.config.localIp === "192.168.1.1:1001" && props.tenantId) {
      return <PlayerDashboard state={state} tenantId={props.tenantId} />;
    } else {
      return <OnPremDashboard state={state} />;
    }
  } else {
    return <CloudDashboard state={state} />;
  }
}

function OnPremDashboard({ state }: { state: State<DashboardMachineContext> }) {
  if (state.matches("loadingCertificatesFromCache")) {
    return <Status>Loading certificates</Status>;
  }

  if (state.matches("downloadingNewCertificates")) {
    return <Status>Downloading certificates</Status>;
  }

  if (state.matches("connectingWT")) {
    return <Status>Connecting Web Transport</Status>;
  }

  if (state.matches("requestingURL")) {
    return <Status>Getting Dashboard URL</Status>;
  }

  if (state.matches("gettingImage")) {
    return <Status>Downloading image</Status>;
  }

  if (state.matches("errored")) {
    return (
      <Status>
        <pre>{state.context.errorMessage}</pre>
      </Status>
    );
  }

  console.log(state.value);

  return (
    <div className={styles.fullScreen}>
      <Image
        orientation={state.context.config.orientation}
        fit={state.context.config.fit}
        src={state.context.screenshotUrl}
        onLoad={() => {}}
      />
    </div>
  );
}

function CloudDashboard({ state }: { state: State<DashboardMachineContext> }) {
  if (state.matches("loading.loading") && state.matches("loading.idle")) {
    return <div style={{ color: "gray" }}>Loading</div>;
  }

  if (state.matches("displaying.playing.error")) {
    return <pre style={{ color: "gray" }}>{state.context.errorMessage}</pre>;
  }

  if (state.matches("displaying.playing.error.retrying")) {
    return <div className={styles.fullScreen}>Re-fetching dashboard</div>;
  }

  if (state.matches("loading.loading")) {
    return (
      <div className={styles.fullScreen} style={{ color: "darkgray" }}>
        Getting dashboard URL
      </div>
    );
  }

  return (
    <div className={styles.fullScreen}>
      <Image
        orientation={state.context.config.orientation}
        fit={state.context.config.fit}
        src={state.context.screenshotUrl}
        onLoad={() => {}}
      />
    </div>
  );
}

export function Status({ children }: { children: React.ReactNode }) {
  return <div style={{ color: "gray" }}>{children}</div>;
}

type Props = {
  actor: ActorRef<any>;
  screenOrientation: number;
  tenantId?: string;
};
