import { Machine, assign } from "xstate";
import getChromeExtensionId from "../misc/get-chrome-extension-id";
import { fetchMachine } from "./FetchMachine";

interface Context {
  tenantId: string;
  playerId: string;
  lastPullTimestamp: number;
  lastCommandSyncTimestamp: number;
  memory: HardwareResource;
  disk: HardwareResource;
  cpu: number;
  temperature: string;
  networkType: string;
  signalStrength: string;
  isEnergySaverEnabled?: boolean;
}
type Event = never;

export const initialContext = {
  tenantId: "",
  playerId: "",
  lastPullTimestamp: Date.now(),
  lastCommandSyncTimestamp: Date.now(),
  memory: { used: 0, total: 0 },
  disk: { used: 0, total: 0 },
  cpu: 0,
  temperature: "0",
  networkType: "UNKNOWN",
  signalStrength: "0",
  isEnergySaverEnabled: undefined,
};

export const metricsMachine = Machine<Context, Event>(
  {
    id: "MetricsMachine",
    context: initialContext,
    initial: "sendingMetrics",
    states: {
      idle: {
        after: {
          30000: "sendingMetrics",
        },
        invoke: [
          {
            id: "updateMemory",
            src: "updateMemory",
            onDone: {
              actions: assign({
                memory: (_context: any, event: { data: HardwareResource }) =>
                  event.data || initialContext.memory,
              }),
            },
          },
          {
            id: "updateDisk",
            src: "updateDisk",
            onDone: {
              actions: assign({
                disk: (_context: any, event: { data: HardwareResource }) =>
                  event.data || initialContext.disk,
              }),
            },
          },
          {
            id: "updateCPU",
            src: "updateCPU",
            onDone: {
              actions: assign({
                cpu: (_context: any, event: { data: number }) =>
                  event.data || initialContext.cpu,
              }),
            },
          },
          {
            id: "updateTemperature",
            src: "updateTemperature",
            onDone: {
              actions: assign({
                temperature: (_context: any, event: { data: string }) =>
                  event.data || initialContext.temperature,
              }),
            },
          },
          {
            id: "updateNetworkType",
            src: "updateNetworkType",
            onDone: {
              actions: assign({
                networkType: (_context: any, event: { data: string }) =>
                  event.data || initialContext.networkType,
              }),
            },
          },
          {
            id: "updateSignalStrength",
            src: "updateSignalStrength",
            onDone: {
              actions: assign({
                signalStrength: (_context: any, event: { data: string }) =>
                  event.data || initialContext.signalStrength,
              }),
            },
          },
          {
            id: "updateEnergySaverStatus",
            src: "updateEnergySaverStatus",
            onDone: {
              actions: assign({
                isEnergySaverEnabled: (
                  _context: any,
                  event: { data: boolean }
                ) => event.data || initialContext.isEnergySaverEnabled,
              }),
            },
          },
        ],
      },
      sendingMetrics: {
        invoke: {
          id: "sendingMetricsFetch",
          src: fetchMachine,
          data: (context: Context) => ({
            url: `${
              process.env.REACT_APP_MONITORING_URL
                ? process.env.REACT_APP_MONITORING_URL
                : "/monitoring"
            }`,
            requestBody: {
              query: `mutation recordPlayerMetrics($tenantId: String!, $playerId: String!, $lastPullTimestamp: Long!, $lastCommandSyncTimestamp: Long!, $cpuUsage: Float!, $diskUsage: HardwareResourceInput!, $memory: HardwareResourceInput!, $temperature: String!, $networkType: String!, $signalStrength: String, $extra: Json){
                recordPlayerMetrics(tenantId: $tenantId, playerId: $playerId, lastPullTimestamp: $lastPullTimestamp, lastCommandSyncTimestamp: $lastCommandSyncTimestamp, cpuUsage: $cpuUsage, diskUsage: $diskUsage, memory: $memory, temperature: $temperature, networkType: $networkType, signalStrength: $signalStrength, extra: $extra)
              }`,
              variables: {
                tenantId: context.tenantId,
                playerId: context.playerId,
                lastPullTimestamp: context.lastPullTimestamp,
                lastCommandSyncTimestamp: context.lastCommandSyncTimestamp,
                cpuUsage: context.cpu,
                diskUsage: context.disk,
                memory: context.memory,
                temperature: context.temperature,
                networkType: context.networkType,
                signalStrength: context.signalStrength,
                extra: {
                  isEnergySaverEnabled: context.isEnergySaverEnabled,
                },
              },
            },
          }),
          onDone: "idle",
          onError: "idle",
        },
      },
    },
  },
  {
    services: {
      updateCPU: () => createMetricResolver("getCPU"),
      updateMemory: () => createMetricResolver("getMemory"),
      updateDisk: () => createMetricResolver("getDisk"),
      updateTemperature: () => createMetricResolver("getTemperature"),
      updateNetworkType: () => createMetricResolver("getNetworkType"),
      updateSignalStrength: () => createMetricResolver("getSignalStrength"),
      updateEnergySaverStatus: () =>
        createMetricResolver("isEnergySaverEnabled"),
    },
  }
);

function createMetricResolver(method: string): Promise<any> {
  return new Promise((resolve) => {
    if (method === "getDisk" && navigator?.storage?.estimate) {
      navigator.storage.estimate().then((estimate) => {
        resolve({
          used: estimate.usage,
          total: estimate.quota,
        });
      });
      return;
    }
    const chromeExtensionId = getChromeExtensionId();
    // @ts-expect-error
    if (window.FugoBridge && window.FugoBridge[method]) {
      console.log(`FugoBridge.${method}()`);
      try {
        // @ts-expect-error
        const result = window.FugoBridge[method]();
        resolve(JSON.parse(result));
      } catch (e) {
        console.error(e);
        resolve(null);
      }
    } else if (
      typeof window?.chrome?.runtime?.sendMessage === "function" &&
      chromeExtensionId
    ) {
      chrome.runtime.sendMessage(
        chromeExtensionId,
        {
          type: method,
        },
        {},
        (data: any) => {
          if (chrome.runtime.lastError) {
            resolve(null);
            return;
          }
          resolve(data);
        }
      );
    } else if (window.tizen) {
      const errCb = (err: any) => {
        console.error(err);
        resolve(null);
      };
      if (method === "getMemory") {
        const { systeminfo } = window.tizen;
        const total = systeminfo.getTotalMemory();
        const used = total - systeminfo.getAvailableMemory();
        resolve({ used, total });
        return;
      } else if (method === "getCPU") {
        window.tizen.systeminfo.getPropertyValue(
          "CPU",
          (info) => {
            resolve(info.load);
          },
          console.error
        );
      } else if (method === "getDisk") {
        window.tizen.systeminfo.getPropertyValue(
          "STORAGE",
          (info) => {
            const total = info.units[0]?.capacity;
            const used = total - info.units[0]?.availableCapacity;
            resolve({ used, total });
          },
          console.error
        );
      } else if (method === "getTemperature") {
        const temperature = window.b2bapis?.b2bcontrol.getCurrentTemperature();
        resolve(`${temperature}` || null);
      } else if (method === "getNetworkType") {
        window.tizen.systeminfo.getPropertyValue(
          "NETWORK",
          (info) => {
            resolve(`${info.networkType}` || null);
          },
          errCb
        );
      } else if (method === "getSignalStrength") {
        window.tizen.systeminfo.getPropertyValue(
          "WIFI_NETWORK",
          (info) => resolve(`${info.signalStrength}` || null),
          console.error
        );
      } else {
        resolve(null);
      }
    } else {
      resolve(null);
    }
  });
}

interface HardwareResource {
  used: number;
  total: number;
}
