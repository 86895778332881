import moment from "moment-timezone";
import { assign } from "xstate";

export interface TimeData {
  timeDiffInSeconds: number;
  zoneName: string;
}

export const initialTimeData: TimeData = {
  timeDiffInSeconds: 0,
  zoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
};

export type UpdateTimeEvent = {
  type: "UPDATE_TIME";
  timeData: TimeData;
};

export type DateTimeSource = {
  type: "auto" | "device";
};

export const updateTime = assign<any, UpdateTimeEvent>((_context, event) => ({
  timeData: event.timeData,
})) as any;

export function getCurrentTime(
  { timeDiffInSeconds, zoneName }: TimeData,
  dateTimeSource: DateTimeSource
): moment.Moment {
  if (dateTimeSource.type === "device") {
    return moment();
  }
  return moment.tz(timeDiffInSeconds * 1000 + Date.now(), zoneName);
}

export function getDefaultDateTimeSource(): DateTimeSource {
  return { type: "auto" };
}
