import { Store } from "idb-keyval";

export function mediaStore(suffix: string) {
  const key = `mediaStore${suffix}`;
  return new Store(key, key);
}

export function mediaContentStore(suffix: string = "Global") {
  const key = `mediaContent${suffix}`;
  return new Store(key, key);
}

export const mediaCacheName = "fugo-files";
