import { useCallback, useEffect } from "react";
import styles from "./index.module.css";
import {
  PlaybackMachineContext,
  PlaybackScreen,
} from "../../stateMachines/PlaybackMachine";
import { Settings } from "../../stateMachines/PlayerMachine";
import { VideoPlaybackComponent as Video } from "../video-playback";
import StaticApp from "../app/static";
import { FormattedMedia } from "../../generated/router";
import DynamicApp, { ProtocolHeaders } from "../app/dynamic";
import { Audio } from "../audio";
import { Content } from "../content";
import { Image } from "../image";
import { Shape } from "../shape";
import { Container } from "../container";
import { Frame } from "../frame";
import VideoExternal from "../video-external";
import Dashboard from "../dashboard";
import { TestScreenImage } from "./TestScreenImage";
import { Document } from "../document";
import { getPlayerType } from "../../misc/get-player-type";
import { setHealthy } from "../../misc/healthCheck";

export function Playback(props: Props) {
  useEffect(() => {
    setHealthy();
  }, []);

  if (!props.playbackContext.screens) {
    return <Blank onLoad={() => {}} />;
  }

  const isInfinite =
    props.playbackState.matches("playback.playingA.infinite.idle") ||
    props.playbackState.matches("playback.playingB.infinite.idle");

  const shouldBePlayingA = props.playbackState.matches("playback.playingA");
  const a =
    shouldBePlayingA ||
    props.playbackState.matches("playback.transitioningToA");

  const shouldBePlayingB = props.playbackState.matches("playback.playingB");
  const b =
    shouldBePlayingB ||
    props.playbackState.matches("playback.transitioningToB");
  const headers: ProtocolHeaders = {
    tenantId: props.tenantId,
    teamId: props.playbackContext.playlists[0]?.teamId,
  };

  const playerType = getPlayerType();
  const hasVideoBug =
    playerType === "amazon" || playerType === "consumer-samsung";

  return (
    <div
      className={`${styles.fullScreen} ${
        !hasVideoBug ? styles.brightness : ""
      }`}
    >
      <div className={styles.fullScreen} style={{ opacity: a ? 1 : 0 }}>
        <Screen
          key={props.playbackContext.screens[0].id}
          value={props.playbackContext.screens[0] ?? null}
          send={props.send}
          settings={props.settings}
          shouldBePlaying={shouldBePlayingA}
          medias={props.medias}
          isInfinite={isInfinite}
          isFullScreen={true}
          headers={headers}
        />
      </div>
      <div className={styles.fullScreen} style={{ opacity: b ? 1 : 0 }}>
        <Screen
          key={props.playbackContext.screens[1]?.id}
          value={props.playbackContext.screens[1] ?? null}
          send={props.send}
          settings={props.settings}
          shouldBePlaying={shouldBePlayingB}
          medias={props.medias}
          isInfinite={isInfinite}
          isFullScreen={true}
          headers={headers}
        />
      </div>
      {props.settings.isTestScreen ? <TestScreenImage /> : null}
    </div>
  );
}

export function Screen({
  value,
  send,
  settings,
  shouldBePlaying,
  medias,
  isInfinite,
  isFullScreen,
  headers,
}: {
  value: PlaybackScreen;
  send: (type: any) => void;
  settings: Settings;
  shouldBePlaying: boolean;
  medias: FormattedMedia[];
  isInfinite: boolean;
  isFullScreen: boolean;
  headers: ProtocolHeaders;
}) {
  const onLoad = useCallback(
    () => send({ type: `LOADED-${value.id}`, component: value.type }),
    [send, value?.id, value?.type]
  );
  const onDone = useCallback(
    (error?: string) => {
      send({ type: `DONE-${value.id}`, component: value.type });

      if (error) {
        console.error(`Error playing video: ${error}`);
        send({
          type: "ERROR",
          payload: {
            error,
          },
        });
      }
    },
    [send, value?.id, value?.type]
  );

  if (!value) {
    return null;
  }

  if (value.type === "blank") {
    return <Blank key={value.id} onLoad={onLoad} />;
  } else if (value.type === "image") {
    const { media } = value.props;
    const source = settings.originalFormat
      ? media.originalUrl
      : media.formatUrl;
    return (
      <Image
        onLoad={onLoad}
        src={source}
        fit={value.props.config?.fit}
        isTinted={value.props.config?.isTinted}
        orientation={value.props.config?.orientation}
        mediaId={value.props.media.mediaId}
        colors={value.props.config?.colors}
      />
    );
  } else if (value.type === "video") {
    return (
      <Video
        key={value.id}
        uniqueId={value.id}
        media={value.props}
        onDone={onDone}
        onContentReady={onLoad}
        shouldBePlaying={shouldBePlaying}
        screenOrientation={settings.screenOrientation}
        shouldForceHtmlVideo={settings.videoPreferences === "html"}
        shouldPrerenderVideo={settings.isVideoPrerenderingEnabled ?? false}
        isInfinite={isInfinite}
        isFullscreen={isFullScreen}
        originalFormat={settings.originalFormat}
        isSurface={settings.isSurface ?? true}
        isFlickeringWorkaroundEnabled={
          settings.isFlickeringWorkaroundEnabled ?? false
        }
        isMuted={settings.isMuted || value.props.config?.isMuted === true}
        fit={value.props.config?.fit}
        shouldStoreMediaInIndexedDB={settings.shouldStoreMediaInIndexedDB}
        isCacheAPIForced={settings.isCacheAPIForced}
      />
    );
  } else if (value.type === "document") {
    return (
      <Document
        src={value.props.formatUrl}
        format={value.props.formatName}
        duration={value.props.duration}
        onContentReady={onLoad}
        onDone={onDone}
        isInfinite={isInfinite}
        shouldBePlaying={shouldBePlaying}
      />
    );
  } else if (value.type === "staticApp") {
    return (
      <StaticApp
        key={value.id}
        content={value.props}
        onContentReady={onLoad}
        medias={medias}
        headers={headers}
        shouldBePlaying={shouldBePlaying}
      />
    );
  } else if (value.type === "dynamicApp") {
    return (
      <DynamicApp
        key={value.id}
        content={value.props}
        onDone={onDone}
        onContentReady={onLoad}
        shouldBePlaying={shouldBePlaying}
        medias={medias}
        isInfinite={isInfinite}
        isFullScreen={isFullScreen}
        screenOrientation={settings.screenOrientation}
        headers={headers}
      />
    );
  } else if (value.type === "audio") {
    return (
      <Audio
        key={value.id}
        media={value.props}
        onDone={onDone}
        onContentReady={onLoad}
        shouldBePlaying={shouldBePlaying}
        duration={0}
        isInfinite={isInfinite}
      />
    );
  } else if (value.type === "content") {
    return (
      <Content
        key={value.id}
        shouldBePlaying={shouldBePlaying}
        isInfinite={isInfinite}
        value={value.props}
        actor={value.ref}
        send={send}
        settings={settings}
        medias={medias}
        headers={headers}
        isPortrait={
          settings.screenOrientation === 90 ||
          settings.screenOrientation === 270
        }
      />
    );
  } else if (value.type === "shape") {
    return <Shape shape={value.props} />;
  } else if (value.type === "container") {
    return (
      <Container
        key={value.id}
        shouldBePlaying={shouldBePlaying}
        isInfinite={isInfinite}
        value={value.props}
        actor={value.ref}
        send={send}
        settings={settings}
        medias={medias}
        headers={headers}
      />
    );
  } else if (value.type === "frame") {
    return (
      <Frame
        content={value.props}
        tileId={value.id}
        actor={value.ref}
        settings={settings}
        send={send}
        medias={medias}
        isInfinite={isInfinite}
        shouldBePlaying={shouldBePlaying}
        originalFormat={settings.originalFormat}
        headers={headers}
      />
    );
  } else if (value.type === "externalImageContent") {
    return (
      <Image
        onLoad={onLoad}
        src={value.props.mediaType.originalFormat.key}
        fit={value.props.config?.fit}
        isTinted={value.props.config?.isTinted}
        orientation={value.props.config?.orientation}
        colors={value.props.config?.colors}
      />
    );
  } else if (value.type === "externalVideoContent") {
    return (
      <VideoExternal
        onLoad={onLoad}
        onDone={onDone}
        src={value.props.mediaType.originalFormat.key}
        poster={value.props.mediaType.thumbnailPath}
        isInfinite={isInfinite}
        shouldBePlaying={shouldBePlaying}
        isMuted={settings.isMuted || value.props.config.isMuted === true}
        fit={value.props.config?.fit}
        duration={value.props.duration}
      />
    );
  } else if (value.type === "dashboard") {
    return (
      <Dashboard
        actor={value.ref}
        screenOrientation={settings.screenOrientation}
        tenantId={headers.tenantId}
      />
    );
  }

  console.log(
    `Not rendering anything because "${JSON.stringify(
      value
    )}" is unknown type of screen`
  );

  return null;
}

function Blank({ onLoad }: { onLoad: () => void }) {
  useEffect(() => {
    onLoad();
  }, [onLoad]);
  return (
    <div
      className={styles.fullScreen}
      style={{ backgroundColor: "black", display: "none" }}
    >
      blank
    </div>
  );
}

interface Props {
  playbackContext: PlaybackMachineContext;
  playbackState: any;
  send: (event: any) => void;
  settings: Settings;
  medias: FormattedMedia[];
  tenantId?: string;
  playerId?: string;
}
