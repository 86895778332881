import { useEffect, useState } from "react";
import { useDynamicRandom } from "./useDynamicRandom";

const DEFAULT_LEFT = 100;
const DEFAULT_TOP = 100;

type Range = {
  min: number;
  max: number;
};

type Bounds = {
  left: Range;
  top: Range;
};

export const useDynamicElement = (
  ref: React.RefObject<HTMLElement>,
  interval = 500
) => {
  const [bounds, setBounds] = useState<Bounds | null>(null);
  const left = useDynamicRandom(
    bounds?.left.min ?? DEFAULT_LEFT,
    bounds?.left.max ?? DEFAULT_LEFT,
    interval
  );
  const top = useDynamicRandom(
    bounds?.top.min ?? DEFAULT_TOP,
    bounds?.top.max ?? DEFAULT_TOP,
    interval
  );

  useEffect(() => {
    if (ref.current) {
      const width = ref.current.clientWidth;
      const height = ref.current.clientHeight;
      const browserWidth = window.innerWidth;
      const browserHeight = window.innerHeight;
      const horizontalMargin = (browserWidth / 100) * 5;
      const verticalMargin = (browserHeight / 100) * 5;
      const left = {
        min: horizontalMargin,
        max: browserWidth - width - horizontalMargin,
      };
      const top = {
        min: verticalMargin,
        max: browserHeight - height - verticalMargin,
      };
      setBounds({ left, top });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ref.current?.clientWidth,
    ref.current?.clientHeight,
    window.innerWidth,
    window.innerHeight,
  ]);

  return { left, top };
};
