import { ReactNode } from "react";
import { useSize } from "./useSize";
import { sizeFlip } from "./Size";
import styles from "./index.module.css";

export const Flip = ({ children }: { children: ReactNode }) => {
  const [size, ref] = useSize();
  return (
    <div className={styles.flipRoot} ref={ref}>
      <div className={styles.flipWrapper} style={sizeFlip(size)}>
        {children}
      </div>
    </div>
  );
};
