import { useEffect, useState } from "react";

const getRandomNumber = (from: number, to: number) =>
  Math.round(Math.random() * (to - from) + from);

export const useDynamicRandom = (from: number, to: number, interval = 500) => {
  const [value, setValue] = useState(getRandomNumber(from, to));
  useEffect(() => {
    const intervalId = setInterval(() => {
      setValue(getRandomNumber(from, to));
    }, interval);
    return () => clearInterval(intervalId);
  }, [from, to, interval]);
  return value;
};
