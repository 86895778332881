import { useEffect, useState } from "react";
import AmazonIAP, { Receipt, Product } from ".";
import { getPlayerType } from "../misc/get-player-type";
import styles from "./index.module.css";

const sku = "ai.fugo.subscriptions.1mo";
export default function useAmazonIAP() {
  const [error, setError] = useState("");
  const [isTrial, setIsTrial] = useState(false);
  const [product, setProduct] = useState<Product | null>(null);
  const processReceipt = async (receipt: Receipt) => {
    setIsTrial(false);
    await AmazonIAP.notifyFulfillment(receipt.receiptId, "FULFILLED");
  };

  const handleBuy = () => {
    (async function () {
      const resp = await AmazonIAP.purchase(sku);
      if (resp.requestStatus === "SUCCESSFUL") {
        await processReceipt(resp.receipt);
      } else if (resp.requestStatus === "ALREADY_PURCHASED") {
        getPurchaseUpdates();
      } else {
        setError(`An error has happened: ${resp.requestStatus}`);
      }
    })();
  };

  useEffect(() => {
    if (
      !window.FugoBridge ||
      !window.FugoBridge.amazonPurchase ||
      getPlayerType() !== "amazon" ||
      !isAmazonRelease()
    ) {
      setIsTrial(false);
      return;
    }
    getPurchaseUpdates();
    getUserData();
    getProductData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getPurchaseUpdates() {
    const resp = await AmazonIAP.getPurchaseUpdates(true);

    if (resp.REQUEST_STATUS === "FAILED") {
      return;
    }

    const receipts = resp.RECEIPTS.filter((receipt) => !receipt.endDate);
    if (receipts.length === 0) {
      setIsTrial(true);
      return;
    }
    receipts.forEach((receipt) => processReceipt(receipt));
    if (resp.HAS_MORE) {
      getPurchaseUpdates();
    }
  }

  async function getUserData() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const resp = await AmazonIAP.getUserData();
    // we don't store subscription state and use Amazon IAP as the single source of truth
    // so we don't need to reset out state when the user has changed
  }

  async function getProductData() {
    const resp = await AmazonIAP.getProductData([sku]);
    if (!resp.productData || Object.keys(resp.productData).length === 0) return;
    setProduct(Object.entries(resp.productData)[0][1]);
  }

  function TrialMessage() {
    if (!isTrial) return null;
    if (!product) return null; // user is logged out
    return (
      <div className={styles.trial}>
        <div>
          This is a free version of the app.
          <div>Please buy a subscription to hide this message.</div>
          <div> Thank you for choosing Fugo!</div>
        </div>
        <button onClick={handleBuy} autoFocus>
          Buy {product?.title} for {product?.price}
        </button>
        <div className={styles.error}>{error ? error : null}</div>
      </div>
    );
  }

  return { isTrial, product, handleBuy, TrialMessage };
}

export function isAmazonRelease() {
  const urlParams = new URLSearchParams(window.location.search);
  const amazonRelease = ~~(urlParams.get("amazonRelease") ?? 0);
  const isAmazonRelease = amazonRelease === 45;
  return isAmazonRelease;
}
