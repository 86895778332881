import { assign, DoneInvokeEvent, forwardTo, createMachine } from "xstate";
import { clear, get, set } from "idb-keyval";
import consolere from "console-remote-client";
import { fetchMachine } from "./FetchMachine";
import {
  generatePinMutation,
  makeGeneratePinVariables,
} from "../graphql/GeneratePinMutation";
import {
  completeRegistrationMutation,
  makeCompleteRegistrationVariables,
} from "../graphql/CompleteRegistrationMutation";
import { timeSyncMachine } from "./TimeSyncMachine";
import { pullMachine, UpdatedPulledDataEvent } from "./PullMachine";
import {
  CommandRestartEvent,
  commandsMachine,
  OpenGooglePlayEvent,
  UpdateSettingsEvent,
} from "./CommandsMachine";
import { playbackMachine, shouldUseScapAPI } from "./PlaybackMachine";
import {
  brightnessMachine,
  BrightnessSchedule,
  UpdateBrightnessEvent,
} from "./BrightnessMachine";
import {
  powerSavingMachine,
  EnablePowerSavingEvent,
  DisablePowerSavingEvent,
} from "./PowerSavingMachine";
import {
  DateTimeSource,
  getDefaultDateTimeSource,
  initialTimeData,
  TimeData,
  updateTime,
  UpdateTimeEvent,
} from "../TimeUtils";
import { checkGraphqlResponse } from "../graphql/Utils";
import { CurrentPlayback } from "../PlaybackData";
import { FormattedMedia, Playlist } from "../generated/router";
import {
  Player,
  PlayerMutation,
  PlayerMutationUpdateSettingsArgs,
} from "../generated/player";
import {
  metricsMachine,
  initialContext as initialMetricsContext,
} from "./MetricsMachine";
import { isSilentReload } from "../misc/silent-reload";
import { getPlayerType, PlayerType } from "../misc/get-player-type";
import notifySlack from "../misc/notifySlack";
import getDefaultVideoPlaybackType from "../misc/get-default-video-playback-type";
import { isOutdatedBrowser } from "../components/video-playback";
import { isAmazonRelease } from "../amazon-iap/use-amazon-iap";
import { log, LogLevel, setLogLevel } from "../misc/log";
import getSupportedVideoCodecs, {
  VideoCodec,
} from "../misc/get-supported-video-codecs";
import getDefaultVideoCodec from "../misc/get-default-video-codec";
import { getAPKVersion } from "../misc/get-apk-version";
import getChromeExtensionId from "../misc/get-chrome-extension-id";
import {
  AndroidScreenshotMethod,
  isNativeScreenshotSupported,
  screenshotMachine,
  screenshotMachineInitContext,
} from "./ScreenshotMachine";
import getDefaultImageFormat from "../misc/get-default-image-format";
import { ImageFormat } from "../misc/get-supported-image-format";
import { PowerSavingSchedule } from "./PowerSavingMachine";
import getDefaultMediaCacheUrl from "../misc/get-default-media-cache-url";
import throttle from "lodash/throttle";
import { getTimezone } from "../misc/get-player-timzeone";
import * as brightsign from "../platform-specific/brightsign";
import { writeToLGStorage, readFromLGStorage } from "../scap/utils";
import {
  castingMachine,
  StartCasting,
  StopCasting,
  StreamingError,
  UpdateCasting,
} from "./CastingMachine";
import { noop } from "lodash";
import { getLGDeviceInfo } from "../scap/utils";
import { castPinMachine, UpdateCastPinEvent } from "./CastPinMachine";
import { DocumentFormat } from "../misc/get-supported-document-format";
import { getDefaultDocumentFormat } from "../misc/get-default-document-format";

const isPlayerRegistered = (context: PlayerContext): boolean =>
  context.tenantId !== undefined;

const contextInitValue: PlayerContext = {
  pin: undefined,
  playerId: undefined,
  tenantId: undefined,
  viewerId: undefined,
  settings: getDefaultSettings(),
  pinTtl: Infinity,
  pinRetries: 0,
  lastError: undefined,
  timeData: initialTimeData,
  pullHash: undefined,
  playlists: [],
  medias: [],
  screenshotUrl: "",
  isAmazonRelease: isAmazonRelease(),
  currentBrightness: 1,
  outOfSyncSettings: {},
  dataSuffix: "",
  playerInfo: null,
  castPin: "",
};

const CHANNEL_SESSION_PASSWORD_KEY = "channelPassword";
export const createPlayerMachine = () =>
  createMachine(
    {
      preserveActionOrder: true,
      schema: {
        context: {} as PlayerContext,
        events: {} as PlayerEvent,
        services: {} as {
          channelCheck: {
            data: { playerId: string; tenantId: string; viewerId: string };
          };
          geoInfo: {
            data: GeoInfo;
          };
          isSilentReload: {
            data: boolean;
          };
          getContext: {
            data: PlayerContext;
          };
          gettingPin: {
            data: {
              body: { data: { generatePin: PlayerMutation["generatePin"] } };
            };
          };
          pair: {
            data: {
              body: {
                data: {
                  completeRegistration: PlayerMutation["completeRegistration"];
                };
              };
            };
          };
          updateSettingsInCMS: {
            data: {
              body: {
                data: { updateSettings: PlayerMutation["updateSettings"] };
              };
            };
          };
          updatingScreenshotUrl: {
            data: { body: { data: { getScreenshotUrl?: string } } };
          };
          updateCastingPin: {
            data: {
              body: {
                data: {
                  generateCastingPin: PlayerMutation["generateCastingPin"];
                };
              };
            };
          };
          checkSettings: {
            data: {
              body: {
                data: {
                  updateSettings: { settings: Player["settings"] };
                };
              };
            };
          };
          syncSettings: {
            data: {
              body: {
                data: {
                  updateSettings: { settings: Player["settings"] };
                };
              };
            };
          };
          updateInfo: {
            data: {
              body: {
                data: {
                  updateSettings: { settings: Player["info"] };
                };
              };
            };
          };
          loadDataSuffix: {
            data: string;
          };
          getSessionPassword: {
            data: string;
          };
          loadPlayerInfo: {
            data: Info;
          };
        },
      },
      tsTypes: {} as import("./PlayerMachine.typegen").Typegen0,
      initial: "loadDataSuffix",
      context: contextInitValue,
      on: {
        ERROR: {
          actions: "handleError",
        },
      },
      states: {
        loadDataSuffix: {
          invoke: {
            src: "loadDataSuffix",
            onDone: {
              actions: "saveDataSuffix",
              target: "channelCheck",
            },
            onError: {
              target: "channelCheck",
            },
          },
        },
        // save data from URL to DB so it skips pairing if this is a Channel
        channelCheck: {
          invoke: {
            src: "channelCheck",
            onDone: {
              actions: ["saveChannelInfo", "persist"],
              target: "launch",
            },
            onError: { target: "launch" },
          },
        },
        launch: {
          id: "launch",
          invoke: {
            src: "isSilentReload",
            onDone: [
              {
                cond: (_context, event) => event.data,
                target: "loadContext",
              },
              {
                target: "introVideo",
              },
            ],
          },
        },
        introVideo: {
          always: [
            {
              cond: "isAndroid",
              target: "loadContext",
            },
          ],
          after: {
            6000: "loadContext", // the video is 4 seconds long, so 2 seconds timeout just in case
          },
          on: {
            INTRO_VIDEO_FINISHED: "loadContext",
          },
        },
        loadContext: {
          invoke: {
            id: "getContext",
            src: "getContext",
            onDone: {
              target: "init",
              actions: "loadContext",
            },
            onError: {
              target: "fail",
            },
          },
        },
        init: {
          initial: "fetchPlayerInfo",
          states: {
            fetchPlayerInfo: {
              invoke: {
                src: "loadPlayerInfo",
                onDone: {
                  actions: "savePlayerInfo",
                  target: "playerInfoFetched",
                },
              },
            },
            playerInfoFetched: {
              always: [
                {
                  cond: "isPlayerUnregistered",
                  target: "#fetchPin",
                },
                {
                  cond: "isPlayerRegistered",
                  target: "#updatingScreenshotUrl",
                },
              ],
            },
          },
        },
        fetchPin: {
          id: "fetchPin",
          initial: "gettingGeoInfo",
          states: {
            gettingGeoInfo: {
              invoke: {
                id: "geoInfo",
                src: "geoInfo",
                onDone: [
                  {
                    actions: "saveGeoInfo",
                    target: "gettingPin",
                  },
                ],
                onError: { target: "#gettingPin" },
              },
            },
            gettingPin: {
              id: "gettingPin",
              invoke: {
                src: "gettingPin",
                data: {
                  url: `${process.env.REACT_APP_API_HOST}/player/player`,
                  requestBody: (context: PlayerContext) => ({
                    query: generatePinMutation,
                    variables: makeGeneratePinVariables(
                      "Android",
                      context.playerInfo!
                    ),
                  }),
                },
                onDone: [
                  {
                    target: "#pairing",
                    cond: "isFetchPinSuccessful",
                    actions: ["savePlayerData", "notifySlackNewPin"],
                  },
                  {
                    target: "#failFetchPin",
                    actions: "incrementPinRetries",
                  },
                ],
                onError: {
                  target: "#failFetchPin",
                  actions: "incrementPinRetries",
                },
              },
            },
          },
        },
        pairing: {
          id: "pairing",
          initial: "register",
          states: {
            idle: {
              after: {
                PLAYER_REGISTRATION_DELAY: "register",
              },
            },
            register: {
              invoke: {
                src: "pair",
                data: (context) => ({
                  url: `${process.env.REACT_APP_API_HOST}/player/player`,
                  requestBody: {
                    query: completeRegistrationMutation,
                    variables: makeCompleteRegistrationVariables(
                      context.playerId
                    ),
                  },
                }),
                onDone: [
                  {
                    target: "#paired",
                    cond: "isPlayerRegistrationSuccessful",
                    actions: ["saveRegistrationData", "persist"],
                  },
                  {
                    target: "idle",
                  },
                ],
                onError: "idle",
              },
            },
          },
          after: {
            PIN_TTL: "fetchPin",
          },
        },
        paired: {
          id: "paired",
          initial: "updateDefaultSettigs",
          states: {
            updateDefaultSettigs: {
              always: {
                actions: ["updateDefaultSettings", "persist"],
                target: "updateSettingsInCMS",
              },
            },
            updateSettingsInCMS: {
              invoke: {
                id: "setDefaultSettings",
                src: "updateSettingsInCMS",
                data: (context) =>
                  createUpdateSettingsData(context, {
                    newSettings: context.settings,
                  }),
                onDone: {
                  target: "#updatingScreenshotUrl",
                  actions: ["applySettings"],
                },
                onError: "#updatingScreenshotUrl",
              },
            },
          },
        },
        updatingScreenshotUrl: {
          id: "updatingScreenshotUrl",
          invoke: {
            id: "updatingScreenshotUrl",
            src: "updatingScreenshotUrl",
            data: (context) => ({
              url: `${
                process.env.REACT_APP_MONITORING_URL
                  ? process.env.REACT_APP_MONITORING_URL
                  : "/monitoring"
              }`,
              requestBody: {
                query: `
                  mutation getScreenshotUrl($tenantId: String!, $playerId: String!) {
                    getScreenshotUrl(tenantId: $tenantId, playerId: $playerId)
                  }
                `,
                variables: {
                  playerId: context.playerId,
                  tenantId: context.tenantId,
                },
              },
            }),
            onDone: [
              {
                target: "syncNewSettings",
                actions: "updatingScreenshotUrl",
              },
            ],
            onError: "syncNewSettings",
          },
        },
        syncNewSettings: {
          initial: "checking",
          states: {
            checking: {
              invoke: {
                src: "checkSettings",
                data: (context) => createUpdateSettingsData(context, {}),
                onDone: [
                  {
                    cond: "areSettingsOutOfSync",
                    target: "sync",
                    actions: "saveOutOfSyncSettings",
                  },
                  {
                    target: "#updateInfo",
                    actions: ["saveChannelPassword"],
                  },
                ],
                onError: "#updateInfo",
              },
            },
            sync: {
              invoke: {
                src: "syncSettings",
                data: (context) =>
                  createUpdateSettingsData(context, {
                    newSettings: context.outOfSyncSettings,
                  }),
                onDone: {
                  target: "#updateInfo",
                  actions: ["clearOutOfSyncSettings", "saveChannelPassword"],
                },
                onError: "#updateInfo",
              },
            },
          },
        },
        updateInfo: {
          id: "updateInfo",
          invoke: {
            src: "updateInfo",
            data: (context) =>
              createUpdateSettingsData(context, {
                newInfo: context.playerInfo,
              }),
            onDone: { target: "checkPassword" },
            onError: { target: "checkPassword" },
          },
        },
        checkPassword: {
          id: "checkPassword",
          initial: "init",
          states: {
            init: {
              always: [
                {
                  cond: "isPasswordRequired",
                  target: "checkingSessionPassword",
                },
                {
                  target: "#playback",
                },
              ],
            },
            checkingSessionPassword: {
              invoke: {
                src: "getSessionPassword",
                onDone: [
                  {
                    cond: "isSessionPasswordCorrect",
                    target: "#playback",
                  },
                  {
                    target: "waitingForPassword",
                  },
                ],
              },
            },
            waitingForPassword: {
              initial: "idle",
              on: {
                PASSWORD_SUBMIT: {
                  actions: ["saveSubmittedPassword"],
                  target: ".checkSubmittedPassword",
                },
              },
              states: {
                idle: {},
                checkSubmittedPassword: {
                  always: [
                    {
                      cond: "isSubmittedPasswordCorrect",
                      actions: ["saveSubmittedPasswordInSession"],
                      target: "#playback",
                    },
                    {
                      target: "submittedPasswordIsIncorrect",
                    },
                  ],
                },
                submittedPasswordIsIncorrect: {
                  after: {
                    CLEAR_PASSWORD_ERROR_TIMEOUT: "idle",
                  },
                  on: {
                    CLEAR_PASSWORD_ERROR: {
                      target: "idle",
                    },
                  },
                },
              },
            },
          },
        },
        playback: {
          id: "playback",
          entry: ["startRemoteLogsIfEnabled"],
          activities: ["trackView", "sendingHeartbeat"],
          invoke: [
            {
              src: timeSyncMachine,
            },
            {
              id: "pullMachine",
              src: "pullMachine",
              data: (context) => ({
                playerId: context.playerId,
                tenantId: context.tenantId,
                // must be empty so the router can return Unpaid
                pullHash: "",
                mediaCacheUrl: context.settings.mediaCacheUrl,
                timeData: context.timeData,
                screenOrientation: context.settings.screenOrientation,
                videoCodec: context.settings.videoCodec,
                imageFormat: context.settings.imageFormat,
                documentFormat: context.settings.documentFormat,
                dateTimeSource: context.settings.dateTimeSource,
                playlists: context.playlists,
              }),
            },
            {
              id: "commandsMachine",
              src: "commandsMachine",
              data: (context) => ({
                playerId: context.playerId,
                tenantId: context.tenantId,
              }),
            },
            {
              id: "playbackMachine",
              src: "playbackMachine",
              autoForward: true,
              data: (context) => ({
                playlists: context.playlists,
                medias: context.medias,
                timeData: context.timeData,
                playbackData: {
                  currentPlayback: 0,
                  playbackA: undefined,
                  playbackB: undefined,
                },
                interleaveContent: context.settings.interleaved,
                videoPreference: context.settings.videoPreferences,
                isVideoPrerenderingEnabled:
                  context.settings.isVideoPrerenderingEnabled,
                isTransitionAnimationEnabled:
                  context.settings.isTransitionAnimationEnabled,
                originalFormat: context.settings.originalFormat,
                dateTimeSource: context.settings.dateTimeSource,
                dataSuffix: context.dataSuffix,
                retryCount: 0,
                tenantId: context.tenantId,
                shouldStoreMediaInIndexedDB:
                  context.settings.shouldStoreMediaInIndexedDB,
                isCacheAPIForced: context.settings.isCacheAPIForced,
                shouldDisplayCastingInstructions:
                  context.settings.shouldDisplayCastingInstructions,
                wifiInstructions: context.settings.wifiInstructions,
                castingInstructionsOptions:
                  context.settings.castingInstructionsOptions,
                castPin: context.castPin,
                shouldHTMLScreenshotForced:
                  context.settings.shouldHTMLScreenshotForced,
                isScreenshotEnabled: context.settings.isScreenshotEnabled,
              }),
            },
            {
              src: metricsMachine,
              data: (context) => ({
                ...initialMetricsContext,
                playerId: context.playerId,
                tenantId: context.tenantId,
                lastPullTimestamp: Date.now(),
                lastCommandSyncTimestamp: Date.now(),
              }),
            },
            {
              id: "brightnessMachine",
              src: "brightnessMachine",
              data: (context) => ({
                brightnessSchedule: context.settings.brightnessSchedule,
              }),
            },
            {
              id: "powerSavingMachine",
              src: "powerSavingMachine",
              data: (context) => ({
                powerSavingSchedule: context.settings.powerSavingSchedule,
              }),
            },
            {
              id: "screenshotMachine",
              src: "screenshotMachine",
              data: (context) => ({
                ...screenshotMachineInitContext,
                url: context.screenshotUrl,
                isScreenshotEnabled: context.settings.isScreenshotEnabled,
                screenshotInterval: context.settings.screenshotInterval,
                androidScreenshotMethod:
                  context.settings.androidScreenshotMethod,
                shouldHTMLScreenshotForced:
                  context.settings.shouldHTMLScreenshotForced,
              }),
            },
            {
              id: "castingMachine",
              src: "castingMachine",
              data: (context) => ({
                isEnabled: context.settings.isCastingEnabled,
                playerId: context.playerId,
                streams: [],
              }),
            },
            {
              id: "castPinMachine",
              src: "castPinMachine",
              data: ({ castPin, playerId, tenantId }) => ({
                castPin,
                playerId,
                tenantId,
              }),
            },
          ],
          on: {
            UPDATE_TIME: {
              actions: ["updateTime", "persist", "forwardToPullMachine"],
            },
            UPDATE_PULLED_DATA: {
              actions: ["savePullHash"],
            },
            UPDATE_SETTINGS: [
              {
                actions: [
                  "updateSettings",
                  "forwardToPullMachine",
                  "processCommand",
                  "persist",
                  "forwardToCommandsMachine",
                  "forwardToBrightnessMachine",
                  "forwardToPowerSavingMachine",
                  "forwardToScreenshotMachine",
                  "forwardToCastingMachine",
                  "forwardToCastPinMachine",
                ],
              },
            ],
            COMMAND_RESTART: {
              actions: ["persist", "forwardToCommandsMachine", "restart"],
            },
            PLAYER_UNREGISTERED: {
              target: "init",
              actions: ["resetContext", "resetIdb", "disableKiosk"],
            },
            PLAYER_UNPAID: {
              target: "unpaid",
            },
            CONTENT_UPDATED: {
              // TODO: i think there's a bug because
              // the media files are downloaded after
              // `savePullHash` is called on `UPDATE_PULLED_DATA`
              // which means the hash will be updated even if media sync has failed
              actions: ["updateContent", "persist"],
            },
            UPDATE_BRIGHTNESS: {
              actions: ["updateBrightness", "applyBrightness"],
            },
            OPEN_GOOGLE_PLAY: {
              actions: ["openGooglePlay"],
            },
            CAST_PIN_UPDATE: {
              actions: ["saveCastPin", "persist"],
            },
          },
          initial: "playing",
          states: {
            playing: {
              on: {
                ENABLE_POWER_SAVING: {
                  target: "savingPower",
                },
                DISABLE_POWER_SAVING: {
                  actions: ["disablePowerSaving"],
                },
                START_CASTING: {
                  target: "casting",
                },
              },
            },
            savingPower: {
              entry: ["enablePowerSaving"],
              on: {
                DISABLE_POWER_SAVING: {
                  actions: ["disablePowerSaving"],
                  target: "playing",
                },
              },
            },
            casting: {
              on: {
                STOP_CASTING: {
                  target: "playing",
                },
                STREAMING_ERROR: {
                  actions: ["forwardToCastingMachine"],
                },
              },
            },
          },
        },
        unpaid: {
          entry: ["disableKiosk"],
          invoke: [
            {
              src: timeSyncMachine,
            },
            {
              id: "pullMachine",
              src: "pullMachine",
              autoForward: true,
              data: (context) => ({
                playerId: context.playerId,
                tenantId: context.tenantId,
                pullHash: "",
                mediaCacheUrl: context.settings.mediaCacheUrl,
                timeData: context.timeData,
                screenOrientation: context.settings.screenOrientation,
                videoCodec: context.settings.videoCodec,
                imageFormat: context.settings.imageFormat,
                documentFormat: context.settings.documentFormat,
                dateTimeSource: context.settings.dateTimeSource,
                playlists: context.playlists,
              }),
            },
          ],
          on: {
            UPDATE_PULLED_DATA: {
              target: "init",
            },
          },
        },
        fail: {
          initial: "general",
          meta: { isError: true },
          states: {
            general: {
              after: {
                10000: "#launch",
              },
              meta: "An error has happened",
            },
            fetchPin: {
              id: "failFetchPin",
              after: {
                3000: "#fetchPin",
              },
            },
          },
        },
      },
    },
    {
      actions: {
        startRemoteLogsIfEnabled: (context) => {
          if (context.settings.isRemoteLogsEnabled) {
            enableRemoteLogs(context.playerId ?? "");
          } else {
            disableRemoteLogs();
          }
        },

        forwardToCastPinMachine: forwardTo("castPinMachine"),
        forwardToCastingMachine: forwardTo("castingMachine"),
        forwardToScreenshotMachine: forwardTo("screenshotMachine"),
        forwardToPullMachine: forwardTo("pullMachine"),
        forwardToCommandsMachine: forwardTo("commandsMachine"),
        forwardToBrightnessMachine: forwardTo("brightnessMachine"),
        forwardToPowerSavingMachine: forwardTo("powerSavingMachine"),

        saveDataSuffix: assign((_context, event) => ({
          dataSuffix: event.data,
        })),

        saveChannelInfo: assign((_context, event) => ({
          playerId: event.data.playerId,
          tenantId: event.data.tenantId,
          viewerId: event.data.viewerId,
        })),

        savePlayerInfo: assign((_, event) => ({
          playerInfo: event.data,
        })),

        updateBrightness: assign((context, event) => ({
          currentBrightness:
            event.type === "UPDATE_BRIGHTNESS"
              ? event.payload.brightness
              : context.currentBrightness,
        })),
        applyBrightness: (context) => {
          console.log(`Setting brightness to ${context.currentBrightness}`);
          if (window.b2bapis) {
            window.b2bapis.b2bcontrol.setLampSchedule(
              "ON",
              "00:00",
              context.currentBrightness * 100,
              "23:59",
              context.currentBrightness * 100,
              () =>
                console.log(
                  `Set brightness to ${context.currentBrightness} sucessfully`
                ),
              (err) => console.error(JSON.stringify(err, null, 2))
            );
          }
        },
        enablePowerSaving: () => {
          console.log(`Enable Power Saving`);
          if (window.bravia) {
            window.bravia.setPowerSavingMode("pictureOff");
          } else {
            window.document.body.classList.toggle("power-saving", true);
          }
        },
        disablePowerSaving: () => {
          console.log(`Disable Power Saving`);
          if (window.bravia) {
            window.bravia.setPowerSavingMode("off");
          } else {
            window.document.body.classList.toggle("power-saving", false);
          }
        },
        updateDefaultSettings: assign((context) => ({
          settings: getDefaultSettings(context),
        })),

        saveGeoInfo: assign((_context, event) => ({
          geoInfo: event.data,
        })),

        updatingScreenshotUrl: assign((_context, event) => ({
          screenshotUrl: event?.data?.body?.data?.getScreenshotUrl || "",
        })),

        saveCastPin: assign((context, event) => ({
          castPin:
            event.type === "CAST_PIN_UPDATE"
              ? event.payload.castPin
              : context.castPin,
        })),

        savePlayerData: assign((_context, event) => {
          const data = event.data.body.data.generatePin;
          if (!data) return {};
          return {
            pin: data.pin,
            playerId: data.playerId,
            pinTtl: data.timeToLive,
          };
        }),

        // @ts-ignore
        updateTime,

        restart: async () => {
          await new Promise((resolve) => setTimeout(resolve, 5000));
          if (window.bravia?.requestReboot) {
            window.bravia.requestReboot();
          } else if (window.FugoBridge?.restart) {
            console.log("window.FugoBridge.restart()");
            window.FugoBridge.restart();
          } else if (window.b2bapis?.b2bcontrol) {
            window.b2bapis.b2bcontrol.rebootDevice(
              () => console.log("Rebooting SSSP"),
              (err) => console.error(err)
            );
          } else if (getPlayerType() === "brightsign") {
            brightsign.reboot();
          } else {
            console.log("Refreshing page");
            document.location.reload();
          }
          // in case the player doesn't restart, reload the page
          setTimeout(() => {
            console.error("Player didn't restart in time, reloading the page");
            window.location.reload();
          }, 1000 * 10);
        },

        processCommand: (context, event) => {
          console.log(JSON.stringify(event, null, 2));

          if ("autostart" in event.settings) {
            const autostart = !!event.settings.autostart;
            setAutostart(autostart);
          } else if ("kiosk" in event.settings) {
            const kiosk = !!event.settings["kiosk"];
            setKiosk(kiosk);
          } else if ("screenOrientation" in event.settings) {
            const { screenOrientation } = event.settings as any;
            setScreenOrientation(screenOrientation);
          } else if ("isDisplayOn" in event.settings) {
            const isDisplayOn = !!event.settings["isDisplayOn"];
            setDisplayState(isDisplayOn);
          } else if ("isRemoteLogsEnabled" in event.settings) {
            const { isRemoteLogsEnabled } = event.settings;
            if (isRemoteLogsEnabled) {
              enableRemoteLogs(context.playerId ?? "");
            } else {
              disableRemoteLogs();
            }
          }
        },

        applySettings: (context) => {
          if (context.settings.autostart === true) {
            setAutostart(context.settings.autostart);
          }
          if (context.settings.kiosk != null) {
            setKiosk(context.settings.kiosk);
          }
        },

        disableKiosk: () => {
          setKiosk(false);
        },

        incrementPinRetries: assign({
          pinRetries: (_context) => _context.pinRetries + 1,
        }),

        saveRegistrationData: assign((context, event) => {
          const data = event.data.body.data.completeRegistration;
          if (!data) return {};
          return {
            playerId: data.playerId,
            tenantId: data.tenantId,
            settings: { ...context.settings, ...data.settings },
          };
        }),

        savePullHash: assign((_context, event) => ({
          pullHash: event.hash,
        })),

        // @ts-expect-error
        updateSettings: assign((context, event) => ({
          settings: { ...context.settings, ...event.settings },
        })),

        updateContent: assign((_context, event: any) => ({
          playbackData: event.playbackData,
          playlists: event.playlists,
          medias: event.medias,
        })),

        resetContext: assign((_context) => contextInitValue),

        resetIdb: () => {
          clear();
        },

        persist: (context) => {
          storeContext(context);
        },

        loadContext: assign((context, event) => ({
          ...event.data,
          settings: {
            ...getDefaultSettings(),
            ...event.data.settings,
          },
          playbackData: {
            currentPlayback: CurrentPlayback.A,
            playbackA: undefined,
            playbackB: undefined,
          },
          isAmazonRelease: context.isAmazonRelease,
          dataSuffix: context.dataSuffix,
        })),

        handleError: (_context, event) => {
          if (event.type !== "ERROR") {
            console.error("Unknown error");
            return;
          }
        },

        notifySlackNewPin: (context, event) => {
          notifySlack(
            `new pin: ${
              event.data.body.data.generatePin?.pin
            }, context: ${JSON.stringify(context, null, 2)}`
          );
        },

        saveOutOfSyncSettings: assign((context, event) => ({
          outOfSyncSettings: getOutOfSyncSettings(context, event),
        })),
        clearOutOfSyncSettings: () => assign({ outOfSyncSettings: {} }),

        openGooglePlay: () => {
          if (window.FugoBridge?.openGooglePlay) {
            console.log("window.FugoBridge.openGooglePlay()");
            window.FugoBridge.openGooglePlay();
          }
        },
        saveSubmittedPassword: assign((_context, event) => ({
          submittedPassword: event.password,
        })),
        saveSubmittedPasswordInSession: (context) => {
          if (typeof context.submittedPassword === "string") {
            sessionStorage.setItem(
              CHANNEL_SESSION_PASSWORD_KEY,
              context.submittedPassword
            );
          }
        },
        saveChannelPassword: assign((context, event) => ({
          settings: {
            ...context.settings,
            password:
              event?.data?.body?.data?.updateSettings?.settings?.password ?? "",
          },
        })),
      },

      delays: {
        PIN_TTL: (_context) => _context.pinTtl || 10000,
        PLAYER_REGISTRATION_DELAY: 5 * 1000,
        CLEAR_PASSWORD_ERROR_TIMEOUT: 10_000,
      },

      guards: {
        isPlayerRegistered: (_context) => isPlayerRegistered(_context),

        isPlayerUnregistered: (_context) => !isPlayerRegistered(_context),

        isFetchPinSuccessful: (_context, event: any) =>
          checkGraphqlResponse<PlayerMutation>(
            event,
            (data) => !!data?.generatePin
          ),

        isPlayerRegistrationSuccessful: (_context, event: any) =>
          checkGraphqlResponse<PlayerMutation>(
            event,
            (data) => !!data?.completeRegistration
          ),

        isAndroid: () => !!window.FugoBridge,

        areSettingsOutOfSync,
        isPasswordRequired: (context) => !!context.settings.password,
        isSessionPasswordCorrect: (context, event) =>
          context.settings.password === event.data,
        isSubmittedPasswordCorrect: (context) =>
          context.submittedPassword === context.settings.password,
      },

      activities: {
        sendingHeartbeat: () => {
          const HEART_BEAT_INTERVAL = 1000 * 60 * 3;
          const interval = setInterval(sendHeartbeat, HEART_BEAT_INTERVAL);
          function sendHeartbeat() {
            if (window?.FugoBridge?.heartbeat) {
              const timeoutMs = (HEART_BEAT_INTERVAL * 2).toString();
              log(`window.FugoBridge.heartbeat(${timeoutMs});`);
              window?.FugoBridge?.heartbeat(timeoutMs);
            }
          }
          return () => clearInterval(interval);
        },
        trackView: (context) => {
          const interval = setInterval(trackView, 600000);
          trackView();

          function trackView() {
            const options: RequestInit = {
              method: "POST",
              headers: {
                "x-api-key":
                  process.env.REACT_APP_AMPLIFY_KEY ??
                  "REACT_APP_AMPLIFY_KEY is missing from env",
              },
              body: JSON.stringify({
                query: `
                mutation registerViewer(
                  $viewerId: ID
                  $tenantId: String
                  $playerId: String
                ) {
                  registerViewer(viewerId: $viewerId, tenantId: $tenantId, playerId: $playerId)
                }
              `,
                variables: {
                  viewerId: context.viewerId,
                  tenantId: context.tenantId,
                  playerId: context.playerId,
                },
              }),
              cache: "no-cache",
            };

            fetch("/amplify", options).catch((e) => console.log(e));
          }

          return () => clearInterval(interval);
        },
      },

      services: {
        channelCheck: async function channelCheck(ctx: PlayerContext) {
          const urlParams = new URLSearchParams(window.location.search);
          const tenantId = urlParams.get("tenantId");
          const playerId = urlParams.get("playerId");
          if (!tenantId || !playerId) throw new Error("Not a channel");
          const viewerId = await getViewerId(ctx.dataSuffix);
          return { tenantId, playerId, viewerId };
        },
        geoInfo: () =>
          fetch(`${process.env.REACT_APP_FUNCTIONS_HOST}/functions/info`).then(
            (r) => r.json()
          ),
        isSilentReload,
        getContext: async (ctx) => await getContext(ctx.dataSuffix),
        gettingPin: fetchMachine,
        pair: fetchMachine,
        updateSettingsInCMS: fetchMachine,
        updatingScreenshotUrl: fetchMachine,
        checkSettings: fetchMachine,
        syncSettings: fetchMachine,
        updateInfo: fetchMachine,
        playbackMachine,
        pullMachine,
        screenshotMachine,
        commandsMachine,
        brightnessMachine,
        powerSavingMachine,
        castingMachine,
        loadDataSuffix: async () => getSuffixFromUrl(),
        getSessionPassword: async () =>
          sessionStorage.getItem(CHANNEL_SESSION_PASSWORD_KEY) ?? "",
        loadPlayerInfo: (context) =>
          getPlayerInfo(context, !isPlayerRegistered(context)),
        castPinMachine,
      },
    }
  );

function getSuffixFromUrl(): string {
  const urlParams = new URLSearchParams(window.location.search);
  const suffix = urlParams.get("suffix") ?? "";
  return suffix ? `_${suffix}` : "";
}

function isUpdateSettingsRequestEvent(
  event: any
): event is UpdateSettingsRequestEvent {
  return typeof event?.data?.body?.data?.updateSettings?.settings === "object";
}

function areSettingsOutOfSync(context: PlayerContext, event: any): boolean {
  if (!isUpdateSettingsRequestEvent(event)) return false;
  const serverSettings = event.data.body.data.updateSettings.settings ?? {};
  const playerSettings = context.settings;
  const isServerMissingSetting = Object.keys(playerSettings).some(
    (key) => !serverSettings.hasOwnProperty(key)
  );
  return isServerMissingSetting;
}

function getOutOfSyncSettings(context: PlayerContext, event: any): object {
  if (!isUpdateSettingsRequestEvent(event)) return {};
  const serverSettings = event.data.body.data.updateSettings.settings ?? {};
  const playerSettings = context.settings;
  const entries = Object.entries(playerSettings).filter(
    ([key]) => !serverSettings.hasOwnProperty(key)
  );
  return Object.fromEntries(entries);
}

async function getViewerId(dataSuffix: string) {
  const context = await getContext(dataSuffix);
  return context.viewerId || Math.random().toString();
}

let prevContextStringified = "";
const throttledStoreContext = throttle(
  async (curContext) => {
    const context = {
      ...curContext,
      playbackData: undefined,
      castingStreams: [],
    };
    const contextStringified = JSON.stringify(context);
    if (prevContextStringified === contextStringified) {
      console.log("not persisting since context is unchanged");
      return;
    }
    const key = generateContextKey(context.dataSuffix);
    try {
      console.log(`persist ${key}: ${contextStringified.length} characters`);
      await set(key, context);
    } catch (e) {
      console.error(
        "failed to store context in IndexedDB (will be saved in localStorage)",
        e
      );
    }
    try {
      if (shouldUseScapAPI()) {
        await writeToLGStorage(contextStringified);
        console.log("Successfully backed up context to LG storage");
      }
    } catch (error) {
      console.error("Failed to back up context to LG storage", error);
    }
    localStorage.setItem(key, contextStringified);
    prevContextStringified = contextStringified;
  },
  10000,
  { trailing: true }
);

async function storeContext(context: PlayerContext): Promise<void> {
  await throttledStoreContext(context);
}

function generateContextKey(dataSuffix: string): string {
  return `context${dataSuffix}`;
}

async function getContext(dataSuffix: string): Promise<PlayerContext> {
  console.log({ dataSuffix });
  const key = generateContextKey(dataSuffix);
  if (shouldUseScapAPI()) {
    try {
      const context = await readFromLGStorage();
      if (!context) {
        throw new Error("no context in LG storage");
      }
      return context;
    } catch (e) {
      console.log(e);
    }
  }
  try {
    const context = await get<PlayerContext>(key);
    if (!context) {
      throw new Error("no context in indexeddb");
    }

    if (!("tenantId" in context)) {
      throw new Error("incorrect context in indexeddb");
    }

    return context;
  } catch (e) {
    try {
      const contextStr = localStorage.getItem(key);
      if (!contextStr) {
        throw new Error("no context in local storage");
      }

      const context = JSON.parse(contextStr);
      if (!("tenantId" in context)) {
        throw new Error("incorrect context in local storage");
      }

      return context as PlayerContext;
    } catch (e) {
      console.debug("New player");
    }
  }
  return contextInitValue;
}

async function getPlayerInfo(
  context: PlayerContext,
  isPairing: boolean = false
): Promise<Info> {
  const additionalInfo: AdditionalPlayerInfo = {};

  if (shouldUseScapAPI()) {
    try {
      const lgDeviceInfo = await getLGDeviceInfo();
      additionalInfo.deviceModel = lgDeviceInfo.modelName;
      additionalInfo.firmwareVersion = lgDeviceInfo.firmwareVersion;
    } catch (error) {
      console.error("Error fetching LG device info: ", error);
    }
  }

  if (getPlayerType() === "bravia") {
    additionalInfo.isBraviaAPISupported = true;
  }
  if (window.FugoBridge && window.FugoBridge.isEnergySaverEnabled) {
    additionalInfo.isEnergySaverEnabled =
      window.FugoBridge.isEnergySaverEnabled();
  }

  if (window.FugoBridge && window.FugoBridge.canDrawOverlays) {
    additionalInfo.canDrawOverlays = window.FugoBridge.canDrawOverlays();
  }

  if (window.FugoBridge && window.FugoBridge.getAndroidVersion) {
    additionalInfo.operationSystem = `Android ${window.FugoBridge.getAndroidVersion()}`;
  }

  if (window.FugoBridge && window.FugoBridge.getDeviceModel) {
    additionalInfo.deviceModel = window.FugoBridge.getDeviceModel();
  }

  if (window.FugoBridge && window.FugoBridge.getAPKVersion) {
    additionalInfo.appShellVersion = window.FugoBridge.getAPKVersion();
  }

  if (window.FugoBridge && window.FugoBridge.openGooglePlay) {
    additionalInfo.isGooglePlaySupported = true;
  }

  if (window.FugoBridge) {
    const { width, height } = JSON.parse(window.FugoBridge.getViewSize());
    additionalInfo.screenResolution = `${width}x${height}`;
  }

  if (window.FugoElectronBridge && window.FugoElectronBridge.getVersion) {
    additionalInfo.appShellVersion = window.FugoElectronBridge.getVersion();
  }

  if (window.b2bapis) {
    additionalInfo.serialNumber = window.b2bapis.b2bcontrol.getSerialNumber();
    additionalInfo.deviceModel = window.webapis.productinfo.getRealModel();
    additionalInfo.operationSystem = window.webapis.productinfo.getFirmware();
    additionalInfo.appShellVersion =
      window.tizen.application.getCurrentApplication().appInfo.packageId;
    try {
      window.b2bapis.b2bcontrol.setNoSignalPowerOff(
        "OFF",
        () => console.log("setNoSignalPowerOff"),
        console.error
      );
      window.b2bapis.b2bcontrol.setStandby(
        "OFF",
        () => console.log("setStandby"),
        console.error
      );
    } catch (e) {
      console.error(e);
    }
  }

  const deviceModelParam = new URLSearchParams(window.location.search).get(
    "deviceModel"
  );
  if (deviceModelParam) {
    additionalInfo.deviceModel = deviceModelParam;
  }

  if (isPairing) {
    additionalInfo.pairedTime = Date.now();
  }

  additionalInfo.videoCodecs = getSupportedVideoCodecs()
    .join(", ")
    .toUpperCase();

  return {
    ...additionalInfo,
    ...context.geoInfo,
    fugoAppVersion: process.env.REACT_APP_VERSION || "unknown",
    playerType: getPlayerType(),
    userAgent: window.navigator.userAgent,
    startTime: Date.now(),
    browserResolution: `${window.innerWidth}x${window.innerHeight}`,
    playerTimezone: getTimezone(),
    playerClock: new Date().toLocaleString(),
    devicePixelRatio: window.devicePixelRatio,
    isNativeScreenshotSupported: isNativeScreenshotSupported(),
  };
}

function setAutostart(autostart: boolean) {
  const chromeExtensionId = getChromeExtensionId();
  if (window.FugoBridge?.setAutostart) {
    console.log(`window.FugoBridge.setAutostart(${autostart})`);
    window.FugoBridge.setAutostart(autostart);
  } else if (
    typeof window?.chrome?.runtime?.sendMessage === "function" &&
    chromeExtensionId
  ) {
    chrome.runtime.sendMessage(chromeExtensionId, {
      type: "setAutostart",
      autostart,
    });
  }
}

function setKiosk(isKiosk: boolean) {
  const chromeExtensionId = getChromeExtensionId();
  if (window.FugoBridge?.setKiosk) {
    console.log(`window.FugoBridge.setKiosk(${isKiosk})`);
    window.FugoBridge.setKiosk(isKiosk);
  } else if (
    typeof window?.chrome?.runtime?.sendMessage === "function" &&
    chromeExtensionId
  ) {
    // TODO: Chrome Extension doesn't handle this
    chrome.runtime.sendMessage(chromeExtensionId, {
      type: "setKiosk",
      isKiosk,
    });
  } else if (window.FugoElectronBridge?.setKiosk) {
    console.log(`window.FugoElectronBridge.setKiosk(${isKiosk})`);
    window.FugoElectronBridge?.setKiosk(isKiosk);
  }
}

function createUpdateSettingsData(
  context: PlayerContext,
  vars: UpdateSettingsVariables
) {
  const variables: PlayerMutationUpdateSettingsArgs = {
    playerId: context.playerId ?? "error",
    tenantId: context.tenantId ?? "error",
    ...vars,
  };
  return {
    url: `${process.env.REACT_APP_API_HOST}/player/player`,
    requestBody: {
      // request `info` when info is changed
      // otherwise request `settings` because this mutation is used for querying
      // `settings` (no variable)
      query: `
        mutation updateSettings($tenantId: String!, $playerId: String!, $newSettings: Json, $newInfo: Json) {
          updateSettings(tenantId: $tenantId, playerId: $playerId, newSettings: $newSettings, newInfo: $newInfo) {
            ${vars.newInfo ? "info" : "settings"}
          }
        }
      `,
      variables,
    },
  };
}

type UpdateSettingsVariables = Pick<
  PlayerMutationUpdateSettingsArgs,
  "newInfo" | "newSettings"
>;

type UpdateSettingsRequestEvent = DoneInvokeEvent<UpdateSettingsRequest>;

interface UpdateSettingsRequest {
  body: {
    data: {
      updateSettings: {
        settings: PlayerMutation["updateSettings"];
      };
    };
  };
}

export interface Info extends AdditionalPlayerInfo, GeoInfo {
  playerType: PlayerType;
  fugoAppVersion: string;
  userAgent: string;
  startTime: number;
  playerTimezone: string;
  playerClock: string;
  devicePixelRatio: number;
  isNativeScreenshotSupported: boolean;
}

interface GeoInfo {
  country?: string;
  city?: string;
  ip?: string;
}

interface AdditionalPlayerInfo {
  operationSystem?: string;
  deviceModel?: string;
  appShellVersion?: string;
  serialNumber?: string;
  videoCodecs?: string;
  pairedTime?: number;
  screenResolution?: string;
  browserResolution?: string;
  isGooglePlaySupported?: boolean;
  canDrawOverlays?: boolean;
  isEnergySaverEnabled?: boolean;
  isBraviaAPISupported?: boolean;
  firmwareVersion?: string;
}

interface ErrorEvent {
  type: "ERROR";
  payload: { error: string };
}

interface ErrorEvent {
  type: "ERROR";
  payload: { error: string };
}

export interface Settings {
  screenOrientation: number;
  interleaved: boolean;
  originalFormat: boolean;
  kiosk: boolean | NotSupported;
  autostart: boolean | NotSupported;
  videoPreferences: VideoPreference | NotSupported;
  videoCodec: VideoCodec;
  imageFormat: ImageFormat;
  documentFormat: DocumentFormat;
  isDisplayOn: boolean | NotSupported;
  brightnessSchedule: BrightnessSchedule;
  powerSavingSchedule: PowerSavingSchedule;
  dateTimeSource: DateTimeSource;
  isSurface: boolean | null;
  isFlickeringWorkaroundEnabled: boolean | null;
  isMuted: boolean;
  isScreenshotSupported: boolean;
  shouldHTMLScreenshotForced: boolean | null;
  isScreenshotEnabled: boolean | null;
  androidScreenshotMethod: AndroidScreenshotMethod | null;
  screenshotInterval: number | null;
  isVideoPrerenderingEnabled: boolean | null;
  isTestScreen?: boolean;
  password?: string;
  mediaCacheUrl: string;
  isCastingEnabled: boolean;
  isTransitionAnimationEnabled: boolean;
  shouldStoreMediaInIndexedDB: boolean;
  isRemoteLogsEnabled: boolean;
  isCacheAPIForced: boolean;
  shouldDisplayCastingInstructions: boolean;
  wifiInstructions: WifiInstructions | null;
  castingInstructionsOptions: CastingInstructionsOptions | null;
}

export type NotSupported = null;

export type VideoPreference = "native" | "html" | null;

export type WifiInstructions = {
  ssid: string;
  password: string;
};

export type CastingInstructionsOptions = {
  size?: "STANDARD" | "BIG";
  style?: "POPUP" | "BAR";
  position?: "STATIC" | "DYNAMIC";
};

export interface PlayerContext {
  pin?: string;
  playerId?: string;
  tenantId?: string;
  viewerId?: string;
  settings: Settings;
  pinTtl: number;
  pinRetries: number;
  lastError?: string;
  timeData: TimeData;
  pullHash?: string;
  playlists: Playlist[];
  medias: FormattedMedia[];
  screenshotUrl: string;
  isAmazonRelease: boolean;
  geoInfo?: GeoInfo;
  currentBrightness: number;
  outOfSyncSettings: object;
  dataSuffix: string;
  submittedPassword?: string;
  playerInfo: Info | null;
  castPin: string;
}

export type PlayerEvent =
  | UpdateTimeEvent
  | UpdatedPulledDataEvent
  | UpdateSettingsEvent
  | CommandRestartEvent
  | { type: "PLAYER_UNREGISTERED" }
  | { type: "PLAYER_UNPAID" }
  | {
      type: "CONTENT_UPDATED";
      playlists: Playlist[];
      medias: FormattedMedia[];
    }
  | { type: "INTRO_VIDEO_FINISHED" }
  | ErrorEvent
  | UpdateBrightnessEvent
  | EnablePowerSavingEvent
  | DisablePowerSavingEvent
  | OpenGooglePlayEvent
  | PasswordSubmitEvent
  | { type: "CLEAR_PASSWORD_ERROR" }
  | StartCasting
  | StopCasting
  | UpdateCasting
  | StreamingError
  | UpdateCastPinEvent;

type PasswordSubmitEvent = {
  type: "PASSWORD_SUBMIT";
  password: string;
};

function shouldUseOriginalMediaFormatByDefault() {
  return false;
}

function getDefaultScreenOrientation() {
  if (window.b2bapis) {
    const orientation = window.b2bapis.b2bcontrol.getMenuOrientation();
    console.log(`Tizen orientation: ${orientation}`);
    if (orientation === "DEGREE_90") return 90;
    if (orientation === "PORTRAIT") return 90;
    if (orientation === "DEGREE_180") return 180;
    if (orientation === "DEGREE_270") return 270;
  }
  return 0;
}

function setScreenOrientation(screenOrientation: number) {
  if (window.b2bapis) {
    const tizenOrientation = `DEGREE_${screenOrientation}`;
    window.b2bapis.b2bcontrol.setMenuOrientation(
      tizenOrientation as any,
      () => console.log(`Set screen orientation to ${tizenOrientation}`),
      console.error
    );
  }
}

function getDefaultIsDisplayOn(): true | null {
  return window.b2bapis ? true : null;
}

function getDefaultKiosk(): boolean | null {
  const isKioskSupported =
    window.FugoBridge || window.FugoElectronBridge?.setKiosk;
  if (isKioskSupported) {
    return false;
  } else {
    return null;
  }
}

function getDefaultAutostart(): boolean | null {
  if (window.FugoBridge) {
    return false;
  }
  if (getPlayerType() === "chrome") return true;
  return null;
}

function setDisplayState(isDisplayOn: boolean) {
  if (window.b2bapis) {
    window.b2bapis.b2bcontrol.setPanelMute(
      isDisplayOn ? "OFF" : "ON",
      () => {},
      console.error
    );
  }
}

function getDefaultBrightnessSchedule(): BrightnessSchedule {
  const playerType = getPlayerType();
  const isWebClient =
    (playerType === "unknown" || playerType === "chrome") &&
    !isOutdatedBrowser();
  if (window.b2bapis || isWebClient) {
    return { default: 1, schedule: [] };
  }
  return null;
}

function getDefaultPowerSavingSchedule(): PowerSavingSchedule {
  return {
    start: "",
    end: "",
    isEnabled: false,
  };
}

function getDefaultVideoView(): boolean | null {
  if (window.FugoBridge) {
    return true;
  } else {
    return null;
  }
}

function getDefaultFlickeringWorkaroundEnabled(): boolean | null {
  const apkVersion = getAPKVersion();
  if (apkVersion.major >= 2 && apkVersion.minor >= 4) {
    return false;
  }
  return null;
}

function getDefaultSettings(context?: PlayerContext): Settings {
  return {
    screenOrientation: getDefaultScreenOrientation(),
    interleaved: false,
    originalFormat: shouldUseOriginalMediaFormatByDefault(),
    kiosk: getDefaultKiosk(),
    autostart: getDefaultAutostart(),
    videoPreferences: getDefaultVideoPlaybackType(),
    videoCodec: getDefaultVideoCodec(),
    imageFormat: getDefaultImageFormat(),
    documentFormat: getDefaultDocumentFormat(),
    isDisplayOn: getDefaultIsDisplayOn(),
    brightnessSchedule: getDefaultBrightnessSchedule(),
    powerSavingSchedule: getDefaultPowerSavingSchedule(),
    dateTimeSource: getDefaultDateTimeSource(),
    isSurface: getDefaultVideoView(),
    isFlickeringWorkaroundEnabled: getDefaultFlickeringWorkaroundEnabled(),
    isMuted: false,
    isScreenshotSupported: true,
    shouldHTMLScreenshotForced: getDefaultShouldHTMLScreenshotForced(),
    isScreenshotEnabled: getDefaultIsScreenshotEnabled(),
    screenshotInterval: getDefaultScreenshotInterval(),
    isVideoPrerenderingEnabled: getDefaultIsVideoPrerenderingEnabled(),
    androidScreenshotMethod: getDefaultAndroidScreenshotMethod(),
    isTestScreen: context?.settings?.isTestScreen || false,
    password: context?.settings?.password || "",
    mediaCacheUrl: getDefaultMediaCacheUrl(
      context?.tenantId,
      context?.geoInfo?.country
    ),
    isCastingEnabled: getDefaultIsCastingEnabled(),
    isTransitionAnimationEnabled: true,
    shouldStoreMediaInIndexedDB: getDefaultShouldStoreMediaInIndexedDB(),
    isRemoteLogsEnabled: false,
    isCacheAPIForced: getDefaultIsCacheAPIForced(),
    shouldDisplayCastingInstructions: false,
    wifiInstructions: null,
    castingInstructionsOptions: getDefaultCastingInstructionsOptions(),
  };
}

function getDefaultIsScreenshotEnabled(): boolean | null {
  // disable screenshots on newer Androids >= 9
  // because videos start lagging when "doScreenshot2" is used on "html"
  // and "doScreenshot" displays a popup
  if (getDefaultAndroidScreenshotMethod() === "doScreenshot2") {
    if (getDefaultVideoPlaybackType() === "native") {
      return true;
    }
  }

  return false;
}

function getDefaultAndroidScreenshotMethod(): AndroidScreenshotMethod | null {
  if (typeof window?.FugoBridge?.doScreenshot2 === "function") {
    return "doScreenshot2";
  } else {
    return null;
  }
}

function getDefaultScreenshotInterval(): number | null {
  return 30_000;
}

function getDefaultIsVideoPrerenderingEnabled(): boolean {
  const goodPlayers: PlayerType[] = ["mecool", "nvidia-shield"];
  return goodPlayers.includes(getPlayerType()) && !isOutdatedBrowser();
}

function getDefaultIsCastingEnabled(): boolean {
  return getPlayerType() !== "brightsign";
}

function getDefaultShouldStoreMediaInIndexedDB(): boolean {
  return false;
}

function getDefaultIsCacheAPIForced(): boolean {
  return false;
}

function getDefaultCastingInstructionsOptions(): CastingInstructionsOptions {
  return {
    size: "BIG",
    style: "POPUP",
    position: "STATIC",
  };
}

function enableRemoteLogs(playerId: string) {
  consolere.connect({
    server: "https://remote-logs.fugo.ai",
    channel: `player-${playerId}`,
    redirectDefaultConsoleToRemote: true,
    disableDefaultConsoleOutput: true,
  });
  if (window.FugoBridge) {
    window.globalAndroidLogs = (base64Message: string) => {
      console.re.log(atob(base64Message));
    };
  }
  setLogLevel(LogLevel.DEBUG);
}

function disableRemoteLogs() {
  if (window.FugoBridge) {
    window.globalAndroidLogs = noop;
  }
  setLogLevel(LogLevel.WARN);
}

function getDefaultShouldHTMLScreenshotForced(): boolean | null {
  // doesn't matter for players without native screenshots since they always use HTML
  if (!isNativeScreenshotSupported()) {
    return null;
  }

  return false;
}

export function shouldUseHTMLScreenshot(
  settings: Pick<Settings, "isScreenshotEnabled" | "shouldHTMLScreenshotForced">
) {
  if (!settings.isScreenshotEnabled) {
    return false;
  }
  switch (settings.shouldHTMLScreenshotForced) {
    // native supported & HTML is forced
    case true:
      return true;
    // only HTML supported & screenshot is enabled
    case null:
      return true;
    // native supported & HTML is not forced so use native
    default:
      return false;
  }
}
