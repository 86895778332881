import { useEffect, useRef } from "react";
import { getPlayerType } from "../../misc/get-player-type";
import styles from "./index.module.css";

export default function Casting({
  streams,
  onError,
}: {
  streams: Array<MediaStream>;
  onError: () => void;
}) {
  const grid = styles[`grid-${streams.length}`] ?? "";
  return (
    <div className={`${styles.root} ${grid}`}>
      {streams.map((stream) => (
        <Cast stream={stream} onError={onError} key={stream.id} />
      ))}
      <img className={styles.logo} src="./logo-casting-purple.svg" alt="logo" />
    </div>
  );
}

function Cast({
  stream,
  onError,
}: {
  stream: MediaStream;
  onError: () => void;
}) {
  const videoElement = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if (!videoElement.current) return;

    videoElement.current.srcObject = stream;
    videoElement.current.play().catch(() => {
      videoElement.current!.muted = true;
      videoElement.current!.play();
    });

    // if stream doesn't start playing, re-play it
    const playingTimeout = setTimeout(() => {
      console.log("stream timeout");
      onError();
    }, 10000);

    // events that may cause stream to stop
    videoElement.current.addEventListener("stalled", () => {
      console.log("stream stalled");
      onError();
    });
    videoElement.current.addEventListener("error", (e) => {
      console.error(JSON.stringify(e, null, 2));
      console.log("stream error");
      onError();
    });
    videoElement.current.addEventListener("abort", () => {
      console.log("stream abort");
      onError();
    });
    videoElement.current.addEventListener("playing", () => {
      console.log("stream playing");
      clearTimeout(playingTimeout);
    });

    let timeUpdateTimeout = 0;
    videoElement.current.addEventListener("timeupdate", () => {
      clearTimeout(timeUpdateTimeout);
      timeUpdateTimeout = window.setTimeout(() => {
        console.log("stream timeout timeupdate");
        onError();
      }, 3600 * 1000);
    });

    // just logs
    [
      "emptied",
      "ended",
      "pause",
      "suspend",
      "waiting",
      "loadedmetadata",
      "loadeddata",
      "canplay",
      "canplaythrough",
      "seeking",
      "seeked",
      "ratechange",
      "volumechange",
      "timeupdate",
      "durationchange",
      "progress",
    ].forEach((event) => {
      videoElement.current!.addEventListener(event, () => {
        console.log(`stream ${event}`);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <video
      ref={videoElement}
      className={styles.casting}
      muted={getPlayerType() === "webos"}
    />
  );
}
