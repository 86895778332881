import { useCallback, useEffect, useMemo, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack5";
import Measure from "react-measure";
import throttle from "lodash/throttle";
import style from "./pdf.module.css";
import { PDFPageProxy } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = "./pdf.worker.min.js";

export const PDF = ({
  src,
  onContentReady,
  onDone,
  shouldBePlaying,
  isInfinite,
  duration = 5000,
}: Props) => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [wrapperWidth, setWrapperWidth] = useState(0);
  const [wrapperHeight, setWrapperHeight] = useState(0);
  const [pageWidth, setPageWidth] = useState(0);
  const [pageHeight, setPageHeight] = useState(0);
  const [renderedPageNumber, setRenderedPageNumber] = useState(0);

  const fitHorizontal = useMemo(() => {
    const wRatio = pageWidth / wrapperWidth;
    const hRatio = pageHeight / wrapperHeight;
    if (wRatio < hRatio) {
      return false;
    }
    return true;
  }, [pageHeight, pageWidth, wrapperHeight, wrapperWidth]);

  const setWrapperDimensions = useCallback(
    (w?: number, h?: number) =>
      throttle((w, h) => {
        setWrapperWidth(w);
        setWrapperHeight(h);
      }, 500)(w, h),
    []
  );

  const onDocumentLoadSuccess = useCallback(
    ({ numPages }: { numPages: number }) => {
      setNumPages(numPages);
      onContentReady();
    },
    [onContentReady]
  );

  const onPageLoadSuccess = useCallback((page: PDFPageProxy) => {
    setPageWidth(page.width);
    setPageHeight(page.height);
  }, []);

  const onPageRenderSuccess = useCallback(() => {
    setRenderedPageNumber(pageNumber);
  }, [pageNumber]);

  const isLoading = renderedPageNumber !== pageNumber;

  useEffect(() => {
    if (numPages < 1 || !shouldBePlaying) return;
    let pageNumber = 1;
    const intervalId = setInterval(() => {
      if (pageNumber >= numPages) {
        if (isInfinite) {
          pageNumber = 0;
        } else {
          clearInterval(intervalId);
          onDone();
          return;
        }
      }
      pageNumber++;
      setPageNumber(pageNumber);
    }, duration / numPages);
    return () => clearInterval(intervalId);
  }, [numPages, duration, isInfinite, onDone, shouldBePlaying]);

  return (
    <div className={style.root}>
      <Measure
        client
        onResize={(contentRect) =>
          setWrapperDimensions(
            contentRect.client?.width,
            contentRect.client?.height
          )
        }
      >
        {({ measureRef }) => (
          <div className={style.wrapper} ref={measureRef}>
            <Document
              file={src}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={(err) => console.error(err)}
              onSourceError={(err) => console.error(err)}
            >
              {isLoading && renderedPageNumber ? (
                <Page
                  key={renderedPageNumber}
                  onLoadSuccess={onPageLoadSuccess}
                  width={fitHorizontal ? wrapperWidth : undefined}
                  height={!fitHorizontal ? wrapperHeight : undefined}
                  pageNumber={renderedPageNumber}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                />
              ) : null}
              <Page
                key={pageNumber}
                className={isLoading ? style.loadingPage : ""}
                onLoadSuccess={onPageLoadSuccess}
                onRenderSuccess={onPageRenderSuccess}
                width={fitHorizontal ? wrapperWidth : undefined}
                height={!fitHorizontal ? wrapperHeight : undefined}
                pageNumber={pageNumber}
                renderAnnotationLayer={false}
                renderTextLayer={false}
              />
            </Document>
          </div>
        )}
      </Measure>
    </div>
  );
};

interface Props {
  src: string;
  duration?: number;
  onDone: (error?: string) => void;
  onContentReady: () => void;
  shouldBePlaying: boolean;
  isInfinite: boolean;
}
