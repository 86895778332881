import { createMachine, assign, sendParent } from "xstate";
import { PlayerMutation } from "../generated/player";
import { fetchMachine } from "./FetchMachine";

export const castPinMachine = createMachine(
  {
    id: "castPinMachine",
    predictableActionArguments: true,
    schema: {
      context: {} as CastPinMachineContext,
      events: {} as CastPinMachineEvents,
      services: {} as CastPinMachineServices,
    },
    tsTypes: {} as import("./CastPinMachine.typegen").Typegen0,
    initial: "idle",
    context: {
      shouldDisplayCastingInstructions: false,
      castPin: "",
      tenantId: "",
      playerId: "",
    },
    states: {
      idle: {
        always: [{ cond: "isCastingEnabled", target: "maintainCastPin" }],
        on: {
          UPDATE_SETTINGS: [
            {
              cond: "isEnableCastingEvent",
              actions: ["saveCastingEnabled"],
              target: "maintainCastPin",
            },
          ],
        },
      },
      maintainCastPin: {
        initial: "updatePin",
        on: {
          UPDATE_SETTINGS: [
            {
              cond: "isDisableCastingEvent",
              actions: ["saveCastingDisabled"],
              target: "idle",
            },
            {},
          ],
        },
        states: {
          updatePin: {
            invoke: {
              src: "updateCastingPin",
              data: (context) => ({
                url: `${process.env.REACT_APP_API_HOST}/player/player`,
                requestBody: {
                  query: `
                    mutation GenerateCastingPin($tenantId: String!, $playerId: String!) {
                      generateCastingPin(tenantId: $tenantId, playerId: $playerId) {
                        castPin
                      }
                    }
                  `,
                  variables: {
                    playerId: context.playerId,
                    tenantId: context.tenantId,
                  },
                },
              }),
              onDone: {
                target: "idle",
                actions: ["savePin"],
              },
              onError: "error",
            },
          },
          idle: {
            entry: ["sendParentPin"],
          },
          error: {
            after: {
              60_000: "updatePin",
            },
          },
        },
      },
    },
  },
  {
    guards: {
      isCastingEnabled: (context) => context.shouldDisplayCastingInstructions,
      isEnableCastingEvent: (_context, event) =>
        event.settings.shouldDisplayCastingInstructions === true,
      isDisableCastingEvent: (_context, event) =>
        event.settings.shouldDisplayCastingInstructions === false,
    },
    actions: {
      saveCastingEnabled: assign({ shouldDisplayCastingInstructions: true }),
      saveCastingDisabled: assign({
        shouldDisplayCastingInstructions: false,
        castPin: "",
      }),
      savePin: assign((_context, event) => ({
        castPin: event?.data?.body?.data?.generateCastingPin?.castPin || "",
      })),
      sendParentPin: sendParent((context) =>
        createUpdateCastPinEvent(context.castPin)
      ),
    },
    services: {
      updateCastingPin: fetchMachine,
    },
  }
);

interface CastPinMachineContext {
  shouldDisplayCastingInstructions: boolean;
  castPin: string;
  playerId: string;
  tenantId: string;
}

type CastPinMachineEvents = {
  type: "UPDATE_SETTINGS";
  settings: {
    shouldDisplayCastingInstructions: boolean;
  };
};

type CastPinMachineServices = {
  updateCastingPin: {
    data: {
      body: {
        data: {
          generateCastingPin: PlayerMutation["generateCastingPin"];
        };
      };
    };
  };
};

function createUpdateCastPinEvent(castPin: string): UpdateCastPinEvent {
  return {
    type: "CAST_PIN_UPDATE",
    payload: { castPin },
  };
}

export interface UpdateCastPinEvent {
  type: "CAST_PIN_UPDATE";
  payload: { castPin: string };
}
