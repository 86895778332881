import { useRef, useEffect, memo, useCallback, CSSProperties } from "react";
import { App as AppType, FormattedMedia } from "../../generated/router";
import { assign, Machine } from "xstate";
import { useMachine } from "@xstate/react/lib";
import ReactDOM from "react-dom";
import React from "react";
import { getPlayerType } from "../../misc/get-player-type";
import { getScreenOrientationCssClasses } from "../../misc/get-screen-orientation-css-classes";
import useAppMessageHandler from "./use-app-message-handler";
import { getDevicePixelRatio } from "../video-playback";
import genSrc from "./gen-src";

const DynamicApp = ({
  content,
  onDone,
  onContentReady,
  shouldBePlaying,
  medias,
  isInfinite,
  isFullScreen,
  screenOrientation,
  headers,
}: Props) => {
  const messageIdRef = useRef(Math.random().toString(36).substring(2, 15));
  const id = messageIdRef.current;
  const src = genSrc(content.config.url, id);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  // send DONE event to the machine so it has a chance to call clean up functions
  // because playback can be interrupted by playlist update and this component
  // will be unmounted wihtout the machine getting to the final state
  useEffect(
    () => () => {
      send("DONE");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const bridgeName = window.FugoBridge ? "FugoBridge" : "FugoElectronBridge";
  const FugoBridge = window.FugoBridge || window.FugoElectronBridge;
  const [state, send] = useMachine(appMachine, {
    actions: {
      sendParam: () => {
        const iframe = iframeRef.current;
        if (!iframe || !iframe.contentWindow) return;
        iframe.contentWindow.postMessage(
          {
            id,
            method: "setParam",
            param: content.config.param,
            headers,
            isInfinite,
          },
          "*"
        );
      },
      setDisplayWebsite: assign((_context: Context, event: Event) => {
        if (event.type === "SET_DISPLAY_WEBSITE") {
          return {
            isDisplayingWebsite: true,
            url: event.payload.url,
            code: event.payload.code,
          };
        }
        return {};
      }),
      prepareWebsite: (context: Context) => {
        if (FugoBridge.prepareWebsite && FugoBridge.prepareWebsiteFullscreen) {
          if (isFullScreen) {
            console.log(`window.${bridgeName}.prepareWebsiteFullscreen(
              ${context.url},
              ${context.code},
              ${screenOrientation}
            );`);
            FugoBridge.prepareWebsiteFullscreen(
              context.url,
              context.code,
              screenOrientation
            );
          } else {
            const appSize = iframeRef.current?.getBoundingClientRect();
            if (appSize) {
              const devicePixelRatio = getDevicePixelRatio();
              const x = ~~(appSize.x * devicePixelRatio);
              const y = ~~(appSize.y * devicePixelRatio);
              const w = ~~(appSize.width * devicePixelRatio);
              const h = ~~(appSize.height * devicePixelRatio);
              console.log(`window.${bridgeName}.prepareWebsite(
                ${context.url},
                ${context.code},
                ${screenOrientation},
                ${x},
                ${y},
                ${w},
                ${h},
                ${id}
              );`);
              FugoBridge.prepareWebsite(
                context.url,
                context.code,
                screenOrientation,
                x,
                y,
                w,
                h,
                id
              );
            }
          }
        }
      },
      displayWebsite: (context: Context) => {
        if (context.isDisplayingWebsite) {
          if (isFullScreen && FugoBridge.displayWebsiteFullscreen) {
            console.log(`window.${bridgeName}.displayWebsiteFullscreen();`);
            FugoBridge.displayWebsiteFullscreen();
          } else if (FugoBridge.displayWebsite) {
            console.log(`window.${bridgeName}.displayWebsite(${id});`);
            FugoBridge.displayWebsite(id);
          }
        }
      },
      destroyWebsite: (context: Context) => {
        if (context.isDisplayingWebsite && !isInfinite) {
          if (isFullScreen && FugoBridge.destroyWebsiteFullscreen) {
            console.log(`window.${bridgeName}.destroyWebsiteFullscreen();`);
            FugoBridge.destroyWebsiteFullscreen();
          } else if (FugoBridge.destroyWebsite) {
            console.log(`window.${bridgeName}.destroyWebsite(${id});`);
            FugoBridge.destroyWebsite(id);
          }
        }
      },
      onDone,
      onContentReady,
      sendPlay: () => {
        iframeRef?.current?.contentWindow?.postMessage(
          {
            id: messageIdRef.current,
            method: "play",
            isInfinite,
          },
          "*"
        );
      },
    },
  });

  const handleMessage = useCallback(
    (data: any) => {
      if (isDisplayWebsite(data)) {
        send({
          type: "SET_DISPLAY_WEBSITE",
          payload: {
            url: data.payload.url,
            code: data.payload.code,
          },
        });
      }
    },
    [send]
  );

  useAppMessageHandler(
    iframeRef,
    messageIdRef,
    send,
    medias,
    isInfinite,
    handleMessage
  );

  useEffect(() => {
    if (shouldBePlaying) {
      send("PLAY");
    }
  }, [shouldBePlaying, send, state, isInfinite]);

  const style: CSSProperties = {
    objectFit: "contain",
    width: "100%",
    height: "100%",
    border: "none",
  };

  const app = (
    // eslint-disable-next-line jsx-a11y/iframe-has-title
    <iframe
      ref={iframeRef}
      style={style}
      src={
        process.env.REACT_APP_USE_HTTP
          ? src.replace("https://", "http://")
          : src
      }
      onLoad={() => send("LOAD")}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      sandbox="allow-scripts allow-same-origin allow-popups allow-forms allow-modals allow-top-navigation-by-user-activation allow-popups-to-escape-sandbox allow-presentation"
      allowFullScreen
    />
  );

  // fix for Youtube on FireTV
  const { kindId } = content.config;
  if (
    kindId === "youtube" &&
    (getPlayerType() === "amazon" ||
      headers.tenantId === "baffb6a9-8476-4225-a62a-b4cb11cd373e")
  ) {
    let clonedAppStyle: CSSProperties = {
      ...style,
      position: "fixed",
      top: 0,
      display: shouldBePlaying ? "block" : "none",
    };
    if (!isFullScreen) {
      const iframeSize =
        iframeRef.current?.getBoundingClientRect() ?? new DOMRect();
      Object.assign(clonedAppStyle, {
        top: iframeSize.y,
        left: iframeSize.x,
        width: iframeSize.width,
        height: iframeSize.height,
      });
    }
    const clonedApp = React.cloneElement(app, {
      style: clonedAppStyle,
    });
    // needed the special case to obey the screen orientation
    // because it's mounted higher on top of the root
    if (screenOrientation !== 0) {
      const screenOrientationCss =
        getScreenOrientationCssClasses(screenOrientation);
      return ReactDOM.createPortal(
        <div className={screenOrientationCss}>{clonedApp}</div>,
        document.body
      );
    } else {
      const appInPortal = ReactDOM.createPortal(clonedApp, document.body);
      return appInPortal;
    }
  } else {
    return app;
  }
};

interface Context {
  isDisplayingWebsite: boolean;
  url: string;
  code: string;
}
export type Event =
  | { type: "LOAD" }
  | { type: "PARAM_READY" }
  | { type: "READY" }
  | { type: "PLAY" }
  | { type: "DONE" }
  | { type: "SET_DISPLAY_WEBSITE"; payload: { url: string; code: string } };

const appMachine = Machine<Context, Event>({
  context: {
    isDisplayingWebsite: false,
    url: "",
    code: "",
  },
  initial: "empty",
  on: {
    SET_DISPLAY_WEBSITE: {
      actions: ["setDisplayWebsite", "prepareWebsite"],
    },
  },
  states: {
    empty: {
      on: {
        LOAD: {
          target: "loaded",
          actions: "sendParam",
        },
      },
    },
    loaded: {
      on: {
        READY: {
          target: "ready",
          actions: "onContentReady",
        },
        PARAM_READY: {
          actions: "sendParam",
        },
      },
    },
    ready: {
      on: {
        PLAY: "playing",
      },
    },
    playing: {
      entry: ["sendPlay", "displayWebsite"],
      on: {
        DONE: {
          target: "loaded",
          actions: ["destroyWebsite", "onDone"],
        },
      },
    },
  },
});

function isDisplayWebsite(data: any): data is DisplayWebsiteEvent {
  if (data.method === "displayWebsite") {
    if (data.payload) {
      if (
        typeof data.payload.url === "string" &&
        typeof data.payload.code === "string"
      ) {
        return true;
      }
    }
    console.log("incorrect displayWebsite event");
  }
  return false;
}

interface DisplayWebsiteEvent {
  method: "displayWebsite";
  payload: {
    url: string;
    code: string;
  };
}

type Props = {
  content: AppType;
  onDone: () => void;
  onContentReady: () => void;
  shouldBePlaying: boolean;
  medias: FormattedMedia[];
  isInfinite: boolean;
  isFullScreen: boolean;
  screenOrientation: number;
  headers: ProtocolHeaders;
};

export type ProtocolHeaders = {
  teamId?: string;
  tenantId?: string;
};

export default memo(DynamicApp);
