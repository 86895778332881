export function getAPKVersion(): { major: number; minor: number } {
  const version =
    window.FugoBridge?.getAPKVersion && window.FugoBridge?.getAPKVersion();
  if (!version || typeof version != "string" || !version.includes(".")) {
    return {
      major: 0,
      minor: 0,
    };
  }
  const splitted = version.split(".");
  const major = ~~splitted[0];
  const minor = ~~splitted[1];
  return {
    major,
    minor,
  };
}
