import { getPlayerType } from "./get-player-type";
import getSupportedVideoCodecs, {
  VideoCodec,
} from "./get-supported-video-codecs";

export default function getDefaultVideoCodec(): VideoCodec {
  if (getPlayerType() === "iadea-web") {
    return "iadea_1280x720";
  }
  if (getPlayerType() === "sssp") {
    return "h264_1280x720";
  }
  if (getPlayerType() === "brightsign") {
    return "brightsign_1280x720";
  }
  // use webm if it's supported
  return getSupportedVideoCodecs().includes("webm")
    ? "webm_1280x720"
    : "h264_1280x720";
}
