import React, { ReactNode } from "react";
import { ImageOrientation } from "../../stateMachines/PlaybackMachine";
import { Flip } from "./Flip";
import { UpsideDown } from "./UpsideDown";

export const Rotate = ({
  orientation,
  children,
}: {
  orientation?: ImageOrientation;
  children: ReactNode;
}) => {
  if (orientation === 90) {
    return <Flip>{children}</Flip>;
  }
  if (orientation === 180) {
    return <UpsideDown>{children}</UpsideDown>;
  }
  if (orientation === 270) {
    return (
      <Flip>
        <UpsideDown>{children}</UpsideDown>
      </Flip>
    );
  }
  return <>{children}</>;
};
