export async function sendMessage(
  stream: WTStream,
  message: string
): Promise<void> {
  const msg = `${message.length} ${message}`;
  const writer = stream.writable.getWriter();
  const encoder = new TextEncoder();
  const encoded = encoder.encode(msg);
  await writer.ready;
  await writer.write(encoded);
  await writer.ready;
  await writer.close();
}
