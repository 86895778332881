import { SuccessCallback, WebAPIError } from "tizen-tv-webapis";

export const TIZEN_DIRECTORY = "downloads";

export interface Tizen {
  systeminfo: SystemInfo;
  DownloadRequest: DownloadRequestConstructor;
  download: DownloadManager;
  filesystem: FileSystemManager;
  application: Application;
}

interface Application {
  getCurrentApplication(): { appInfo: { packageId: string } };
}

export interface B2BAPIs {
  b2bcontrol: {
    captureScreen(
      success: (screenshot: string) => void,
      error: (err: any) => void
    ): void;
    rebootDevice(success: () => void, error: (err: any) => void): void;
    getSerialNumber(): string;
    getCurrentTemperature(): string;
    getMenuOrientation(): B2BControlDEGREE;
    setMenuOrientation(
      degree: B2BControlDEGREE,
      onSuccess: () => void,
      onError?: (err: any) => void
    ): void;
    setPanelMute(
      state: "ON" | "OFF",
      success: () => void,
      error: (err: any) => void
    ): void;
    setLampSchedule(
      state: "ON" | "OFF",
      time: string,
      brightness: number,
      secondTime: string,
      secondBrightness: number,
      onSuccess: () => void,
      onError?: (err: any) => void
    ): void;
    setNoSignalPowerOff(
      state: "ON" | "OFF",
      onsuccess: () => void,
      onError: (err: any) => void
    ): void;
    setStandby(
      state: "ON" | "OFF",
      onsuccess: () => void,
      onError: (err: any) => void
    ): void;
  };
}

type B2BControlDEGREE =
  | "DEGREE_0"
  | "DEGREE_90"
  | "DEGREE_180"
  | "DEGREE_270"
  | "PORTRAIT"
  | "LANDSCAPE";

interface DownloadRequest {
  url: string;
  destination?: string;
  fileName?: string;
  networkType?: DownloadNetworkType;
  httpHeader?: object;
}

interface DownloadRequestConstructor {
  new (
    url: string,
    destination?: string,
    fileName?: string,
    networkType?: DownloadNetworkType,
    httpHeader?: object
  ): DownloadRequest;
}

type DownloadNetworkType = "ALL" | "WIFI" | "CELLULAR";

interface DownloadManager {
  start(
    downloadRequest: DownloadRequest,
    downloadCallback?: DownloadCallback
  ): number;
  cancel(downloadId: number): void;
  pause(downloadId: number): void;
  resume(downloadId: number): void;
  getState(downloadId: number): DownloadState;
  getDownloadRequest(downloadId: number): DownloadRequest;
  getMIMEType(downloadId: number): string;
  setListener(downloadId: number, downloadCallback: DownloadCallback): void;
}

interface DownloadCallback {
  onprogress?(
    downloadId: number,
    receivedSize: number,
    totalSize: number
  ): void;
  onpaused?(downloadId: number): void;
  oncanceled?(downloadId: number): void;
  oncompleted?(downloadId: number, path: string): void;
  onfailed?(downloadId: number, error: WebAPIError): void;
}

type DownloadState =
  | "QUEUED"
  | "DOWNLOADING"
  | "PAUSED"
  | "CANCELED"
  | "COMPLETED"
  | "FAILED";

interface FileSystemManager {
  deleteFile(
    path: string,
    successCallback?: (path: string) => void,
    errorCallback?: ErrorCallback
  ): void;
  pathExists: (path: string) => boolean;
  resolve: (
    location: string,
    onsuccess: (file: File) => void,
    onerror?: ErrorCallback,
    mode?: FileMode
  ) => void;
}

type FileMode = "a" | "r" | "rw" | "rwo" | "w";

interface File {
  parent?: File;
  readOnly: boolean;
  isFile: boolean;
  isDirectory: boolean;
  // readonly attribute Date? created;
  // readonly attribute Date? modified;
  path: string;
  name: string;
  fullPath: string;
  fileSize: number;
  length: number;
  toURI: () => string;
  listFiles: (
    onsuccess: (files: File[], path: string) => void,
    onerror?: any,
    filter?: any
  ) => void;
  // void openStream(FileMode mode, FileStreamSuccessCallback onsuccess, optional ErrorCallback? onerror, optional DOMString? encoding)
  //                 raises(WebAPIException);
  // void readAsText(FileStringSuccessCallback onsuccess, optional ErrorCallback? onerror, optional DOMString? encoding)
  //                 raises(WebAPIException);
  // void copyTo(DOMString originFilePath, DOMString destinationFilePath, boolean overwrite, optional SuccessCallback? onsuccess,
  //             optional ErrorCallback? onerror) raises(WebAPIException);
  // void moveTo(DOMString originFilePath, DOMString destinationFilePath, boolean overwrite, optional SuccessCallback? onsuccess,
  //             optional ErrorCallback? onerror) raises(WebAPIException);
  // File createDirectory(DOMString dirPath) raises(WebAPIException);
  // File createFile(DOMString relativeFilePath) raises(WebAPIException);
  // File resolve(DOMString filePath) raises(WebAPIException);
  deleteDirectory: (
    directoryPath: string,
    recursive: boolean,
    onsuccess?: SuccessCallback,
    onerror?: ErrorCallback
  ) => void;
  deleteFile: (
    filePath: string,
    onsuccess: (file: File) => void,
    onerror?: ErrorCallback
  ) => void;
}

type errCb = (err: any) => void;
type successCb<Info> = (info: Info) => void;

interface SystemInfo {
  getCapability(capability: string): boolean;
  getTotalMemory(): number;
  getAvailableMemory(): number;
  getPropertyValue(
    property: "CPU",
    info: successCb<{ load: number }>,
    err: errCb
  ): void;
  getPropertyValue(
    property: "STORAGE",
    info: successCb<{
      units: {
        type: string;
        capacity: number;
        availableCapacity: number;
        isRemovable: boolean;
      }[];
    }>,
    err: errCb
  ): void;
  getPropertyValue(
    property: "NETWORK",
    info: successCb<{
      networkType:
        | "NONE"
        | "2G"
        | "2.5G"
        | "3G"
        | "4G"
        | "WIFI"
        | "ETHERNET"
        | "NET_PROXY"
        | "UNKNOWN";
    }>,
    err: errCb
  ): void;
  getPropertyValue(
    property: "WIFI_NETWORK",
    info: successCb<{
      status: string;
      ssid: string;
      ipAddress: string;
      ipv6Address: string;
      macAddress: string;
      signalStrength: number; //double
    }>,
    err: errCb
  ): void;
}
