import { State } from "xstate";
import { DashboardMachineContext } from "../../stateMachines/PlaybackMachine";
import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import { useEffect, useState } from "react";
import { Status } from ".";
import { Image } from "../image";
import styles from "./index.module.css";

export default function PlayerDashboard({
  state,
  tenantId,
}: {
  state: State<DashboardMachineContext>;
  tenantId: string;
}) {
  const [screenshotUrl, setScreenshotUrl] = useState<null | string | Error>(
    null
  );
  useEffect(() => {
    async function getDash() {
      try {
        const dashboard = await getDashboard(
          state.context.dashboardId,
          tenantId,
          process.env.REACT_APP_DASHBOARD_BASE ?? ""
        );
        const screenshotUrl =
          (await window.FugoElectronBridge?.getPlayerDashboardScreenshot?.(
            state.context.dashboardId,
            dashboard
          )) ?? new Error("getting screenshot url");
        setScreenshotUrl(screenshotUrl);
      } catch (e) {
        setScreenshotUrl(new Error("getting dashboard"));
      }
    }
    getDash();
  }, [state.context.dashboardId, tenantId]);

  if (screenshotUrl == null) return <Status>loading</Status>;
  if (screenshotUrl instanceof Error)
    return (
      <Status>
        error: {screenshotUrl.message}
        <pre>{screenshotUrl.stack}</pre>
      </Status>
    );
  return (
    <div className={styles.fullScreen}>
      <Image
        orientation={state.context.config.orientation}
        fit={state.context.config.fit}
        src={screenshotUrl}
        onLoad={() => {}}
      />
    </div>
  );
}

async function getDashboard(
  dashboardId: string,
  tenantId: string,
  base: string
): Promise<DashboardReply> {
  const client = new ApolloClient({
    uri: `${base}/player/dashboard`,
    cache: new InMemoryCache(),
  });
  const signature = "empty";
  const variables = {
    tenantId,
    dashboardId,
    signature,
  };
  try {
    const result = await client.query({
      query: gql`
        query GetDashboard(
          $tenantId: String!
          $dashboardId: String!
          $signature: String!
        ) {
          getDashboard(
            tenantId: $tenantId
            dashboardId: $dashboardId
            signature: $signature
          ) {
            dashboard {
              height
              width
              steps
              secretIds
              screenshotPeriod
              settings
              maybeOnPremiseConfig {
                localIp
                remoteIp
                publicKey
              }
            }
            secrets {
              secrets {
                key
                value
              }
            }
          }
        }
      `,
      variables,
    });
    return result.data.getDashboard;
  } catch (err) {
    console.log(err);
    console.log(variables);
    console.log(`${base}/player/dashboard`);
    throw err;
  }
}

export interface DashboardReply {
  dashboard: {
    height: number;
    width: number;
    steps: Array<Event>;
    secretIds: Array<string>;
    screenshotPeriod: number;
    settings: any;
    maybeOnPremiseConfig: {
      localIp: string;
      remoteIp: string;
      publicKey: string;
      screenshotUrl: string;
      thumbnailUrl: string;
    };
  };
  secrets: {
    secrets: Array<{
      key: string;
      value: string;
    }>;
  };
}
