import QR from "qrcode.react";
import styles from "./index.module.css";
import { useEffect } from "react";
import { setHealthy } from "../../misc/healthCheck";

function PinScreen({ pin, isExiting, isAmazonRelease }: Props) {
  useEffect(() => {
    setHealthy();
  }, []);
  const appLinkTitle = isAmazonRelease ? "fugo.ai/amazon" : "fugo.ai/app";
  const amazonReleaseClass = isAmazonRelease ? styles.hidden : "";
  return (
    <div
      className={styles.root}
      style={{
        animationName: isExiting ? styles.slideOut : styles.slideIn,
      }}
    >
      <div>
        <div className={styles.titleRow}>
          <img alt="logo" src="./logo-white.svg" />
          <h1 className={styles.title}>Welcome to Fugo</h1>
        </div>
        <main className={styles.row}>
          <div>
            <div title={`pin-${pin}`} className={styles.pinContainer}>
              {pin.split("").map((number, index) => (
                <div key={`${index}-${number}`} className={styles.pinNumber}>
                  {number}
                </div>
              ))}
            </div>
            <div className={styles.pinHint}>
              Go to <a href="#not-interactive">{appLinkTitle}</a> on Desktop and
              enter this PIN to pair your device
              <div className={`${styles.qrHint} ${amazonReleaseClass}`}>
                Or scan this QR code with your smartphone
              </div>
            </div>
          </div>
          <div className={amazonReleaseClass}>
            <div className={styles.qrContainer}>
              <QR
                className={styles.qr}
                value={`https://www.fugo.ai/app/screens/new?pin=${pin}`}
                bgColor="#2a2d33"
                fgColor="#acb5bd"
                renderAs="svg"
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

interface Props {
  pin: string;
  isExiting: boolean;
  isAmazonRelease: boolean;
}

export default PinScreen;
