import { assign, createMachine } from "xstate";
import { UpdateSettingsEvent } from "./CommandsMachine";
import getChromeExtensionId from "../misc/get-chrome-extension-id";
import "blueimp-canvas-to-blob";
import { shouldStoreOnBrightSign, shouldUseScapAPI } from "./PlaybackMachine";
import { getPlayerType } from "../misc/get-player-type";
import { get } from "idb-keyval";

export const screenshotMachineInitContext: ScreenshotContext = {
  screenshotInterval: 30_000,
  url: "",
  androidScreenshotMethod: "doScreenshot2",
  isScreenshotEnabled: true,
  shouldHTMLScreenshotForced: false,
};

export const screenshotMachine = createMachine(
  {
    id: "ScreenshotMachine",
    schema: {
      context: {} as ScreenshotContext,
      events: {} as ScreenshotEvent,
      services: {} as {
        screenshot: {
          data: void;
        };
      },
    },
    tsTypes: {} as import("./ScreenshotMachine.typegen").Typegen0,
    context: screenshotMachineInitContext,
    initial: "screenshot",
    on: {
      UPDATE_SETTINGS: [
        {
          cond: "isScreenshotRelatedEvent",
          actions: "updateSettings",
        },
      ],
    },
    states: {
      screenshot: {
        invoke: {
          src: "screenshot",
          onDone: [
            {
              target: "idle",
            },
          ],
          onError: [
            {
              actions: "log",
              target: "idle",
            },
          ],
        },
        description: "make & send a screenshot",
      },
      idle: {
        after: {
          INTERVAL: {
            target: "screenshot",
          },
        },
      },
    },
  },
  {
    guards: {
      isScreenshotRelatedEvent: (_ctx, event) => {
        return (
          "isScreenshotEnabled" in event.settings ||
          "screenshotInterval" in event.settings ||
          "androidScreenshotMethod" in event.settings ||
          "shouldHTMLScreenshotForced" in event.settings
        );
      },
    },
    actions: {
      log: (_ctx, event) => console.log(event),
      updateSettings: assign({
        isScreenshotEnabled: (ctx, event) =>
          "isScreenshotEnabled" in event.settings
            ? !!event.settings.isScreenshotEnabled
            : ctx.isScreenshotEnabled,
        screenshotInterval: (ctx, event) =>
          "screenshotInterval" in event.settings
            ? Number(event.settings.screenshotInterval)
            : ctx.screenshotInterval,
        androidScreenshotMethod: (ctx, event) =>
          "androidScreenshotMethod" in event.settings
            ? (event.settings
                .androidScreenshotMethod as AndroidScreenshotMethod)
            : ctx.androidScreenshotMethod,
        shouldHTMLScreenshotForced: (ctx, event) =>
          "shouldHTMLScreenshotForced" in event.settings
            ? !!event.settings.shouldHTMLScreenshotForced
            : ctx.shouldHTMLScreenshotForced,
      }),
    },
    services: {
      screenshot: async (context) => {
        const { url, isScreenshotEnabled, shouldHTMLScreenshotForced } =
          context;
        const apkVersion = parseFloat(
          window.FugoBridge?.getAPKVersion?.() || "0"
        );
        const areHeadersSupported = apkVersion >= 2.16;

        if (!isScreenshotEnabled) {
          console.log("screenshot disabled");
          return;
        }

        if (!url) {
          console.log("screenshot url is empty");
          return;
        }

        if (shouldHTMLScreenshotForced) {
          await sendHTMLScreenshot(url);
          return;
        }

        if (
          typeof window?.FugoBridge?.doScreenshot2 === "function" &&
          context.androidScreenshotMethod === "doScreenshot2"
        ) {
          if (areHeadersSupported) {
            console.log(
              `window.FugoBridge.doScreenshot2(${url}, ${JSON.stringify(
                screenshotRequestHeaders
              )});`
            );
            window.FugoBridge.doScreenshot2(
              url,
              JSON.stringify(screenshotRequestHeaders)
            );
          } else {
            console.log(`window.FugoBridge.doScreenshot2(${url});`);
            window.FugoBridge.doScreenshot2(url);
          }
          return;
        }

        if (
          typeof window?.FugoBridge?.doScreenshot === "function" &&
          context.androidScreenshotMethod === "doScreenshot"
        ) {
          if (areHeadersSupported) {
            console.log(
              `window.FugoBridge.doScreenshot(${url}, ${JSON.stringify(
                screenshotRequestHeaders
              )});`
            );
            window.FugoBridge.doScreenshot(
              url,
              JSON.stringify(screenshotRequestHeaders)
            );
          } else {
            console.log(`window.FugoBridge.doScreenshot(${url});`);
            window.FugoBridge.doScreenshot(url);
          }
          return;
        }

        if (typeof window?.FugoElectronBridge?.doScreenshot === "function") {
          console.log(
            `window.FugoElectronBridge.doScreenshot(${url}, ${JSON.stringify(
              screenshotRequestHeaders
            )});`
          );
          window.FugoElectronBridge.doScreenshot(
            url,
            JSON.stringify(screenshotRequestHeaders)
          );
          return;
        }

        if (window?.b2bapis?.b2bcontrol?.captureScreen) {
          sendScreenshot(url, await getTizenScreenshotPath());
          return;
        }

        if (shouldStoreOnBrightSign()) {
          // eslint-disable-next-line no-eval
          const ScreenshotClass = eval('require("@brightsign/screenshot")');
          const screenshot = new ScreenshotClass();
          await screenshot.asyncCapture({
            fileName: "SD:/screenshot.jpeg",
            width: 720,
            height: 405,
            fileType: "JPEG",
            quality: 75,
          });
          sendScreenshot(url, "file:///sd:/screenshot.jpeg");
          return;
        }

        if (shouldUseScapAPI()) {
          try {
            const screenshotInBase64 = await getLgScreenshot();
            const screenshotAsResponse = await fetch(screenshotInBase64);
            await fetch(url, {
              method: "PUT",
              headers: screenshotRequestHeaders,
              body: await screenshotAsResponse.arrayBuffer(),
            });
            return;
          } catch (error) {
            console.error("Failed to get screenshot from LG", error);
            return;
          }
        }

        const chromeExtensionId = getChromeExtensionId();
        if (
          typeof window?.chrome?.runtime?.sendMessage === "function" &&
          chromeExtensionId
        ) {
          chrome.runtime.sendMessage(chromeExtensionId, {
            type: "doScreenshot",
            url,
          });
          return;
        }

        // default
        sendHTMLScreenshot(url);
      },
    },
    delays: {
      INTERVAL: (ctx) => ctx.screenshotInterval || 30_000,
    },
  }
);

async function sendHTMLScreenshot(url: string) {
  try {
    console.log("Sending HTML screenshot to ", url);
    const screenshot = await get<Blob>("screenshot");
    if (screenshot) {
      console.log("Loaded screenshot from IndexedDB, size: ", screenshot.size);
      const response = await fetch(url, {
        method: "PUT",
        headers: screenshotRequestHeaders,
        body: screenshot,
      });
      console.log(
        `Screenshot sent, status: ${response.status} ${response.statusText}, OK: ${response.ok}`
      );
    } else {
      console.log("No screenshot in IndexedDB");
    }
  } catch (e) {
    console.error(e);
  }
}

async function sendScreenshot(screenshotUrl: string, screenshotPath: string) {
  try {
    const screenshotData = await getScreenshotBinary(screenshotPath);
    await fetch(screenshotUrl, {
      method: "PUT",
      headers: screenshotRequestHeaders,
      body: screenshotData,
    });
  } catch (e) {
    console.error(e);
  }
}

function getTizenScreenshotPath(): Promise<string> {
  return new Promise((resolve, reject) => {
    try {
      window?.b2bapis?.b2bcontrol.captureScreen(
        (screenshot) => {
          resolve(screenshot);
        },
        (err) => reject(err)
      );
    } catch (e) {
      reject(e);
    }
  });
}

function getLgScreenshot(): Promise<string> {
  return new Promise((resolve, reject) => {
    const successCB = function (cbObject: { data: string }) {
      resolve("data:image/jpeg;base64," + cbObject.data);
    };
    const failureCB = function (cbObject: {
      errorCode: number;
      errorText: string;
    }) {
      reject(cbObject);
    };
    const options = { save: true } as const;
    if (window.Signage) {
      const LGsignage = new window.Signage();
      LGsignage.captureScreen(successCB, failureCB, options);
    }
  });
}

function getScreenshotBinary(
  screenshotPath: string
): Promise<ArrayBuffer | string> {
  return new Promise((resolve, reject) => {
    try {
      let canvas: HTMLCanvasElement | null = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.onload = function () {
        if (!canvas || !ctx) {
          console.log("Canvas is null 🤷");
          return;
        }

        const coeff = 1;
        const isPortrait = window.matchMedia("(orientation: portrait)").matches;
        if (isPortrait) {
          canvas.width = img.height * coeff;
          canvas.height = img.width * coeff;
          ctx.translate(canvas.width / 2, canvas.height / 2);
          const degrees = 90;
          ctx.rotate((degrees * Math.PI) / 180);
          ctx.drawImage(
            img,
            -canvas.height / 2,
            -canvas.width / 2,
            canvas.height,
            canvas.width
          );
        } else {
          canvas.width = img.width * coeff;
          canvas.height = img.height * coeff;
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        }

        canvas.toBlob(
          async function (blob) {
            if (!blob) {
              console.log("empty canvas blob");
              return;
            }
            if (
              typeof blob.arrayBuffer === "function" &&
              getPlayerType() === "brightsign"
            ) {
              resolve(await blob.arrayBuffer());
              return;
            }
            var reader = new FileReader();

            reader.onload = function () {
              if (typeof reader.result === "string") {
                console.log("reader result is string");
              }
              if (!reader.result) {
                reject(`Reader result is "${reader.result}"`);
                return;
              }
              resolve(reader.result);
            };

            reader.readAsArrayBuffer(blob);
          },
          "image/jpeg",
          0.75
        );

        canvas = null;
      };
      img.src = screenshotPath + "?v=" + Date.now();
    } catch (e) {
      reject(e);
    }
  });
}

export function isNativeScreenshotSupported() {
  if (typeof window?.FugoBridge?.doScreenshot2 === "function") {
    return true;
  }

  if (typeof window?.FugoElectronBridge?.doScreenshot === "function") {
    return true;
  }

  if (window?.b2bapis?.b2bcontrol?.captureScreen) {
    return true;
  }

  if (shouldStoreOnBrightSign()) {
    return true;
  }

  if (getPlayerType() === "raspberry" || getPlayerType() === "chrome") {
    return true;
  }

  if (shouldUseScapAPI()) {
    return true;
  }

  return false;
}

export interface ScreenshotContext {
  screenshotInterval: number;
  url: string;
  androidScreenshotMethod: AndroidScreenshotMethod;
  isScreenshotEnabled: boolean;
  shouldHTMLScreenshotForced: boolean;
}

export type AndroidScreenshotMethod = "doScreenshot" | "doScreenshot2";

type ScreenshotEvent = UpdateSettingsEvent;

const screenshotRequestHeaders = {
  "Content-Type": "image/jpeg",
  "x-amz-server-side-encryption": "AES256",
};
