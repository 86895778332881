import { PlayerContext } from "../stateMachines/PlayerMachine";
import appInfo from "./appinfo.json";

export const SCAP_DOWNLOAD_DIRECTORY = "file://internal/FugoContent/";
export const SCAP_SOURCE_DIRECTORY = "http://127.0.0.1:9080/FugoContent/";

function listScapFiles(
  dir: string
): Promise<{ name: string; type: string; size: number }[]> {
  return new Promise((resolve, reject) => {
    try {
      const successCb = (cbObject: {
        files: { name: string; type: string; size: number }[];
      }) => {
        resolve(cbObject.files);
      };

      const failureCb = (cbObject: {
        errorCode: string;
        errorText: string;
      }) => {
        reject(
          new Error(`Error Code [${cbObject.errorCode}]: ${cbObject.errorText}`)
        );
      };

      const options = {
        path: dir,
      };

      const storage = new window.Storage();
      storage.listFiles(successCb, failureCb, options);
    } catch (e) {
      reject(e);
    }
  });
}

export async function getFullScapPath(path: string) {
  try {
    const files = await listScapFiles(SCAP_DOWNLOAD_DIRECTORY);
    for (const file of files) {
      const withoutExtension = file.name.split(".")[0];

      if (withoutExtension === path) {
        const content = SCAP_SOURCE_DIRECTORY + file.name;
        return content;
      }
    }
    return null;
  } catch (error) {
    console.log(error);
  }
}

export function writeToLGStorage(data: string): Promise<void> {
  return new Promise((resolve, reject) => {
    try {
      const successCb = () => {
        console.log("Context stored in LG Storage");
        resolve();
      };

      const failureCb = (cbObject: {
        errorCode: string;
        errorText: string;
      }) => {
        reject(
          new Error(`Error Code [${cbObject.errorCode}]: ${cbObject.errorText}`)
        );
      };

      const options = {
        data: data,
        path: `file://internal/FugoContext/context.json`,
        position: 0,
        mode: "truncate",
        offset: 0,
        length: data.length,
        encoding: "utf8",
      } as const;

      const storage = new window.Storage();
      storage.writeFile(successCb, failureCb, options);
    } catch (e) {
      reject(e);
    }
  });
}

export async function readFromLGStorage(): Promise<PlayerContext> {
  try {
    const response = await fetch(
      "http://127.0.0.1:9080/FugoContext/context.json"
    );
    if (!response.ok) {
      throw new Error("Response from LG context is not ok");
    }
    const data = await response.json();
    if (!data || Object.keys(data).length === 0) {
      throw new Error("LG context empty");
    }
    return data as PlayerContext;
  } catch (e) {
    throw new Error("Error reading from LG Storage");
  }
}

async function upgradeIpkApplication(): Promise<void> {
  return new Promise((resolve, reject) => {
    const successCb = () => {
      console.log("IPK app update successful");
      resolve();
    };
    const failureCb = (cbObject: { errorCode: string; errorText: string }) => {
      reject(
        new Error(`Error Code [${cbObject.errorCode}]: ${cbObject.errorText}`)
      );
    };
    const options = {
      type: window.Storage.AppType.IPK,
      to: window.Storage.AppMode.LOCAL,
      recovery: true,
    } as const;
    const storage = new window.Storage();
    storage.upgradeApplication(successCb, failureCb, options);
  });
}

async function restartLG() {
  const successCb = () => {
    console.log("Restarting LG");
  };
  const failureCb = (cbObject: { errorCode: string; errorText: string }) => {
    new Error(`Error Code [${cbObject.errorCode}]: ${cbObject.errorText}`);
  };
  if (window.Power) {
    const options = {
      powerCommand: window.Power.PowerCommand.REBOOT,
    } as const;
    const power = new window.Power();
    power.executePowerCommand(successCb, failureCb, options);
  }
}

export async function checkForAppUpdates() {
  const localVersion = appInfo.version;
  console.log("Local Version: ", localVersion);
  try {
    const response = await fetch(
      "https://raw.githubusercontent.com/OutOfAxis/fugo-lg-app-releases/main/latest.yml"
    );
    const data = await response.text();
    const remoteVersion = data
      .split("\n")
      .find((line) => line.startsWith("version: "));
    if (remoteVersion) {
      const latestVersion = remoteVersion.split(": ")[1];
      console.log("New version: ", latestVersion);
      if (latestVersion !== localVersion) {
        console.log("Update available for LG");
        try {
          await upgradeIpkApplication();
          await restartLG();
        } catch (e) {
          console.error("Failed to upgrade IPK application:", e);
        }
      } else {
        console.log("Already running latest version");
        return;
      }
    }
  } catch (error) {
    console.error("Couldn't fetch version from LG release: ", error);
    return;
  }
}

export function getLGDeviceInfo(): Promise<{
  hardwareVersion: string;
  manufacturer: string;
  modelName: string;
  sdkVersion: string;
  serialNumber: string;
  firmwareVersion: string;
}> {
  return new Promise((resolve, reject) => {
    const successCb = (cbObject: {
      hardwareVersion: string;
      manufacturer: string;
      modelName: string;
      sdkVersion: string;
      serialNumber: string;
      firmwareVersion: string;
    }) => {
      resolve(cbObject);
    };

    const failureCb = (cbObject: { errorCode: string; errorText: string }) => {
      reject(
        new Error(`Error Code [${cbObject.errorCode}]: ${cbObject.errorText}`)
      );
    };
    if (window.DeviceInfo) {
      const deviceInfo = new window.DeviceInfo();
      deviceInfo.getPlatformInfo(successCb, failureCb);
    } else {
      reject(new Error("DeviceInfo is not available"));
    }
  });
}
