import { useCallback, useEffect, useMemo, useState } from "react";
import JSZip from "jszip";
import style from "./zipped-images.module.css";

export const ZippedImages = ({
  src,
  onContentReady,
  onDone,
  shouldBePlaying,
  isInfinite,
  duration = 5000,
}: Props) => {
  const [images, setImages] = useState<Blob[]>([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const fetchImages = useCallback(async (src: string) => {
    const res = await fetch(src);
    const zip = await JSZip.loadAsync(res.blob());
    return Promise.all(zip.file(/.png$/i).map((file) => file.async("blob")));
  }, []);

  useEffect(() => {
    (async () => {
      setImages(await fetchImages(src));
      onContentReady();
    })();
  }, [src, fetchImages, onContentReady]);

  useEffect(() => {
    if (images.length === 0 || !shouldBePlaying) return;
    const intervalId = setInterval(() => {
      if (currentImageIndex >= images.length - 1) {
        if (isInfinite) {
          setCurrentImageIndex(0);
        } else {
          clearInterval(intervalId);
          onDone();
          return;
        }
      } else {
        setCurrentImageIndex(currentImageIndex + 1);
      }
    }, duration / images.length);
    return () => clearInterval(intervalId);
  }, [
    images,
    currentImageIndex,
    duration,
    isInfinite,
    onDone,
    shouldBePlaying,
  ]);

  const imageSrc = useMemo(() => {
    if (images.length === 0) return;
    return URL.createObjectURL(images[currentImageIndex]);
  }, [images, currentImageIndex]);

  return (
    <div className={style.root}>
      <img
        src={imageSrc}
        onLoad={(e) => URL.revokeObjectURL(e.currentTarget.src)}
        alt=""
      />
    </div>
  );
};

interface Props {
  src: string;
  duration?: number;
  onDone: (error?: string) => void;
  onContentReady: () => void;
  shouldBePlaying: boolean;
  isInfinite: boolean;
}
