import { useActor } from "@xstate/react";
import { SpawnedActorRef } from "xstate";
import { FrameContent } from "../MediaUtils";
import { Screen } from "../playback";
import styles from "./index.module.css";
import { FramesScreenContext } from "../../stateMachines/PlaybackMachine";
import { Settings } from "../../stateMachines/PlayerMachine";
import { FormattedMedia } from "../../generated/router";
import { ProtocolHeaders } from "../app/dynamic";
import { Contain } from "../image/Contain";

const getFrameItemId = (tileId: string, index: number) =>
  `FrameItem_${tileId}_${index}`;

export const Frame = ({
  content,
  tileId,
  actor,
  send,
  settings,
  shouldBePlaying,
  medias,
  isInfinite,
  originalFormat,
  headers,
}: {
  content: FrameContent;
  tileId: string;
  actor: SpawnedActorRef<any>;
  send: (type: any) => void;
  settings: Settings;
  shouldBePlaying: boolean;
  medias: FormattedMedia[];
  isInfinite: boolean;
  originalFormat: boolean;
  headers: ProtocolHeaders;
}) => {
  const [state] = useActor(actor);
  const context = state.context as FramesScreenContext;
  const bg = medias.find(
    (media) => media.mediaId === content.backgroundMediaId
  );

  const frameElement = (
    <>
      <svg width="0" height="0">
        <defs>
          {content.items.map((item, i) => (
            <clipPath
              key={i}
              id={getFrameItemId(tileId, i)}
              clipPathUnits="objectBoundingBox"
            >
              <path d={item.path} />
            </clipPath>
          ))}
        </defs>
      </svg>
      {context.items.map((item, index) => (
        <div
          key={index}
          className={styles.fullScreen}
          style={{
            clipPath: `url(#${getFrameItemId(tileId, index)})`,
          }}
        >
          <div
            style={{
              position: "absolute",
              left: `${item.item.rect.x * 100}%`,
              top: `${item.item.rect.y * 100}%`,
              width: `${item.item.rect.width * 100}%`,
              height: `${item.item.rect.height * 100}%`,
            }}
          >
            <div
              style={{
                position: "absolute",
                left: `${((item.item.contentPosition.x ?? 0.5) - 0.5) * 100}%`,
                top: `${((item.item.contentPosition.y ?? 0.5) - 0.5) * 100}%`,
                width: `${(item.item.contentPosition.width ?? 1) * 100}%`,
                height: `${(item.item.contentPosition.height ?? 1) * 100}%`,
                transform: `rotate(${
                  item.item.contentPosition.rotate ?? 0
                }deg)`,
              }}
            >
              <Screen
                value={item.screen}
                send={send}
                settings={settings}
                shouldBePlaying={shouldBePlaying}
                medias={medias}
                isInfinite={isInfinite}
                isFullScreen={false}
                headers={headers}
              />
            </div>
          </div>
        </div>
      ))}
      {bg ? (
        <img
          className={styles.fullScreen}
          src={originalFormat ? bg.originalUrl : bg.formatUrl}
          alt="Frame background"
        />
      ) : null}
    </>
  );

  if (content.fit === "stretch") {
    return <div className={styles.fullScreen}>{frameElement}</div>;
  }

  return (
    <div className={styles.fullScreen}>
      <Contain aspectRatio={content.aspectRatio}>
        {(size) => (
          <div className={styles.relative} style={size}>
            {frameElement}
          </div>
        )}
      </Contain>
    </div>
  );
};
